import type { ReactNode } from 'react'

type PageAreaProps = {
  children: ReactNode
  gridArea: 'header' | 'content' | 'navigation'
  className?: string
}

const PageArea = ({ children, gridArea, className }: PageAreaProps) => {
  return (
    <div style={{ gridArea: gridArea }} className={className}>
      {children}
    </div>
  )
}

export { PageArea }
