import type { To } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Arrow, General } from 'untitledui-js'

import { openUrl } from '@/utils/external-resources'

type Props = {
  icon?: React.ReactNode
  label: string
  to?: To
  external?: string
  onClick?: () => void
}

export const MenuLink = ({ label, to, icon, external, onClick }: Props) => {
  const content = (
    <div className="w-full p-4 flex gap-4 justify-between items-center rounded-md border border-solid border-gray-100 [&>svg]:w-6 [&>svg]:h-6 [&>svg]:stroke-text-primary">
      {icon}
      <p className="flex-grow font-body text-text-primary">{label}</p>
      {external ? (
        <General.LinkExternal01 className="!w-6 !h-4 stroke-text-primary" />
      ) : (
        <Arrow.ChevronRight className="w-6 h-6 stroke-text-primary" />
      )}
    </div>
  )

  if (external) {
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          openUrl(external, '_blank')
        }}
      >
        {content}
      </div>
    )
  } else if (onClick) {
    return (
      <div className="cursor-pointer" onClick={onClick}>
        {content}
      </div>
    )
  } else if (to) {
    return <Link to={to}>{content}</Link>
  }
}
