import { Preferences } from '@capacitor/preferences'
import { useEffect, useState } from 'react'

export const useOnboarding = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [onboardingCompleted, setOnboardingCompleted] = useState<string>()

  useEffect(() => {
    const asyncEffect = async () => {
      const returnValue = await Preferences.get({ key: 'onboardingCompleted' })
      if (returnValue.value && returnValue.value !== '') {
        setOnboardingCompleted(returnValue.value)
      } else {
        setOnboardingCompleted(undefined)
      }
      setIsLoading(false)
    }
    asyncEffect()
  }, [])

  return { isLoading, onboardingCompleted }
}
