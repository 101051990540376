import { type RouteObject } from 'react-router-dom'

import { RootNavigation } from '@/layout/navigation/RootNavigation'
import { PageLayout } from '@/layout/pageLayout/PageLayout'
import { CalendarPage } from '@/pages/activity/Calendar.page'
import { ChatPage } from '@/pages/chat/Chat.page'
import { ContentPage } from '@/pages/content/Content.page'
import { HomePage } from '@/pages/home/Home.page'
import { CompleteProfilePage } from '@/pages/profile/CompleteProfile.page'
import { DebugPage } from '@/pages/profile/Debug.page'
import { EditProfilePage } from '@/pages/profile/EditProfile.page'
import { ProfilePage } from '@/pages/profile/Profile.page'
import { SettingsPage } from '@/pages/profile/Settings.page'
import { TestPage } from '@/pages/test/Test.page'

const privateRoutes: RouteObject[] = [
  {
    element: <PageLayout navigation={<RootNavigation />} />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'carepath', element: <CalendarPage /> },
      { path: 'chat', element: <ChatPage /> },
      {
        path: 'discover',
        children: [{ index: true, element: <ContentPage /> }],
      },
      {
        path: 'profile',
        children: [
          { index: true, element: <ProfilePage /> },
          { path: 'edit', element: <EditProfilePage /> },
          { path: 'settings', element: <SettingsPage /> },
          { path: 'debug', element: <DebugPage /> },
          { path: 'complete', element: <CompleteProfilePage /> },
        ],
      },
      { path: 'test', element: <TestPage /> },
    ],
  },
]

export { privateRoutes }
