import { i18n } from '@lingui/core'
import type { HTMLProps } from 'react'
import { Arrow } from 'untitledui-js'

import { Container } from '@/layout/Container'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'

import { StatusBarFade } from '../statusbar/StatusBarFade'

type Props = {
  title: string
  background?: string
  description?: string
  onBack?: () => void
  actions?: React.ReactNode
} & HTMLProps<HTMLDivElement>

export const PageHeader = ({
  title,
  description,
  onBack,
  actions,
  className = 'bg-primary-50',
  ...props
}: Props) => {
  return (
    <>
      <StatusBarFade variant={'pageheader'} />
      <Container {...props} className={`flex flex-col ${className}`}>
        <SafeAreaTopSpacer />
        <div className="flex justify-between gap-2">
          <div className="flex flex-col flex-grow gap-1 py-2">
            {onBack ? (
              <div className="flex justify-between -ml-6">
                <div
                  className="h-full pl-6 flex items-center justify-end cursor-pointer"
                  onClick={onBack}
                >
                  <Arrow.ChevronLeft className="w-6 h-6 stroke-primary-800 cursor-pointer" />
                  <p className="font-callout font-semibold text-text-primary">
                    {i18n._('globals.back')}
                  </p>
                </div>

                <p className="font-callout font-semibold text-primary-800 py-4">
                  {title}
                </p>

                <div className="w-[90px] h-full" />
              </div>
            ) : (
              <p className="font-title1 !leading-[28px] text-primary-800 py-4">
                {title}
              </p>
            )}

            {description ? (
              <p className="font-body text-gray-800">{description}</p>
            ) : null}
          </div>
          {actions ? <div className="flex items-center">{actions}</div> : null}
        </div>
      </Container>
    </>
  )
}
