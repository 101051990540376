import { Capacitor } from '@capacitor/core'
import { useAxios } from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import { General } from 'untitledui-js'

import { Button } from '@/components/button/Button'
import { ContentType } from '@/components/content/ContentType'
import { Container } from '@/layout/Container'
import { useHideNativeNavigation } from '@/layout/providers/LayoutProvider'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import type { FrontdoorCareplanActivity } from '@/utils/content'

export const MessageModalBody = ({
  activity,
  onClose,
  onChange,
}: {
  activity: FrontdoorCareplanActivity
  onClose: () => void
  onChange?: () => void
}) => {
  useHideNativeNavigation()

  const axios = useAxios()
  const close = async () => {
    onClose()
    await axios.patch(`/careplan-activity/${activity.id}`, {
      status: 'completed',
    })
    onChange?.()
  }

  return (
    <div className="w-full h-full overflow-auto flex flex-col gap-6 bg-brand-gradient">
      <div className="flex">
        {Capacitor.isNativePlatform() && (
          <div className="absolute inset-0 h-20 opacity-50 bg-gradient-to-b from-white to-transparent pointer-events-none"></div>
        )}
        <div className="w-full h-28 bg-gradient-to-bl from-primary-500 to-primary-700" />
        <div
          className="flex flex-col absolute top-0 right-0 p-4 cursor-pointer"
          onClick={close}
        >
          <SafeAreaTopSpacer />
          <div className="p-[0.6rem] bg-gray-50 rounded-full">
            <General.X className="w-7 h-7 stroke-gray-500" />
          </div>
        </div>
      </div>

      <Container className="flex flex-col flex-grow gap-2">
        <div className="flex gap-4 pb-1">
          <ContentType type={'message'} />
        </div>

        <div className="flex flex-col flex-grow gap-6">
          <div className="flex flex-col flex-grow gap-2">
            <p className="text-brand font-title1 font-bold">
              {activity.display}
            </p>

            {activity.meta?.message ? (
              <p className="text-text-secondary font-body">
                {activity.meta?.message}
              </p>
            ) : null}
          </div>

          <div className="flex flex-col pb-6">
            <Button variant="primary" size="large" onClick={close}>
              {i18n._('message.mark_completed', undefined, {
                message: 'Markeer als gelezen',
              })}
            </Button>
            <SafeAreaBottomSpacer />
          </div>
        </div>
      </Container>
    </div>
  )
}
