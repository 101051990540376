import { Capacitor } from '@capacitor/core'
import { General, MediaDevices } from 'untitledui-js'

import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import type { Video } from '@/utils/content'

type Props = {
  content: Video
  onStart: () => void
  onClose: () => void
}

export const VideoHeader = ({ content, onStart, onClose }: Props) => {
  return (
    <div className="flex">
      {Capacitor.isNativePlatform() && (
        <div className="absolute inset-0 h-20 opacity-50 bg-gradient-to-b from-white to-transparent pointer-events-none"></div>
      )}
      <div className="flex flex-grow relative h-[100vw] max-h-[calc(100vh-300px)] ">
        {content.image?.thumbnail ? (
          <img
            src={content.image?.thumbnail}
            alt={content.title!}
            className="w-full h-full object-cover bg-gradient-to-bl from-secondary-purple-500 to-secondary-purple-700"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-bl from-secondary-purple-500 to-secondary-purple-700" />
        )}
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center z-20 w-20 h-20 rounded-md overflow-hidden bg-base-black bg-opacity-50 cursor-pointer">
          <div className="absolute w-full h-full bg-base-black blur-2xl" />
          <MediaDevices.Play
            className="w-6 h-6 stroke-white z-10 cursor-pointer"
            onClick={onStart}
          />
        </div>
      </div>
      <div
        className="flex flex-col absolute top-0 right-0 p-4 cursor-pointer"
        onClick={onClose}
      >
        <SafeAreaTopSpacer />
        <div className="p-[0.6rem] bg-gray-50 rounded-full">
          <General.X className="w-7 h-7 stroke-gray-500" />
        </div>
      </div>
    </div>
  )
}
