import type { DeviceInfo } from '@capacitor/device'
import { Device } from '@capacitor/device'

import { createGlobal } from './global-hook'

export const {
  hook: useDeviceInfo,
  setData: setDeviceInfo,
  current: getDeviceInfo,
} = createGlobal<DeviceInfo | undefined>(undefined)

Device.getInfo()
  .then((info) => {
    if (!info.name) {
      // Get name like "Chrome on iOS"
      const browsername = navigator.userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      )
      const browser = browsername ? browsername[1] : ''
      const os = navigator.userAgent.match(
        /(iPhone|iPad|iPod|Android|Windows Phone|Macintosh)/,
      )
      const name = os ? os[1].replace('Macintosh', 'macOS') : ''
      info.name = [browser, name].filter(Boolean).join(' ') || 'Web browser'
    }
    setDeviceInfo(info)
  })
  .catch((error) => {
    // Ignore
    console.log('Device info.error', error)
  })

export const deviceName = getDeviceInfo().then((info) => info.name)
