import {
  healthblocksApi,
  presets,
  setHealthblocksApi,
} from '@/config/environment'

import { Button } from './button/Button'

export function EnvironmentOverride({
  className = '',
}: {
  className?: string
}) {
  return (
    <div className={className + ' flex flex-wrap gap-x-8'}>
      <div className="flex-1">
        <h3 className="font-semibold">Omgeving</h3>
        <p className="font-footNote text-gray-700 font-semibold">
          {healthblocksApi.split('://').pop()}
        </p>
      </div>
      <ul
        className="flex mt-1 flex-wrap gap-2"
        onClick={(event) => event.stopPropagation()}
      >
        {presets.map((p) => (
          <li key={p.env}>
            <Button
              variant={p.api === healthblocksApi ? 'primary' : 'gray'}
              type="button"
              size="small"
              onClick={() => setHealthblocksApi(p.api)}
            >
              {p.display}
            </Button>
          </li>
        ))}
        <li>
          <Button
            variant="gray"
            type="button"
            onClick={() => setHealthblocksApi('')}
          >
            &times;
          </Button>
        </li>
      </ul>
    </div>
  )
}
