import { Preferences } from '@capacitor/preferences'
import { Style } from '@capacitor/status-bar'
import {
  humanDatetime,
  unsafeDecode,
  useActorUpdate,
  useAxios,
  useHealthblocksSWR,
} from '@healthblocks-io/core'
import { useLingui } from '@lingui/react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { Button } from '@/components/button/Button'
import { Debug } from '@/components/Debug'
import { PageHeader } from '@/components/header/PageHeader'
import { EnvironmentOverride } from '@/components/EnvironmentOverride'
import { useOnboarding } from '@/hooks/useOnboarding'
import { Container } from '@/layout/Container'
import { useHideNativeNavigation } from '@/layout/providers/LayoutProvider'
import { useMinimumVersion } from '@/utils/app-version'
import { languages, setLanguage } from '@/utils/lingui'
import {
  notificationPromptModal,
  setNotificationIntent,
  useNotificationChannelManager,
  useNotificationIntent,
} from '@/utils/push-notification'
import { useStatusBarStyle } from '@/utils/status-bar'

export const DebugPage = () => {
  const i18n = useLingui()
  const axios = useAxios()

  const language = languages.find((l) => l.locale === i18n.i18n.locale)
  const languageDisplay = language?.display
  const { isLoading, onboardingCompleted } = useOnboarding()
  const { data: account } = useHealthblocksSWR('/account/me')
  const insz = account?.idToken
    ? unsafeDecode(account?.idToken)?.insz
    : undefined

  const [member, update] = useActorUpdate()

  const navigate = useNavigate()
  const nudge = useMinimumVersion()
  const intent = useNotificationIntent()
  const channel = useNotificationChannelManager()

  useStatusBarStyle(Style.Light)
  useHideNativeNavigation()

  return (
    <div className="h-full flex flex-col bg-base-white">
      <PageHeader
        title={i18n._('profile.settings.title', undefined, {
          message: 'Settings',
        })}
        onBack={() => {
          navigate(-1)
        }}
      />
      <Container className="flex-grow">
        <div className="flex flex-col py-6 h-full">
          <div className="flex flex-col flex-grow gap-6">
            <div className="flex flex-col gap-1">
              <p className="font-body text-gray-700 font-semibold">
                {i18n._(
                  'profile.language',
                  { language: languageDisplay },
                  { message: 'Taal' },
                )}
              </p>
              <ul className="flex flex-wrap gap-2">
                {languages.map((l) => (
                  <li key={l.locale}>
                    <Button
                      variant={language === l ? 'primary' : 'gray'}
                      size="small"
                      onClick={() => setLanguage(l.locale)}
                    >
                      {l.display}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <div className="font-body mb-2 text-gray-700 font-semibold">
                Debug push meldingen
              </div>

              {channel.channels.data?.map((c) => (
                <div key={c.id} className="flex items-center my-4">
                  <div className="flex-1">
                    <div className="font-semibold">
                      {c.display} ({c.medium})
                    </div>
                    <div className="text-sm">{humanDatetime(c.createdAt)}</div>
                  </div>
                  <div>
                    <Button
                      variant="gray"
                      size="small"
                      onClick={() => channel.removeChannel(c.id)}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ))}

              <div className="mt-6 flex flex-wrap gap-2 items-center">
                {intent && (
                  <div>
                    <Button
                      variant="gray"
                      onClick={() => {
                        setNotificationIntent(undefined)
                        toast(
                          'Als je nu chatwoot opent, zal je opnieuw de vraag krijgen om meldingen toe te staan.',
                        )
                      }}
                    >
                      Reset intent: {intent}
                    </Button>
                  </div>
                )}
                <div>
                  <Button variant="gray" onClick={notificationPromptModal}>
                    Modal
                  </Button>
                </div>
              </div>
            </div>

            <div>
              {/* Updated required */}
              <p className="font-body text-gray-700 font-semibold">
                Update required?
              </p>
              <div className="my-2">
                {nudge ? <Debug updateRequiredNudge={nudge} /> : 'Nee'}
              </div>
              <div className="flex">
                <div className="text-primary-600 font-semibold">
                  <Link to="/update-required">Open update required screen</Link>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              {/* Clean user meta */}
              <p className="font-body text-gray-700 font-semibold">
                Profile meta data
              </p>
              <p className="font-caption1 text-gray-700 font-semibold">
                Privacy Policy Accepted:{' '}
                {member.data?.meta?.privacyPolicyAccepted}
              </p>
              <p className="font-caption1 text-gray-700 font-semibold">
                Profile Completed: {member.data?.meta?.profileCompleted}
              </p>
              <Button
                variant="gray"
                size="small"
                onClick={() => {
                  update({
                    meta: {},
                  })
                }}
              >
                Clean user meta
              </Button>
              <Button
                variant="gray"
                size="small"
                onClick={async () => {
                  await Preferences.set({
                    key: 'onboardingCompleted',
                    value: '',
                  })
                  await update({
                    meta: {},
                  })
                  localStorage.clear()
                  sessionStorage.clear()
                  window.location.reload()
                }}
              >
                Full reset
              </Button>
            </div>

            <div className="flex flex-col gap-2">
              {/* Clean device data */}
              <p className="font-body text-gray-700 font-semibold">
                Device storage
              </p>
              <p className="font-caption1 text-gray-700 font-semibold">
                Onboarding completed:
                {isLoading ? 'loading' : onboardingCompleted}
              </p>
              <Button
                variant="gray"
                size="small"
                onClick={async () => {
                  await Preferences.set({
                    key: 'onboardingCompleted',
                    value: '',
                  })
                  localStorage.clear()
                  window.location.reload()
                }}
              >
                Clear device storage
              </Button>
            </div>

            <EnvironmentOverride />

            <Button
              variant="gray"
              size="small"
              onClick={async () => {
                await axios.post('/account/refresh')
                toast('ok')
              }}
            >
              refresh account
            </Button>

            <Debug insz={insz} account={account} />
          </div>
        </div>
      </Container>
    </div>
  )
}
