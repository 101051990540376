import { Capacitor } from '@capacitor/core'
import React from 'react'

type Props = {
  variant?: 'default' | 'pageheader'
}

export const StatusBarFade = ({ variant = 'default' }: Props) =>
  Capacitor.isNativePlatform() ? (
    <div className="absolute top-0 w-full bg-transparent z-10 h-0 overflow-visible">
      <div
        className={`h-[80px] pointer-events-none bg-gradient-to-b ${
          variant === 'pageheader' ? 'from-primary-50' : 'from-base-white'
        } from-30% to-transparent`}
      />
    </div>
  ) : null
