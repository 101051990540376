import type {
  QuestionAnswerInput,
  QuestionAnswerOption,
} from '@healthblocks-io/core'
import { AnimatePresence, motion } from 'framer-motion'
import { General } from 'untitledui-js'

import { dropInAnimation } from '@/theme/animation'

import { Text } from '../typography/Typography'

type Props = {
  quickReplies: QuestionAnswerOption[]
  answer?: QuestionAnswerInput
  onSelect: (option: QuestionAnswerOption) => void
}

const MultiSelect = ({ quickReplies, answer, onSelect }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      {quickReplies?.map((option, key) => {
        const checked = answer && answer.value.includes(option.value)

        return (
          <button
            key={key}
            type="button"
            className={
              (checked
                ? 'bg-primary-50 text-primary-800 border-primary-600'
                : 'bg-white text-gray-700 border-gray-100') +
              ' shadow-card rounded-md border p-4 flex justify-start gap-3'
            }
            onClick={() =>
              onSelect({
                value: option.value,
                label: option.label,
              })
            }
          >
            <input checked={!!checked} type="checkbox" className="hidden" />
            <div
              className={`w-6 h-6 relative flex-shrink-0 border rounded-md transition-colors duration-200 ${
                checked
                  ? 'bg-primary-600 border-primary-600'
                  : 'bg-base-white border-gray-300'
              }`}
            >
              <AnimatePresence>
                {checked ? (
                  <motion.div key={option.value} {...dropInAnimation(-10)}>
                    <General.Check className="stroke-base-white" />
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>

            <Text typography="body" className="text-left">
              {option.label ||
                // @ts-expect-error migration
                option.valueString}
            </Text>
          </button>
        )
      })}
    </div>
  )
}

export { MultiSelect }
