import AppStoreBadge from './assets/app-store-badge-nl.svg'
import GooglePlaybadge from './assets/google-play-badge-nl.png'

type NativeStoreButtonProps = {
  type: 'playStore' | 'appStore'
  url: string
}

const NativeStoreButton = ({ type, url }: NativeStoreButtonProps) => {
  const imageSrc = type === 'playStore' ? GooglePlaybadge : AppStoreBadge
  const imageAlt =
    type === 'playStore' ? 'Google Play Store' : 'Apple App Store'

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <img
        src={imageSrc}
        alt={imageAlt}
        style={{ width: 160, objectFit: 'contain', objectPosition: 'center' }}
      />
    </a>
  )
}

export { NativeStoreButton }
