import { i18n } from '@lingui/core'
import { throttle } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { Arrow, General } from 'untitledui-js'

import { Button } from '@/components/button/Button'
import { useSafeArea } from '@/hooks/useSafeArea'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import type { Exercise } from '@/utils/content'
import { Serialize } from '@/utils/serialize'

type Props = {
  stepNumber: number
  exercise: Exercise
  setStep: (step: number) => void
  onClose: () => void
  onComplete: () => void
}

export const ExerciseStep = ({
  stepNumber,
  exercise,
  setStep,
  onClose,
  onComplete,
}: Props) => {
  const safe = useSafeArea()
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const isProgrammaticScroll = useRef<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timeout>()

  const totalSteps = exercise.steps?.length ?? 1
  const progress = stepNumber / totalSteps

  const cardContainerGap = 56
  const maxViewportWidth = 600

  const handleScroll = useCallback(
    throttle(() => {
      if (isProgrammaticScroll.current) {
        return
      }

      const container = scrollContainerRef.current
      if (container) {
        const cardWidth =
          Math.min(window.innerWidth, maxViewportWidth) - cardContainerGap
        const scrollPosition = container.scrollLeft + container.clientWidth / 2 // Adjusted to find the center
        const currentStep = Math.round(
          (scrollPosition - cardWidth / 2) / cardWidth,
        )
        setStep(currentStep + 1)
      }
    }, 100),
    [],
  )

  const goToStep = (newStep: number, smooth: boolean = true) => {
    setStep(newStep)

    const cardWidth =
      Math.min(window.innerWidth, maxViewportWidth) - cardContainerGap
    const newScrollPosition = (newStep - 1) * cardWidth

    isProgrammaticScroll.current = true
    setTimeout(() => {
      scrollContainerRef.current?.scrollTo({
        left: newScrollPosition,
        behavior: smooth ? 'smooth' : 'instant',
      })

      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(
        () => (isProgrammaticScroll.current = false),
        1000,
      )
    }, 0)
  }

  const goToNextStep = () => {
    goToStep(stepNumber + 1)
  }

  const goToPreviousStep = () => {
    goToStep(stepNumber - 1)
  }

  useEffect(() => {
    goToStep(stepNumber, false)
  }, [])

  return (
    <div className="w-full h-full overflow-auto flex flex-col">
      {/* Step Header */}
      <div className="flex">
        <button className="flex flex-col p-4" onClick={goToPreviousStep}>
          <SafeAreaTopSpacer />
          <div className="p-[0.6rem] bg-gray-50 rounded-full">
            <Arrow.ChevronLeft className="w-7 h-7 stroke-gray-500" />
          </div>
        </button>
        <div className="flex-1 flex py-4 items-center justify-center">
          <h2
            className="flex items-center font-regular justify-center text-gray-700"
            style={{ marginTop: safe.top }}
          >
            <span className="font-semibold">{stepNumber}</span>&nbsp;/&nbsp;
            <span className="font-semibold">{totalSteps}</span>
          </h2>
        </div>
        <div className="flex flex-col p-4 cursor-pointer" onClick={onClose}>
          <SafeAreaTopSpacer />
          <div className="p-[0.6rem] bg-gray-50 rounded-full">
            <General.X className="w-7 h-7 stroke-gray-500" />
          </div>
        </div>
      </div>
      <Progress value={progress} />

      {/* Step Content */}
      <div
        className="overflow-x-auto px-6 flex gap-4 flex-1 snap-x snap-mandatory scrollbar-hide md:scrollbar-default"
        onScroll={handleScroll}
        ref={scrollContainerRef}
      >
        {exercise.steps?.map((step, index) => (
          <StepContent key={index} step={step} stepNumber={index + 1} />
        ))}
      </div>
      <div className="sticky bottom-0 bg-white p-6 pt-4">
        {stepNumber === totalSteps ? (
          <Button
            size="large"
            onClick={() => {
              onClose()
              onComplete()
            }}
          >
            {i18n._('exercise.complete', {}, { message: 'Afsluiten' })}
          </Button>
        ) : (
          <Button size="large" onClick={goToNextStep}>
            {i18n._('exercise.next', {}, { message: 'Volgende' })}
          </Button>
        )}
      </div>
    </div>
  )
}

type ProgressProps = {
  value?: number
}
const Progress = ({ value: percentage = 0 }: ProgressProps) => {
  return (
    <div className="w-full h-2 justify-start items-center gap-3 inline-flex">
      <div className="w-full h-2 relative rounded-lg">
        <div className="w-full h-2 bg-primary-100">
          <div
            className={`flex h-2 items-center bg-primary-500 ${
              percentage === 1 ? null : 'rounded-r-sm'
            } transition:width duration-500`}
            style={{ width: Math.round(percentage * 100) + '%' }}
          />
        </div>
      </div>
    </div>
  )
}

type StepContentProps = {
  step: NonNullable<Exercise['steps']>[0]
  stepNumber: number
}

const StepContent = ({ step, stepNumber }: StepContentProps) => {
  return (
    <div
      className="flex flex-shrink-0 flex-grow-0  basis-[calc(100%_-_16px)] snap-center"
      id={'step-' + stepNumber}
    >
      <div className="w-full h-full flex flex-col gap-4 overflow-auto p-6 scrollbar-hide md:scrollbar-default">
        {step.image?.thumbnail && (
          <div className="aspect-square">
            <img
              src={step.image?.thumbnail}
              alt=""
              className="aspect-square rounded-lg object-cover"
            />
          </div>
        )}
        <div className="text font-title2 font-bold">{step.title}</div>
        <div className="text-text-primary text-xl font-normal leading-[25px]">
          {typeof step.description === 'string' ? (
            <p className="font-callout">{step.description}</p>
          ) : (
            <Serialize nodes={step.description?.root?.children} />
          )}
        </div>
      </div>
    </div>
  )
}
