import styled from 'styled-components'

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  flex-grow: 1;
`

const PageGrid = styled.div`
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  grid-template-columns: 1fr;
  inset: 0;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'content'
    'navigation';
  overflow: hidden;
`

const PageContent = styled.div`
  grid-area: content;
  overflow: scroll;
`

export { PageContainer, PageContent, PageGrid }
