import type {
  CareplanActivity,
  IQuestionnaire,
  QuestionnaireResponse,
} from '@healthblocks-io/core'
import { useApi, useProjectId } from '@healthblocks-io/core'
import axios from 'axios'
import type { SWRResponse } from 'swr'
import useSWR from 'swr'

import type { RichText } from './serialize'

export type FrontdoorCareplanActivity =
  CareplanActivity<CareplanActivityType> & {
    questionnaire?: IQuestionnaire
    questionnaireResponse?: QuestionnaireResponse
  }

export type CareplanActivityType =
  | 'AppointmentRequest'
  | 'Appointment'
  | 'article'
  | 'audio'
  | 'collection'
  | 'exercise'
  | 'Message'
  | 'Questionnaire'
  | 'video'

export const contentTypes = [
  'exercise',
  'video',
  'audio',
  'collection',
  'article',
  'Questionnaire',
]

export const contentFetcher: any = <T>(url: string) =>
  axios.get<T>(url).then((r) => {
    if (typeof r.data === 'string') return []
    return (r.data as any).data
  })

/** Load all content items from a collection, ignore pagination */
export type ContentTypeMap = {
  article: Article
  audio: Audio
  exercise: Exercise
  video: Video
  collection: Collection
  Questionnaire: QuestionnaireExercise
  // Other
  AppointmentRequest: Article
  Message: Article
  ClinicalNote: Article
  Procedure: Article
  Trigger: Article
  Checklist: Article
  Task: Article
}

export function useContent<T extends keyof ContentTypeMap>(
  slug?: T,
): SWRResponse<ContentTypeMap[T][], any> {
  const api = useApi()
  const projectId = useProjectId()

  return useSWR<ContentTypeMap[T][]>(
    slug && contentTypes.includes(slug)
      ? api + '/content/' + slug + '?projectId=' + projectId
      : null,
    contentFetcher,
    {
      onErrorRetry(error, key, config, revalidate, { retryCount }) {
        // Never retry for these errors
        if (
          error?.response?.status === 404 ||
          error?.response?.status === 401
        ) {
          return
        }
        // Try a max of 2 retries
        if (retryCount >= 2) {
          return
        }
        setTimeout(() => {
          revalidate({ retryCount })
        }, 5000)
      },
    },
  )
}

export function useArticles() {
  return useContent('article')
}
export function useAudios() {
  return useContent('audio')
}
export function useExercises() {
  return useContent('exercise')
}
export function useVideos() {
  return useContent('video')
}
export function useCollections() {
  return useContent('collection')
}
export function useQuestionaires() {
  return useContent('Questionnaire')
}
export function useContentBySlug(
  type: keyof ContentTypeMap,
  slug?: string | null,
) {
  return useContent(slug ? type : undefined).data?.find((c) => c.slug === slug)
}

export type Audio = {
  id: string
  slug: string
  title: string
  description?: string
  duration?: string
  label?: string
  image?: Image
  audio?: AudioFile
  published?: boolean
}

export type Article = {
  id: string
  slug: string
  title: string
  description?: string
  duration?: string
  label?: string
  image?: Image
  published?: boolean
}

export type Exercise = {
  id: string
  slug: string
  image?: Image
  title: string
  intro?: string
  duration?: string
  label?: string
  category?: string
  path?: string
  notes?: string
  steps?: ExerciseStep[]
  published?: boolean
  createdAt?: string
  updatedAt?: string | null
}

export type Collection = {
  id: string
  slug: string
  title: string
  description?: string
  label?: string
  image: Image
  duration?: string
  published?: boolean
  article?: Article[]
  audio?: Audio[]
  exercise?: Exercise[]
  questionnaire?: QuestionnaireExercise[]
  video?: Video[]
}

export type ExerciseStep = {
  id: string
  title: string
  description?: string | RichText
  label?: string
  notes?: string
  timer?: string
  image?: Image
}

export type Video = {
  slug: string
  title: string
  description?: string
  duration?: string
  label?: string
  image?: Image
  videoUrl?: string
  published?: boolean
}

export type Image = {
  createdAt?: string
  filename?: string
  filesize?: number
  mimeType?: string
  updatedAt?: string
  url: string
  thumbnail?: string
}

export type AudioFile = {
  createdAt?: string
  filename?: string
  filesize?: number
  mimeType?: string
  updatedAt?: string
  url: string
}

export type QuestionnaireExercise = {
  slug: string
  title: string
  description?: string
  duration?: string
  image?: Image
  hbSlug?: string
  published?: boolean
}
