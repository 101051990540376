/* eslint-disable unicorn/filename-case */
import { useCallback } from 'react'
// eslint-disable-next-line import/no-named-as-default
import toast, { ToastBar, Toaster } from 'react-hot-toast'
import { Alerts, General } from 'untitledui-js'

import { useSafeArea } from './useSafeArea'

type ShowToastProps = {
  message: string
  duration?: number
}

const ToastProvider = () => {
  const { bottom } = useSafeArea()

  return (
    <Toaster
      position="bottom-center"
      containerStyle={{
        zIndex: 11111,
        bottom: bottom + 120,
      }}
      toastOptions={{
        success: {
          icon: (
            <div className="w-10 h-10 flex flex-shrink-0 justify-center items-center rounded-full bg-success-100">
              <General.CheckCircle className="w-5 h-5 stroke-success-600" />
            </div>
          ),
        },
        error: {
          icon: (
            <div className="w-10 h-10 flex flex-shrink-0 justify-center items-center rounded-full bg-error-100">
              <Alerts.AlertCircle className="w-5 h-5 stroke-error-600" />{' '}
            </div>
          ),
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <div
              className="flex justify-center items-center"
              onClick={() => toast.dismiss(t.id)}
            >
              {icon}
              {message}
              {t.type === 'loading' ? null : (
                <div className="w-6 h-6 flex justify-center items-center cursor-pointer">
                  <General.X className="w-6 h-6 stroke-text-gray-900" />
                </div>
              )}
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

const useToast = () => {
  const showSuccessToast = useCallback(
    ({ message, duration = 4000 }: ShowToastProps) => {
      return toast.success(message, {
        position: 'bottom-center',
        className: '!rounded-full !p-1 !pr-4',
        duration,
      })
    },
    [],
  )

  const showErrorToast = useCallback(
    ({ message, duration = 4000 }: ShowToastProps) => {
      return toast.error(message, {
        position: 'bottom-center',
        className: '!rounded-full !p-1 !pr-4',
        duration,
      })
    },
    [],
  )

  return { showSuccessToast, showErrorToast }
}

export { ToastProvider, useToast }

/* eslint-enable unicorn/filename-case */
