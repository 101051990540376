import * as Yup from 'yup'

const LoginFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Dit is geen correct e-mailadres.')
    .required('Je e-maildres is verplicht.'),
  password: Yup.string().required('Je wachtwoord is verplicht.'),
})

export { LoginFormSchema }
