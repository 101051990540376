import { useState } from 'react'
import { Education, Files, MediaDevices } from 'untitledui-js'

import { gradient } from '@/pages/content-detail/components/IntroHeader'

type Props = {
  src?: string
  type: string
}

export const ContentImageWithFallback = ({ src, type }: Props) => {
  const [imageError, setImageError] = useState(!src)

  const handleImageError = () => {
    setImageError(true)
  }

  return (
    <div className="w-full h-[120px] bg-gray-200 object-cover rounded-lg overflow-hidden">
      {imageError ? (
        <ContentFallback type={type} />
      ) : (
        <img
          src={src}
          onError={handleImageError}
          alt="Image"
          className="w-full h-full object-cover"
          loading="lazy"
        />
      )}
    </div>
  )
}

type ContentFallbackProps = {
  type: string
}

const ContentFallback = ({ type }: ContentFallbackProps) => {
  switch (type) {
    case 'Questionnaire': {
      return (
        <div
          className={`w-full h-full flex justify-center items-center  ${gradient.Questionnaire}`}
        >
          <Files.FileQuestion02 className="w-10 h-10 stroke-base-white opacity-60 stroke-1" />
        </div>
      )
    }
    case 'exercise': {
      return (
        <div
          className={`w-full h-full flex justify-center items-center  ${gradient.exercise}`}
        >
          <Files.ClipboardCheck className="w-10 h-10 stroke-base-white opacity-60 stroke-1" />
        </div>
      )
    }
    case 'video': {
      return (
        <div
          className={`w-full h-full flex justify-center items-center  ${gradient.video}`}
        >
          <MediaDevices.PlayCircle className="w-10 h-10 stroke-base-white opacity-60 stroke-1" />
        </div>
      )
    }
    case 'audio': {
      return (
        <div
          className={`w-full h-full flex justify-center items-center  ${gradient.audio}`}
        >
          <MediaDevices.VolumeMax className="w-10 h-10 stroke-base-white opacity-60 stroke-1" />
        </div>
      )
    }
    case 'collection': {
      return (
        <div
          className={`w-full h-full flex justify-center items-center  ${gradient.collection}`}
        >
          <Files.Box className="w-10 h-10 stroke-base-white opacity-60 stroke-1" />
        </div>
      )
    }
    case 'article': {
      return (
        <div
          className={`w-full h-full flex justify-center items-center  ${gradient.article}`}
        >
          <Education.BookOpen01 className="w-10 h-10 stroke-base-white opacity-60 stroke-1" />
        </div>
      )
    }
    default: {
      return null
    }
  }
}
