import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const SnapCard = ({ children }: Props) => {
  return (
    <div className="w-full max-w-[calc(100vw-48px)] flex-shrink-0 scroll-smooth snap-start scroll-m-6">
      {children}
    </div>
  )
}
