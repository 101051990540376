import type { ReactNode } from 'react'
import { General } from 'untitledui-js'

type Props = {
  icon: ReactNode
  variant: 'notification' | 'message'
  text: string
  onClick: () => void
  onDismiss: () => void
}

export const DismissableCard = ({
  icon,
  variant,
  text,
  onClick,
  onDismiss,
}: Props) => {
  return (
    <div className="flex items-center bg-base-white border border-gray-50 -mx-6 px-6 shadow-md">
      <div
        className="flex flex-grow pl-4 py-3 items-center"
        onClick={() => onClick()}
      >
        <div
          className={`${
            variant === 'message' ? 'bg-secondary-green-500' : 'bg-error-600'
          } w-10 h-10 min-w-10 min-h-10 flex-shrink-0  flex justify-center items-center rounded-full mr-2 [&>svg]:stroke-base-white`}
        >
          {icon}
        </div>
        <p className="flex flex-grow font-body text-text-primary font-semibold">
          {text}
        </p>
      </div>

      <button
        className="flex justify-center items-center p-5"
        onClick={onDismiss}
      >
        <General.X className="w-5 h-5 stroke-text-primary" />
      </button>
    </div>
  )
}
