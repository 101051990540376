import type { SVGMotionProps } from 'framer-motion'

export const animation = {
  animation: {
    fast: {
      transition: 'all 0.2s ease-in-out',
    },
    normal: {
      transition: 'all 0.4s ease-in-out',
    },
    slow: {
      transition: 'all 0.6s ease-in-out',
    },
  },
}

export const iconAnimation: SVGMotionProps<SVGPathElement> = {
  initial: { pathLength: 0 },
  animate: { pathLength: 1 },
  transition: {
    duration: 6,
    delay: 0,
    type: 'spring',
    repeat: Infinity,
    repeatType: 'reverse',
  },
}

export const dropInAnimation = (initialY: number) => ({
  initial: {
    opacity: 0,
    y: initialY,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: initialY,
  },
})
