import type {
  QuestionAnswerInput,
  QuestionAnswerOption,
} from '@healthblocks-io/core'

import { Text } from '../typography/Typography'

type Props = {
  quickReplies: QuestionAnswerOption[]
  answer?: QuestionAnswerInput
  onSelect: (option: QuestionAnswerOption) => void
}

const QuickReply = ({ quickReplies, answer, onSelect }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      {quickReplies?.map((option, key) => (
        <button
          key={key}
          type="button"
          className={
            // @ts-expect-error
            (answer && (option.id || option.value) === answer.value
              ? 'bg-primary-50 text-primary-800 border-primary-600'
              : 'bg-white text-gray-700 border-gray-100') +
            ' rounded-md border p-4 flex justify-start'
          }
          onClick={() =>
            onSelect({
              value: option.value,
              label: option.label,
            })
          }
        >
          <Text typography="body">
            {option.label ||
              // @ts-expect-error migration
              option.valueString}
          </Text>
        </button>
      ))}
    </div>
  )
}

export { QuickReply }
