import { useLingui } from '@lingui/react'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { Communication, Education, General, Time, Users } from 'untitledui-js'

import { Navigation } from '@/components/navigation/Navigation'
import { useUnreadMessages } from '@/hooks/useNewMessages'

import { ChatIconWrapper, NavigationArea } from './RootNavigation.styled'

const RootNavigation = () => {
  const location = useLocation()

  const i18n = useLingui()
  const { unreadMessages } = useUnreadMessages()

  const navItems = useMemo(() => {
    const items = [
      {
        label: i18n._('nav.home', undefined, { message: 'Home' }),
        icon: <General.HomeSmile />,
        href: '/',
      },
      {
        label: i18n._('nav.discover', undefined, { message: 'Ontdek' }),
        icon: <Education.GraduationHat02 />,
        href: '/discover',
      },
      {
        label: i18n._('nav.careplan', undefined, { message: 'Plan' }),
        icon: <Time.CalenderHeart01 />,
        href: '/carepath',
      },
      {
        label: i18n._('nav.chat', undefined, { message: 'Chat' }),
        icon: (
          <ChatIconWrapper>
            {unreadMessages > 0 ? (
              <div className="absolute top-0 right-0 rounded-full w-3 h-3 bg-error-400 border-2 border-solid border-base-white z-10" />
            ) : null}
            <Communication.MessageCircle01 />
          </ChatIconWrapper>
        ),
        href: '/chat',
      },
      {
        label: i18n._('nav.profile', undefined, { message: 'Profile' }),
        icon: <Users.User03 />,
        href: '/profile',
      },
    ]

    return items?.map((item) => ({
      ...item,
      active: item.href === location.pathname,
    }))
  }, [unreadMessages, location, i18n.i18n.locale])

  return (
    <NavigationArea gridArea="navigation">
      <Navigation items={navItems} />
    </NavigationArea>
  )
}

export { RootNavigation }
