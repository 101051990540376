import { useActorId, useHealthblocksSWR } from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import { useNavigate } from 'react-router'
import { Time } from 'untitledui-js'

import SimpleCalendar from '@/components/calendar/SimpleCalendar'
import { PageHeader } from '@/components/header/PageHeader'
import type { FrontdoorCareplanActivity } from '@/utils/content'

import { ActivityModal } from './Activity.modal'

export type Appointment = {
  id: number
  title: string
  description: string
  date: Date
  duration: number
  type: string
  status: string
  meta: Record<string, unknown>
}

export const CalendarPage = () => {
  const navigate = useNavigate()
  const actorId = useActorId()
  const activities = useHealthblocksSWR<FrontdoorCareplanActivity[]>(
    actorId ? '/careplan-activity?subjectId=' + actorId : null,
  )

  const isLoading = activities.isLoading

  return (
    <div className="min-h-full flex flex-col bg-base-white">
      <PageHeader
        className="bg-primary-50 sticky top-0 z-10"
        title={i18n._('calendar.title', undefined, { message: 'Jouw plan' })}
        actions={
          <button
            className="w-12 h-12 flex justify-center items-center bg-primary-400 rounded-md"
            onClick={() => {
              const prefill = encodeURIComponent(
                `Ik wil graag een afspraak boeken.`,
              )
              navigate(`/chat?prefill=${prefill}`)
            }}
          >
            <Time.CalenderPlus01 className="w-5 h-5 stroke-base-white" />
          </button>
        }
      />
      {isLoading ? null : (
        <SimpleCalendar
          activities={activities.data || []}
          refetch={() => {
            console.log('refetching')
          }}
        />
      )}

      <ActivityModal
        onChange={() => {
          activities.mutate()
        }}
      />
    </div>
  )
}
