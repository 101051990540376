import { Capacitor } from '@capacitor/core'

import { createGlobal } from './global-hook'

/** Keyboard height that overlaps the webview (Android only) */
const keyboardHeight = createGlobal(0)

/** Keyboard height that overlaps the webview (Android only) */
export const useKeyboardHeight = keyboardHeight.hook

if (Capacitor.getPlatform() === 'android') {
  // @ts-expect-error
  window.addEventListener('keyboardWillShow', ({ keyboardHeight: height }) => {
    if (typeof height === 'number') keyboardHeight.setData(height)
  })

  window.addEventListener('keyboardWillHide', () => {
    keyboardHeight.setData(0)
  })
}
