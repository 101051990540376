import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'
import { General } from 'untitledui-js'

type ButtonDefaultProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

type ButtonCustomProps = {
  children?: ReactNode
  /** @default primary */
  variant?: keyof typeof classnames.variant
  /** @default small */
  size?: 'small' | 'medium' | 'large'
  prefixIcon?: string | ReactNode
  postfixIcon?: string | ReactNode
  isLoading?: boolean
  onClick?: () => void
}

type Props = ButtonDefaultProps & ButtonCustomProps

const shared =
  'w-full flex justify-center items-center rounded-md transition-colors duration-200 cursor-pointer select-none'
const classnames = {
  size: {
    small: 'px-4 py-2 gap-2 font-semibold leading-snug',
    medium: 'px-4 py-4 gap-2 text-[16px] font-semibold leading-snug',
    large: 'px-6 py-4 gap-4 text-[18px] font-semibold leading-snug',
  },
  variant: {
    primary: 'shadow-sm bg-primary-400 text-white group-hover:bg-primary-500',
    primaryDisabled: 'shadow-sm bg-gray-50 text-gray-500 cursor-default',
    secondary:
      'shadow-sm bg-secondary-green-600 text-white hover:bg-secondary-green-700',
    secondaryDisabled: 'shadow-sm bg-gray-50 text-gray-500 cursor-default',
    tertiary: 'shadow-sm bg-white text-gray-700 hover:bg-gray-50',
    tertiaryDisabled: 'shadow-sm bg-text-gray-200 cursor-default',
    tertiaryGray: 'text-gray-700 hover:bg-gray-50',
    tertiaryGrayDisabled: 'shadow-sm bg-text-gray-200 cursor-default',
    gray: 'shadow-sm bg-white text-gray-700 hover:bg-gray-50 border border-solid border-gray-300',
    grayDisabled: 'shadow-sm bg-text-gray-200 border-gray-200 cursor-default',
    error:
      'shadow-sm bg-error-600 text-base-white hover:bg-error-700 border border-solid border-error-600',
    errorOutline:
      'shadow-sm bg-base-white text-error-600 hover:bg-error-50 border border-solid border-error-600',
    errorDisabled: 'shadow-sm bg-text-gray-200 border-gray-200 cursor-default',
  },
}

const Button = ({
  children,
  variant = 'primary',
  disabled,
  size = 'small',
  prefixIcon,
  postfixIcon,
  isLoading,
  className: additionalClassnames = '',
  onClick,
  ...rest
}: Props) => {
  const sizeClassname = classnames.size[size]

  const variantKey = `${variant}${
    disabled ? 'Disabled' : ''
  }` as keyof typeof classnames.variant
  const variantClassname = classnames.variant[variantKey] || ''

  const classNamesToApply = `font-semibold ${shared} ${sizeClassname} ${variantClassname} ${additionalClassnames}`

  return (
    <button
      className={classNamesToApply}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {isLoading ? (
        <General.Loading02
          className={`w-[22px] h-[22px] object-cover animate-spin ${
            ['primary', 'secondary'].includes(variant)
              ? 'stroke-base-white'
              : 'stroke-gray-700'
          }
      `}
        />
      ) : (
        <>
          {prefixIcon ? (
            typeof prefixIcon === 'string' ? (
              <img
                src={prefixIcon}
                alt="itsme"
                className="w-6 h-auto object-cover"
              />
            ) : (
              prefixIcon
            )
          ) : null}
          {children}
          {postfixIcon ? (
            typeof postfixIcon === 'string' ? (
              <img
                src={postfixIcon}
                alt="itsme"
                className="w-6 h-auto object-cover"
              />
            ) : (
              postfixIcon
            )
          ) : null}
        </>
      )}
    </button>
  )
}

export { Button }
