import { i18n } from '@lingui/core'
import { isEmptyArray } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { isNil } from 'ramda'
import { Arrow, General } from 'untitledui-js'

import { useSafeArea } from '@/hooks/useSafeArea'

import { Button } from '../button/Button'
import { Text } from '../typography/Typography'
import { Question } from './Question'
import { useQuestionDirection, useQuestionHistory } from './question-history'

type Props = {
  onClose?: () => void
}

export default function PagedQuestionnaire({ onClose }: Props) {
  const { answer, index, total, input, question } = useQuestionHistory()
  const { direction, backward, forward } = useQuestionDirection()
  const safe = useSafeArea()

  const nextDisabled =
    question.type !== 'Display' &&
    question.required &&
    (isNil(answer?.value) || isEmptyArray(answer?.value))

  return (
    <div
      className={`flex-1 flex flex-col max-h-full justify-between ${
        !nextDisabled && `bg-brand-gradient`
      }`}
      style={{ paddingBottom: safe.bottom }}
    >
      <div className="flex flex-col items-center flex-shrink-0 z-10 -order-1 sticky top-0">
        <div className="w-full px-4 py-3 flex items-center justify-between">
          {index > 0 ? (
            <button className="flex flex-col" onClick={backward}>
              <div className="p-[0.6rem] bg-gray-50 rounded-full">
                <Arrow.ChevronLeft className="w-7 h-7 stroke-gray-500" />
              </div>
            </button>
          ) : (
            <div className="w-12 h-12" />
          )}

          <div className="flex gap-sp0.5">
            <Text typography="subheadline" className="font-bold">
              {index + 1}
            </Text>
            <Text typography="subheadline">/</Text>
            <Text typography="subheadline" className="font-bold">
              {total}
            </Text>
          </div>
          {onClose ? (
            <div className="flex flex-col" onClick={onClose}>
              <div className="p-[0.6rem] bg-gray-50 rounded-full">
                <General.X className="w-7 h-7 stroke-gray-500" />
              </div>
            </div>
          ) : (
            <div className="w-12 h-12" />
          )}
        </div>

        <div className="w-full h-2 bg-primary-100">
          <div
            className="flex h-2 items-center bg-primary-500 rounded-r-sm transition:width duration-500"
            style={{ width: Math.round(((index + 1) / total) * 100) + '%' }}
          />
        </div>
      </div>

      <form
        className="flex flex-col overflow-y-scroll"
        onSubmit={(event) => {
          event.preventDefault()
          forward()
        }}
      >
        <div className="flex flex-col max-h-full p-6 w-full overflow-x-hidden">
          <AnimatePresence mode="popLayout" initial={false}>
            <motion.div
              key={question.id}
              variants={slideAnimation}
              initial={direction === 'forward' ? 'slideLeft' : 'slideRight'}
              animate="visible"
              exit={direction === 'forward' ? 'slideRight' : 'slideLeft'}
              transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
            >
              <Question
                question={question}
                answer={answer}
                onAnswer={(a) => input(a, index)}
              />
            </motion.div>
          </AnimatePresence>
        </div>

        <div className="flex items-center w-full px-6 pb-6 order-1 sticky bottom-0 z-20">
          <Button
            type="submit"
            variant="primary"
            disabled={nextDisabled}
            isLoading={false}
            size="large"
          >
            {i18n._('questionnaire.next', {}, { message: 'Volgende' })}
          </Button>
        </div>
      </form>
    </div>
  )
}

const slideAnimation = {
  slideLeft: {
    opacity: 0,
    x: '100%',
  },
  visible: {
    opacity: 1,
    x: 0,
  },
  slideRight: {
    opacity: 0,
    x: '-100%',
  },
}
