import { useActorUpdate } from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import type { FormikHelpers } from 'formik'
import { Form, Formik } from 'formik'
import { useMemo, useState } from 'react'

import { Button } from '@/components/button/Button'
import { Checkbox } from '@/components/checkbox/Checkbox'
import { useToast } from '@/hooks/useToast'

import { PrivacyFormSchema } from './PrivacyForm.schema'

type Props = {
  onSuccess?: () => void
}

type FormValues = {
  checked: boolean
}

const PrivacyForm = ({ onSuccess }: Props) => {
  const [member, update] = useActorUpdate()
  const formikInitialValues: FormValues = useMemo<FormValues>(
    () => ({ checked: false }),
    [],
  )
  const { showErrorToast } = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (
    _: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    actions.setSubmitting(true)
    setIsSubmitting(true)
    try {
      await update({
        meta: {
          ...member.data?.meta,
          privacyPolicyAccepted: new Date().toJSON(),
        },
      })

      setTimeout(() => {
        actions.setSubmitting(false)
        setIsSubmitting(false)
        if (onSuccess) {
          onSuccess()
        }
      }, 100)
    } catch (error: any) {
      console.log(error)
      showErrorToast({
        message: i18n._('privacy.error', undefined, {
          message: 'Something went wrong',
        }),
      })
      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={PrivacyFormSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form className="flex flex-col gap-6">
          <div className="px-6">
            <Checkbox
              name="checked"
              id="checked"
              label={
                <Trans id="privacy.read_and_agreed">
                  Ik heb de privacyverklaring gelezen en goedgekeurd
                </Trans>
              }
            />
          </div>
          <div className="flex justify-center px-8 py-4 pb-6 bg-white shadow-md">
            <Button
              type="submit"
              variant="primary"
              disabled={!isValid}
              isLoading={isSubmitting}
              children={<Trans id="globals.next">Volgende</Trans>}
              size="large"
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export { PrivacyForm }
