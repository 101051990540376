import { useEffect, useState } from 'react'

import type {
  Article,
  Audio,
  Collection,
  Exercise,
  QuestionnaireExercise,
  Video,
} from '@/utils/content'
import {
  useArticles,
  useAudios,
  useCollections,
  useExercises,
  useQuestionaires,
  useVideos,
} from '@/utils/content'

import type { ContentCardProps } from '../card/ContentCard'

export const useContent = () => {
  const exercises = useExercises()
  const audios = useAudios()
  const videos = useVideos()
  const articles = useArticles()
  const collections = useCollections()
  const questionnaires = useQuestionaires()

  const loading =
    exercises.isLoading ||
    audios.isLoading ||
    videos.isLoading ||
    articles.isLoading ||
    collections.isLoading ||
    questionnaires.isLoading

  const error =
    exercises.error ||
    audios.error ||
    videos.error ||
    articles.error ||
    collections.error ||
    questionnaires.error

  const content = {
    exercises: exercises.data,
    audios: audios.data,
    videos: videos.data,
    articles: articles.data,
    collections: collections.data,
    questionnaires: questionnaires.data,
  }

  return {
    loading,
    error,
    content,
  }
}

export const useContentCards = () => {
  const { loading, error, content } = useContent()
  const [cardItems, setCardItems] = useState<ContentCardProps[]>([])

  useEffect(() => {
    if (!loading) {
      const cards = [
        ...mapExercisesToCard(content.exercises),
        ...mapAudiosToCard(content.audios),
        ...mapVideosToCard(content.videos),
        ...mapArticlesToCard(content.articles),
        ...mapCollectionsToCard(content.collections),
        ...mapQuestionnairesToCard(content.questionnaires),
      ]?.filter((cardData) => cardData.published)

      setCardItems(cards)
    }
  }, [
    loading,
    content.exercises,
    content.audios,
    content.videos,
    content.articles,
    content.collections,
  ])

  return {
    loading,
    error,
    cardItems,
  }
}

export const mapExercisesToCard = (
  exercises?: Exercise[],
): ContentCardProps[] => {
  return (
    exercises?.map((exercise) => {
      return {
        id: exercise.slug,
        contentSlug: exercise.slug,
        title: exercise.title,
        type: 'exercise',
        imageUrl: exercise.image?.url,
        thumbnailUrl: exercise.image?.thumbnail,
        duration: exercise.duration,
        published: exercise.published,
      }
    }) || []
  )
}

export const mapAudiosToCard = (audios?: Audio[]): ContentCardProps[] => {
  return (
    audios?.map((audio) => {
      return {
        id: audio.slug,
        title: audio.title,
        type: 'audio',
        imageUrl: audio.image?.url,
        thumbnailUrl: audio.image?.thumbnail,
        duration: audio.duration,
        published: audio.published,
      }
    }) || []
  )
}

export const mapVideosToCard = (videos?: Video[]): ContentCardProps[] => {
  return (
    videos?.map((video) => {
      return {
        id: video.slug,
        title: video.title,
        type: 'video',
        imageUrl: video.image?.url,
        thumbnailUrl: video.image?.thumbnail,
        duration: video.duration,
        published: video.published,
      }
    }) || []
  )
}

export const mapArticlesToCard = (articles?: Article[]): ContentCardProps[] => {
  return (
    articles?.map((article) => {
      return {
        id: article.slug,
        title: article.title,
        type: 'article',
        imageUrl: article.image?.url,
        thumbnailUrl: article.image?.thumbnail,
        duration: article.duration,
        published: article.published,
      }
    }) || []
  )
}

const mapCollectionsToCard = (
  collections?: Collection[],
): ContentCardProps[] => {
  return (
    collections?.map((collection) => {
      return {
        id: collection.slug,
        title: collection.title,
        type: 'collection',
        imageUrl: collection.image?.url,
        thumbnailUrl: collection.image?.thumbnail,
        published: collection.published,
      }
    }) || []
  )
}

export const mapQuestionnairesToCard = (
  questionnaires?: QuestionnaireExercise[],
): ContentCardProps[] => {
  return (
    questionnaires?.map((questionnaire) => {
      return {
        id: questionnaire.slug,
        title: questionnaire.title,
        type: 'Questionnaire',
        imageUrl: questionnaire.image?.url,
        thumbnailUrl: questionnaire.image?.thumbnail,
        duration: questionnaire.duration,
        published: questionnaire.published,
      }
    }) || []
  )
}
