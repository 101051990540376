import { i18n } from '@lingui/core'
import {
  Communication,
  Education,
  Files,
  MediaDevices,
  Time,
} from 'untitledui-js'

type Props = {
  type?: string
}

export const ContentType = ({ type }: Props) => {
  switch (type) {
    case 'AppointmentRequest':
    case 'Appointment': {
      return (
        <div className="flex gap-1 items-center">
          <Time.Calender className="w-4 h-4 stroke-warning-500" />
          <p className="font-callout text-warning-500 font-semibold">
            {i18n._('activity.type.appointment.title', undefined, {
              message: 'Afspraak',
            })}
          </p>
        </div>
      )
    }
    case 'Questionnaire': {
      return (
        <div className="flex gap-1 items-center">
          <Files.FileQuestion02 className="w-4 h-4 stroke-primary-500" />
          <p className="font-callout text-primary-700 font-semibold">
            {i18n._('activity.type.questionnaire.title', undefined, {
              message: 'Vragenlijst',
            })}
          </p>
        </div>
      )
    }
    case 'Message': {
      return (
        <div className="flex gap-1 items-center">
          <Communication.MessageChatCircle className="w-4 h-4 stroke-primary-500" />
          <p className="font-callout text-primary-700 font-semibold">
            {i18n._('activity.type.message.title', undefined, {
              message: 'Bericht',
            })}
          </p>
        </div>
      )
    }
    case 'exercise': {
      return (
        <div className="flex gap-1 items-center">
          <Files.ClipboardCheck className="w-4 h-4 stroke-secondary-green-500" />
          <p className="font-callout text-secondary-green-700 font-semibold">
            {i18n._('activity.type.exercise.title', undefined, {
              message: 'Oefening',
            })}
          </p>
        </div>
      )
    }
    case 'video': {
      return (
        <div className="flex gap-1 items-center">
          <MediaDevices.PlayCircle className="w-4 h-4 stroke-secondary-purple-500" />
          <p className="font-callout text-secondary-purple-700 font-semibold">
            {i18n._('activity.type.video.title', undefined, {
              message: 'Video',
            })}
          </p>
        </div>
      )
    }
    case 'audio': {
      return (
        <div className="flex gap-1 items-center">
          <MediaDevices.VolumeMax className="w-4 h-4 stroke-secondary-aqua-500" />
          <p className="font-callout text-secondary-aqua-700 font-semibold">
            {i18n._('activity.type.audio.title', undefined, {
              message: 'Audio',
            })}
          </p>
        </div>
      )
    }
    case 'collection': {
      return (
        <div className="flex gap-1 items-center">
          <Files.Box className="w-4 h-4 stroke-success-700" />
          <p className="font-callout text-success-700 font-semibold">
            {i18n._('activity.type.collection.title', undefined, {
              message: 'Collectie',
            })}
          </p>
        </div>
      )
    }
    case 'article': {
      return (
        <div className="flex gap-1 items-center">
          <Education.BookOpen01 className="w-4 h-4 stroke-secondary-pink-500" />
          <p className="font-callout text-secondary-pink-700 font-semibold">
            {i18n._('activity.type.article.title', undefined, {
              message: 'Artikel',
            })}
          </p>
        </div>
      )
    }
    default: {
      return null
    }
  }
}
