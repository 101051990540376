import './no-eval'
import './theme/root.css'

import {
  ActorProvider,
  AnalyticsProvider,
  AuthProvider,
  AuthSubjectProvider,
  ProjectProvider,
  ServerProvider,
} from '@healthblocks-io/core'
// import { setupIonicReact } from '@ionic/react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { loadLanguageFromLocalStorage } from '@/utils/lingui'

import { healthblocksApi, preset } from './config/environment'
import { UnreadMessageProvider } from './hooks/useNewMessages'
import { HealthblocksProjectFallback } from './layout/HealthblocksProjectFallback'
import { LayoutProvider } from './layout/providers/LayoutProvider'
import { NetworkConnected } from './pages/network/NetworkConnected'
import { router } from './routing/router'
import { FrontdoorThemeProvider } from './theme/ThemeProvider'
import { forceSetOverlaysWebView } from './utils/status-bar'

loadLanguageFromLocalStorage()

forceSetOverlaysWebView(true)

createRoot(document.querySelector('#react-root')!).render(
  <StrictMode>
    <ServerProvider api={healthblocksApi}>
      <ProjectProvider
        pid={preset.pid}
        // domain={window.location.hostname}
        fallback={HealthblocksProjectFallback}
      >
        <AuthProvider>
          <ActorProvider>
            <AuthSubjectProvider>
              <AnalyticsProvider>
                <I18nProvider i18n={i18n}>
                  <FrontdoorThemeProvider config={{ spacingBase: 8 }}>
                    <LayoutProvider>
                      <UnreadMessageProvider>
                        <NetworkConnected>
                          <RouterProvider router={router} />
                        </NetworkConnected>
                      </UnreadMessageProvider>
                    </LayoutProvider>
                  </FrontdoorThemeProvider>
                </I18nProvider>
              </AnalyticsProvider>
            </AuthSubjectProvider>
          </ActorProvider>
        </AuthProvider>
      </ProjectProvider>
    </ServerProvider>
  </StrictMode>,
)
