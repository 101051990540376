import type {
  ActivityType,
  CareplanActivity,
  CareplanActivityMeta,
  IQuestionnaire,
  QuestionnaireResponse,
} from '@healthblocks-io/core'
import { Link } from 'react-router-dom'

import { Card } from '@/components/Card'
import { useContentBySlug } from '@/utils/content'

import { AppointmentRequestContent } from './AppointmentRequestContent'
import { DefaultContent } from './DefaultContent'
import { QuestionnaireContent } from './QuestionnaireContent'

type Props = {
  activity: CareplanActivity<ActivityType, CareplanActivityMeta> & {
    questionnaire?: IQuestionnaire
    questionnaireResponse?: QuestionnaireResponse
  }
  mutate: () => void
}

export const NextBestActionCard = ({ activity, mutate }: Props) => {
  const content = useContentBySlug(activity.type, activity.contentSlug)
  return (
    <Link to={`?activityId=${activity.id}`} preventScrollReset>
      <Card className="flex p-2 h-[200px] min-h-[200px]">
        {activity.type === 'Questionnaire' ? (
          <QuestionnaireContent activity={activity} mutate={mutate} />
        ) : activity.type === 'AppointmentRequest' ? (
          <AppointmentRequestContent activity={activity} />
        ) : (
          <DefaultContent activity={activity} />
        )}

        {content?.image ? (
          <img
            src={content.image.url}
            className="w-28 h-full object-cover rounded-md"
          />
        ) : null}
      </Card>
    </Link>
  )
}
