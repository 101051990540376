import type { ReactNode } from 'react'
import { NavLink, type To } from 'react-router-dom'

import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'

import type { NavigationItem } from './Navigation.styled'
import { NavigationContainer, NavigationIcon } from './Navigation.styled'

type NavigationItem = {
  label: string
  icon: ReactNode
  href: To
  active: boolean
}

type NavigationProps = {
  items: NavigationItem[]
}

const Navigation = ({ items }: NavigationProps) => {
  return (
    <div className="bg-white h-full md:shadow-lg">
      <NavigationContainer>
        {items.map((item) => (
          <NavLink
            className={
              'flex flex-grow flex-col justify-center items-center gap-1 text-center select-none overflow-hidden z-10 p-4 pb-0 transition-opacity duration-200 outline-none'
            }
            key={item.href.toString()}
            to={item.href}
          >
            <NavigationIcon $active={item.active}>{item.icon}</NavigationIcon>
            <div
              className={`text-[13px] sm:text-sm md:text-md leading-snug font-semibold overflow-hidden w-full overflow-ellipsis whitespace-nowrap ${
                item.active ? 'text-[#48505E]' : 'text-[#858D9D]'
              }`}
            >
              {item.label}
            </div>
            <SafeAreaBottomSpacer />
          </NavLink>
        ))}
      </NavigationContainer>
    </div>
  )
}

export { Navigation }
