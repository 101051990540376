import { i18n } from '@lingui/core'

import { messages as en } from '../locales/en'
import { messages as fr } from '../locales/fr'
import { messages as nl } from '../locales/nl'

export const languages = [
  { locale: 'en', display: 'English' },
  { locale: 'nl', display: 'Nederlands' },
  { locale: 'fr', display: 'Français' },
  { locale: 'dev', display: 'Dev' },
] as const

export const locales = languages.map((l) => l.locale)

export const defaultLanguage = languages[0]

export const defaultLocale = defaultLanguage.locale

export function setLanguage(locale: (typeof languages)[number]['locale']) {
  locale = languages.some((l) => l.locale === locale) ? locale : defaultLocale
  let messages = locale === 'fr' ? fr : locale === 'nl' ? nl : en

  // Dev language (extra long strings)
  if (locale === 'dev') {
    messages = { ...messages }
    for (const key in messages) {
      const element = messages[key as keyof typeof messages]
      if (typeof element === 'string') {
        messages[key as keyof typeof messages] = [key, element, element].join(
          ' ',
        )
      }
    }
  }

  localStorage.language = locale
  i18n.loadAndActivate({
    locale,
    messages,
  })
}

export function loadLanguageFromLocalStorage() {
  if (i18n.locale) console.log('loadLanguageFromLocalStorage was called twice')
  else setLanguage(localStorage.language)
}
