import type { CareplanActivity } from '@healthblocks-io/core/types'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import isPast from 'date-fns/isPast'
import isThisYear from 'date-fns/isThisYear'
import isToday from 'date-fns/isToday'
import isTomorrow from 'date-fns/isTomorrow'
import { nl } from 'date-fns/locale'

import { CalendarCard } from '@/components/calendar/calendar-card/CalendarCard'
import { Container } from '@/layout/Container'

import type { IndexListItem } from './IndexList'

export type ActivityDay<T = CareplanActivity> = IndexListItem<
  Day<T> | MonthStart
>

export type Day<T> = {
  date: Date
  activities: T[]
}

export type MonthStart = {
  date: Date
  month: string
}

export function CalendarItem({ data }: ActivityDay) {
  const monthStart = data as MonthStart
  if (monthStart.month) {
    return (
      <Container>
        <div className="flex justify-start items-center py-2">
          <p className="font-footNote text-text-secondary">
            {monthStart.month}
          </p>
        </div>
      </Container>
    )
  }

  const d = data as Day<CareplanActivity>
  if (!d.activities) {
    return <div style={{ paddingTop: 1 }}>no activ? {JSON.stringify(data)}</div>
  }
  if (d.activities.length === 0) {
    return <div style={{ paddingTop: 1 }} />
  }

  const dateString = isToday(d.date)
    ? 'Vandaag'
    : isTomorrow(d.date)
    ? 'Morgen'
    : isThisYear(d.date)
    ? format(d.date, 'E dd MMMM', {
        locale: nl,
      })
    : format(d.date, 'E dd MMMM yyyy', {
        locale: nl,
      })

  return (
    <Container>
      <div
        id={isToday(d.date) ? 'today' : undefined}
        className={`flex flex-col gap-4 mb-4 ${
          isPast(endOfDay(d.date)) ? 'opacity-90' : 'opacity-100'
        }`}
      >
        <p className="font-body font-semibold text-text-primary sticky top-[76px] bg-base-white py-2.5 -mx-6 px-6">
          {dateString}
        </p>
        <div className="flex flex-col">
          {d.activities.map((activity, key) => (
            <CalendarCard
              key={key}
              {...activity}
              startTime={activity?.startTime}
              duration={activity?.duration}
            />
          ))}
        </div>
      </div>
    </Container>
  )
}
