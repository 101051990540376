import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import type { Extension } from '@healthblocks-io/core'
import { useApi, useProjectId } from '@healthblocks-io/core'
import axios from 'axios'
import { useMemo } from 'react'
import useSWR from 'swr'

import { createGlobal } from './global-hook'

const web = Capacitor.getPlatform() === 'web'

export const { hook: useAppVersion, setData: setAppVersion } = createGlobal(
  web ? APP_VERSION : undefined,
)

export function useMinimumVersion() {
  const current = useAppVersion()
  const api = useApi()
  const projectId = useProjectId()
  const swr = useSWR<
    Extension<{
      version: string
      platform: 'android' | 'ios'
      required?: boolean
    }>[]
  >(
    api && projectId && current
      ? api +
          '/extension?context=app&type=minimum_version&projectId=' +
          projectId
      : null,
    (url: string) => axios.get(url).then((r) => r.data),
  )
  return useMemo(() => {
    const nudge = swr.data?.find((extension) => {
      // Ignore web
      if (!current || typeof current !== 'string') return false

      // Ignore irrelevant platforms
      if (
        extension.options.platform &&
        extension.options.platform !== Capacitor.getPlatform()
      )
        return false

      // Ignore older versions
      if (
        typeof extension.options.version === 'string' ||
        typeof extension.options.version === 'number'
      )
        return isNewer(current, String(extension.options.version))
    })
    return nudge?.options
  }, [current, swr.data])
}

// Simple semver comparison
export const isNewer = (current: string, suggestion: string) => {
  const [major, minor, patch] = current.split('.').map((n) => parseInt(n))
  const [sMajor, sMinor, sPatch] = suggestion.split('.').map((n) => parseInt(n))
  if (major < sMajor) return true
  if (major > sMajor) return false
  if (minor < sMinor) return true
  if (minor > sMinor) return false
  return patch < sPatch
}

if (!web) {
  App.getInfo()
    .then((info) => {
      console.log('App version', info)
      setAppVersion(info.version)
    })
    .catch(() => {
      setAppVersion('latest')
    })
}
