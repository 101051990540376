import 'react-phone-input-2/lib/material.css'
import './PhoneInput.css'

import { Capacitor } from '@capacitor/core'
import { useField } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import type { FocusEvent, InputHTMLAttributes } from 'react'
import ReactPhoneInput from 'react-phone-input-2'

import { dropInAnimation } from '@/theme/animation'
import type { PartiallyRequired } from '@/utils/typescript'

import { Text } from '../typography/Typography'

type PhoneInputProps = {
  label?: string
} & PartiallyRequired<InputHTMLAttributes<HTMLInputElement>, 'name'>

const PhoneInput = ({ label, ...props }: PhoneInputProps) => {
  const [field, meta, helpers] = useField(props)

  const hasError = meta.touched && !!meta.error

  const onChange = (value: string) => {
    helpers.setValue(value ? `+${value}` : undefined)
    helpers.setTouched(true)
  }

  return (
    <div className="w-full flex flex-col gap-2 relative pb-2" id="phoneInput">
      {label && (
        <Text
          typography="subheadline"
          className={`transition-colors duration-200 ${
            hasError ? '!text-error-500' : '!text-gray-700'
          } font-bold`}
        >
          <label htmlFor={props.name}>{label}</label>
        </Text>
      )}

      <div className="relative">
        <ReactPhoneInput
          country={'be'}
          regions={'europe'}
          {...props}
          {...field}
          onChange={onChange}
          onFocus={(event: FocusEvent<HTMLInputElement>) => {
            setTimeout(() => {
              if (Capacitor.isNativePlatform()) {
                event.target.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                })
              }
            }, 700)
          }}
          containerClass="relative"
          inputClass={`relative p-4 pl-12 scroll-m-[200px] !w-full rounded-sm bg-base-white !border !border-solid !shadow-sm !transition-colors !duration-200
            ${hasError ? '!text-error-500' : '!text-gray-700'}
            ${hasError ? '!border-error-500' : '!border-gray-300'}`}
          dropdownClass="h-[125px]"
          buttonClass="outline-none absolute top-0 bottom-0 w-13 h-full border-r border-solid"
        />
      </div>

      <AnimatePresence>
        {hasError ? (
          <motion.div
            className="w-full absolute top-full"
            {...dropInAnimation(10)}
            key={meta.error}
          >
            <Text typography="caption1" className="text-error-500">
              {meta.error}
            </Text>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}

export { PhoneInput }
