import { Container } from '../Container'

type Props = {
  children: React.ReactNode
  image: string
}

const LoginLayout = ({ children, image }: Props) => {
  return (
    <div className="h-screen flex flex-col overflow-auto">
      <div className="flex-grow min-h-0 relative">
        <div className="w-full h-full overflow-hidden relative">
          <img
            src={image}
            alt="Background"
            className="w-full h-full object-cover bg-slate-500"
            loading="lazy"
          />
        </div>
      </div>
      <Container className="pt-12 bg-brand-gradient">{children}</Container>
    </div>
  )
}

export { LoginLayout }
