import { Style } from '@capacitor/status-bar'
import { useLingui } from '@lingui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import { Button } from '@/components/button/Button'
import { PageHeader } from '@/components/header/PageHeader'
import { Container } from '@/layout/Container'
import { useHideNativeNavigation } from '@/layout/providers/LayoutProvider'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'
import { useMinimumVersion } from '@/utils/app-version'
import { languages, setLanguage } from '@/utils/lingui'
import { NotificationSettings } from '@/utils/push-notification'
import { useStatusBarStyle } from '@/utils/status-bar'

import { DeleteAccountModal } from './modal/DeleteAccount.modal'

export const SettingsPage = () => {
  const i18n = useLingui()
  const language = languages.find((l) => l.locale === i18n.i18n.locale)?.display

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const navigate = useNavigate()
  const nudge = useMinimumVersion()

  useStatusBarStyle(Style.Light)
  useHideNativeNavigation()

  return (
    <div className="h-full flex flex-col bg-base-white">
      <PageHeader
        title={i18n._('profile.settings.title', undefined, {
          message: 'Settings',
        })}
        onBack={() => {
          navigate(-1)
        }}
      />
      <Container className="flex-grow">
        <div className="flex flex-col py-6 h-full">
          <div className="flex flex-col flex-grow gap-6">
            <div className="flex flex-col gap-1">
              <p className="font-body text-gray-700 font-semibold">
                {i18n._('profile.language', { language }, { message: 'Taal' })}
              </p>
              <ul className="flex flex-wrap gap-2">
                {languages
                  .filter((l) => l.locale !== 'dev')
                  .map((l) => (
                    <li key={l.locale}>
                      <Button
                        variant={language === l.display ? 'primary' : 'gray'}
                        size="small"
                        onClick={() => setLanguage(l.locale)}
                      >
                        {l.display}
                      </Button>
                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <NotificationSettings />
            </div>
          </div>

          <div className="flex flex-col gap-1 pb-6">
            <Button
              variant="errorOutline"
              size="medium"
              onClick={() => {
                setShowDeleteAccountModal(true)
              }}
            >
              {i18n._('general.delete_account', undefined, {
                message: 'Delete Account',
              })}
            </Button>
            <SafeAreaBottomSpacer />
          </div>
        </div>
      </Container>

      <DeleteAccountModal
        open={showDeleteAccountModal}
        onClose={() => {
          setShowDeleteAccountModal(false)
        }}
      />
    </div>
  )
}
