import styled from 'styled-components'

import { PageArea } from '../pageArea/PageArea'

const NavigationArea = styled(PageArea)``

const ChatIconWrapper = styled.div`
  position: relative;
`

const NotificationDot = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #a5ac00;
  border: 2px solid white;
`

export { NavigationArea, ChatIconWrapper, NotificationDot }
