import type {
  ActivityType,
  CareplanActivity,
  CareplanActivityMeta,
  IQuestionnaire,
} from '@healthblocks-io/core'
import { useHealthblocksSWR } from '@healthblocks-io/core'
import { useLingui } from '@lingui/react'

import { NextBestActionCard } from '../next-best-action/NextBestActionCard'
import { EmptyCard } from './Empty'
import { ErrorCard } from './ErrorCard'
import { SnapCard } from './SnapCard'

type Props = {
  error?: Error
  activities?: CareplanActivity<ActivityType, CareplanActivityMeta>[]
  mutate: () => void
}

export const ActivityCarousel = ({ activities, mutate, error }: Props) => {
  const questionnaires = useHealthblocksSWR<IQuestionnaire[]>('/questionnaire')
  const i18n = useLingui()

  return (
    <div className="flex flex-row items-stretch justify-stretch gap-4 -mx-6 px-6 overflow-x-auto snap-x snap-mandatory scrollbar-hide md:scrollbar-default">
      {activities?.length ? (
        activities.map((activity) => (
          <SnapCard key={activity.id}>
            <NextBestActionCard activity={activity} mutate={mutate} />
          </SnapCard>
        ))
      ) : error ? (
        <ErrorCard
          onReload={async () => {
            await questionnaires.mutate()
            mutate()
          }}
        >
          <div className="semibold">
            {i18n._('activity.error', undefined, {
              message: 'Er is iets misgegaan bij het ophalen van je acties.',
            })}
          </div>
          <div>{error.message}</div>
        </ErrorCard>
      ) : (
        <EmptyCard
          onReload={async () => {
            await questionnaires.mutate()
            mutate()
          }}
        />
      )}
    </div>
  )
}
