import type { Typography } from '@/theme/types/typography'
import { typography as tailwindTypography } from '@/theme/typography'

type TypographyType = keyof Typography

type ParagraphProps = {
  /** Defaults to body */
  typography?: TypographyType

  className?: string
  children: string | React.ReactNode
}

/** Consider using className="font-body text-body" */
const Text = ({ typography = 'body', className, children }: ParagraphProps) => {
  return (
    <p
      className={`font-sans whitespace-pre-line ${tailwindTypography[typography]} ${className}`}
    >
      {children}
    </p>
  )
}

const Span = ({ typography = 'body', className, children }: ParagraphProps) => {
  return (
    <span
      className={`font-sans whitespace-pre-line ${tailwindTypography[typography]} ${className}`}
    >
      {children}
    </span>
  )
}

export { Text, Span }
