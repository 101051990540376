import { Style } from '@capacitor/status-bar'
import { i18n } from '@lingui/core'
import { useNavigate } from 'react-router'

import { PageHeader } from '@/components/header/PageHeader'
import { useHideNativeNavigation } from '@/layout/providers/LayoutProvider'
import { useStatusBarStyle } from '@/utils/status-bar'

import { EditProfileForm } from './forms/EditProfileForm'

export const EditProfilePage = () => {
  const navigate = useNavigate()

  useStatusBarStyle(Style.Light)
  useHideNativeNavigation()

  return (
    <div className="min-h-full flex flex-col bg-base-white">
      <PageHeader
        title={i18n._('profile.edit.title', undefined, {
          message: 'Personal Information',
        })}
        onBack={() => {
          navigate(-1)
        }}
      />
      <EditProfileForm
        onSuccess={() => {
          navigate(-1)
        }}
        buttonLabel={i18n._(
          'profile.edit.button',
          {},
          { message: 'Save changes' },
        )}
      />
    </div>
  )
}
