import { useActorContext, useAuth } from '@healthblocks-io/core'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import Modal from '@/components/modal/Modal'
import NotificationModalBody from '@/components/modal/NotificationModalBody'
import { useKeyboardHeight } from '@/utils/keyboard'
import {
  useNotificationChannelSync,
  useNotificationModal,
} from '@/utils/push-notification'

import { useLayout } from '../providers/LayoutProvider'
import { PageContainer, PageContent, PageGrid } from './PageLayout.styled'

type PageLayoutProps = {
  header?: ReactNode
  navigation?: ReactNode
  children?: ReactNode
}

/** This layout is only relevant in authenticated pages, so it will redirect the user away if anonymous */
export const PageLayout = ({
  header,
  navigation,
  children,
}: PageLayoutProps) => {
  // Sync notification channels on sign in
  useNotificationChannelSync()
  const notificationModal = useNotificationModal()

  // Consider redirect user to login if not authenticated
  const navigate = useNavigate()
  const auth = useAuth()
  const backToLogin = auth && !auth.loading ? !auth.data : false

  // const member = useActorMember()
  const actorContext = useActorContext()
  const member = actorContext?.members.data?.[0] || null

  const { isNavigationVisible } = useLayout()
  const keyboardVisible = useKeyboardHeight()
  const showNavigation = !keyboardVisible && isNavigationVisible && navigation

  // Redirect
  useEffect(() => {
    if (backToLogin) {
      navigate('/login?error=unauthorized')
    } else if (member && !member.meta?.privacyPolicyAccepted) {
      navigate('/privacy')
    } else if (member && !member.meta?.profileCompleted) {
      navigate('/profile/complete')
    }
  }, [backToLogin, member])

  if (backToLogin || !auth || auth.loading) {
    return null
  }

  // Actual page layout
  return (
    <PageContainer>
      <PageGrid>
        {header}
        {showNavigation ? navigation : null}
        <PageContent>{children || <Outlet />}</PageContent>
      </PageGrid>
      <Modal
        title=""
        open={notificationModal}
        onBlur={() => {
          // some ios sim bug makes the modal blur too soon, so blur is disabled for now
          // setNotificationModal(false)
        }}
        bodyClassName="rounded-lg max-w-sm"
        dialog
      >
        <NotificationModalBody />
      </Modal>
    </PageContainer>
  )
}
