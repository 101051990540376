import { Style } from '@capacitor/status-bar'
import { i18n } from '@lingui/core'
import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button } from '@/components/button/Button'
import { StatusBarFade } from '@/components/statusbar/StatusBarFade'
import { Container } from '@/layout/Container'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'
import type { Exercise, FrontdoorCareplanActivity } from '@/utils/content'
import { useStatusBarStyle } from '@/utils/status-bar'

import { ExerciseStep } from '../components/ExerciseStep'
import { IntroHeader } from '../components/IntroHeader'
import { IntroTags } from '../components/IntroTags'

type Props = {
  content: Exercise
  onClose: () => void
  onComplete: () => void
  activity?: FrontdoorCareplanActivity
}

export const ExerciseIntro = ({
  activity,
  content,
  onClose,
  onComplete,
}: Props) => {
  const [search, setSearch] = useSearchParams()
  const step = parseInt(search.get('step') || '0')

  useStatusBarStyle(Style.Light)

  const startExercise = useCallback(() => {
    setSearch((search) => {
      search.set('step', '1')
      return search
    })
  }, [])

  useEffect(() => {
    if (step) {
      const stepElement = document.querySelector('#step-' + step)
      if (stepElement) {
        stepElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }
    }
  }, [step])

  if (step) {
    return (
      <ExerciseStep
        stepNumber={step}
        exercise={content}
        setStep={(step) => {
          setSearch((search) => {
            search.set('step', step.toString())
            return search
          })
        }}
        onClose={onClose}
        onComplete={onComplete}
      />
    )
  }

  return (
    <div className="w-full h-full overflow-auto flex flex-col bg-brand-gradient">
      <IntroHeader content={content} type={'exercise'} onClose={onClose} />
      <div id="wrapper" className="w-full flex flex-grow flex-col">
        <StatusBarFade />
        <Container className="flex flex-col flex-grow gap-2 mt-6">
          <IntroTags type={'exercise'} duration={content.duration} />
          <div className="flex flex-col flex-grow gap-6">
            <div className="flex flex-col flex-grow gap-2">
              <p className="text-brand font-title1 font-bold">
                {activity?.display || content.title}
              </p>

              {content.intro ? (
                <p className="text-text-secondary font-body">
                  {activity?.meta.message || content.intro}
                </p>
              ) : null}
            </div>

            <div className="flex flex-col pb-6">
              <Button variant="primary" size="large" onClick={startExercise}>
                {i18n._('exercise.start', undefined, {
                  message: 'Start deze oefening',
                })}
              </Button>
              <SafeAreaBottomSpacer />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
