import { evaluateCondition, useEvent } from '@healthblocks-io/core'
import { warn } from '@healthblocks-io/core/log'
import type {
  IQuestionnaire,
  IQuestionnaireQuestion,
  QuestionAnswerInput,
} from '@healthblocks-io/core/types'
import type { ReactNode } from 'react'
import { createContext, useCallback, useContext, useRef, useState } from 'react'

export type QuestionHistoryEntry = {
  question: IQuestionnaireQuestion
  answer?: QuestionAnswerInput
  // filter?: string[]
  /** Keeps a list of linkIds that should be skipped */
  skipIdsPromise?: Promise<string[]>
}

export type QuestionHistoryContext = {
  /** Should not be updated while in use */
  questionnaire: IQuestionnaire

  /** State */
  history: QuestionHistoryEntry[]
  /** Active question */
  question: IQuestionnaireQuestion
  /** Active answer */
  answer?: QuestionAnswerInput

  // status: 'in-progress' | 'completed' | 'stopped'
  /** Active question number */
  index: number
  /** Reverse question number */
  reverseIndex: number
  /** Total number of questions, could be lower than it should be */
  total: number

  // These verbs are derived from HTML: onInput, onChange, onSubmit

  /** Select answer without going to the next question */
  input: (value: QuestionAnswerInput, index?: number) => void
  /** Select answer and go to next question */
  change: (value: QuestionAnswerInput, index?: number) => void
  /** Skip a question or go back */
  move: (diff?: number) => void
  /** Skip a question or go back */
  next: (history: QuestionHistoryEntry[]) => void
}

export type Progress = {
  index: number
  answers: QuestionAnswerInput[]
  questionnaire: IQuestionnaire
}

export async function healthblocksLoadSkipIds(
  questionnaire: IQuestionnaire,
  history: QuestionHistoryEntry[],
) {
  return healthblocksLoadSkipIdsSync(questionnaire, history)
}

export function healthblocksLoadSkipIdsSync(
  questionnaire: IQuestionnaire,
  history: QuestionHistoryEntry[],
) {
  const answers = Object.fromEntries(
    history.map((entry) => [entry.question.linkId, entry.answer?.value]),
  )
  return questionnaire.item
    .filter((question) => !evaluateCondition(question.condition, answers))
    .map((question) => question.id!)
}

export type QuestionHistoryProps = {
  questionnaire: IQuestionnaire
  initialHistory?: QuestionHistoryEntry[]
  initialIndex?: number
  children?: ReactNode
  /** Should return a list of linkIds to skip */
  loadSkipIds?: (
    questionnaire: IQuestionnaire,
    history: QuestionHistoryEntry[],
  ) => Promise<string[]>
  /** Change the questionnaire based on the answers */
  transform?: (context: Progress) => IQuestionnaire
  /**
   * Called when the user answers a question
   *
   * Example:
   *
   *     onInput={() => toast('Answer changed')}
   *
   */
  onChange?: (context: Progress) => void
  /**
   * Called when the user moves to the next (or previous) question
   *
   * Example:
   *
   *     onMove={(index) => toast('Question ' + index)}
   *
   */
  onMove?: (context: Progress) => void
  /**
   * Called before submitting questionnaire, this allows you to enable/disable saving the response
   *
   * Examples:
   *
   * Don't save the response
   *
   *     onSubmit={console.log()}
   *
   * Verify the answers before saving
   *
   *     onSubmit={async ({ answers }) => {
   *       const ok = await postJSON('/verify', { answers })
   *       if (ok.valid) {
   *         await submitResponse({ answers })
   *       }
   *       navigation.goBack()
   *     }}
   */
  onSubmit?: (
    context: Omit<Progress, 'index'>,
  ) => void | null | Promise<void | null>
}

export const questionHistoryContext =
  createContext<QuestionHistoryContext | null>(null)

export const emptyArray = []

export const supportedQuestionTypes = new Set([
  'Display',
  'QuickReplies',
  'MultiSelect',
  'Rating',
  'Smiley',
  'Number',
  'Date',
  'BirthDate',
  'Time',
])

export function QuestionHistory({
  questionnaire,
  initialHistory,
  onSubmit,
  onMove,
  onChange,
  loadSkipIds = healthblocksLoadSkipIds,
  children,
}: QuestionHistoryProps) {
  const supportedQuestionnaireQuestions = questionnaire.item.filter(
    (question) => supportedQuestionTypes.has(question.type),
  )

  const debounce = useRef<any>()

  // History of questions and their answers
  const [history, setHistory] = useState<QuestionHistoryEntry[]>(
    initialHistory ||
      (() => [{ question: supportedQuestionnaireQuestions[0] }]),
  )

  // Keep track of traveling backwards when paged
  const [reverseIndex, setReverseIndex] = useState(0)
  const index = history.length - reverseIndex - 1
  const entry = history[index]

  // Total number of questions is
  const total =
    // Number of answered questions before this entry
    index +
    // Number of questions that will potentially come after this entry
    (supportedQuestionnaireQuestions.length -
      supportedQuestionnaireQuestions.findIndex(
        (event) => event.id === entry.question.id,
      ))

  // Filtered questions
  const nextQuestion = useCallback(
    async (history: QuestionHistoryEntry[]) => {
      const latest = history.at(-1)
      const skipIds = latest?.skipIdsPromise ? await latest.skipIdsPromise : []
      const linkIds = new Set(
        history.map((h) => h.question.linkId).filter(Boolean),
      )
      const ids = new Set(history.map((h) => h.question.id).filter(Boolean))

      return supportedQuestionnaireQuestions.find(
        (q) =>
          // Skip any linkId that is in the filter
          !skipIds.includes(q.id!) && !linkIds.has(q.linkId!) && !ids.has(q.id),
      )
    },
    [supportedQuestionnaireQuestions],
  )

  // Actions
  const debouncedOnChange = useEvent(
    () => onChange?.({ questionnaire, answers: ans(history), index }),
  )
  const next = useEvent(async (history: QuestionHistoryEntry[]) => {
    const question = await nextQuestion(history)
    if (question) setHistory((his) => [...his, { question }])
    else {
      clearTimeout(debounce.current)
      onSubmit?.({ questionnaire, answers: ans(history) })
    }
  })
  const input = useEvent(
    (answer: QuestionAnswerInput, answerIndex?: number) => {
      const entry = { ...history[answerIndex ?? index], answer }
      // This removes the filterPromise
      console.log(
        'answerIndex',
        answerIndex,
        index,
        history.length,
        entry,
        history.slice(0, answerIndex ?? index),
      )
      const answered = [...history.slice(0, answerIndex ?? index), entry]
      if (loadSkipIds)
        entry.skipIdsPromise = loadSkipIds(questionnaire, answered).catch(
          (error) => {
            console.error('All questions will be visible', error.message)
            return []
          },
        )
      setHistory(answered)
      if (reverseIndex) setReverseIndex(0)

      // debounce onChange
      clearTimeout(debounce.current)
      debounce.current = setTimeout(debouncedOnChange, 1000)

      return answered
    },
  )
  const change = useEvent(
    async (answer: QuestionAnswerInput, answerIndex?: number) =>
      next(input(answer, answerIndex)),
  )
  const move = useEvent(async (diff = 1) => {
    // Consider using setTimeout
    if (onMove)
      Promise.resolve().then(() =>
        onMove({ questionnaire, answers: ans(history), index: index + diff }),
      )

    // Keep history intact and pivot back to the
    if (diff < 0) {
      const r = reverseIndex - diff
      if (history.length - r < 1)
        // @ts-expect-error
        return warn('questionnaire', 'Cannot revert ' + -diff + ' questions')
      setReverseIndex(r)
      return
    }

    // Currently history mode is active, so let's just pivot back to the future
    if (reverseIndex) {
      setReverseIndex(reverseIndex - 1)
      return
    }

    // New question coming up!
    return next(history)
  })

  return (
    <questionHistoryContext.Provider
      value={{
        questionnaire,
        index,
        reverseIndex,
        total,
        history,
        question: entry.question,
        answer: entry.answer,
        change,
        input,
        move,
        next,
      }}
    >
      {children}
    </questionHistoryContext.Provider>
  )
}

export function useQuestionHistory() {
  const context = useContext(questionHistoryContext)
  if (!context) throw new Error('Must wrap in <QuestionHistory>')
  return context
}

function ans(entries: QuestionHistoryEntry[]) {
  return entries.map((entry) => entry.answer!).filter(Boolean)
}

export function useQuestionDirection() {
  const context = useContext(questionHistoryContext)
  if (!context) throw new Error('Must wrap in <QuestionHistory>')

  const [direction, setDirection] = useState<'backward' | 'forward'>('forward')

  const backward = useEvent(async () => {
    if (direction !== 'backward') await setDirection('backward')
    context.move(-1)
  })
  const forward = useEvent(async () => {
    if (direction !== 'forward') await setDirection('forward')
    context.move(1)
  })

  return { direction, backward, forward }
}

// type HandlerReturnType<T> = T extends Promise<any> ? T : Promise<T>;
// type LoadSkipIdsType<T> = T extends Promise<any> ? T : Promise<T>;
// function myFunction<T>(loadSkipIds: () => LoadSkipIdsType<T>): HandlerReturnType<T> {
//   const x = handler();
//   return Promise.resolve(x);
// }
/**
 * Get the next question based on all filled in questions
 * Returning to a previous question is not considered
 * Async loadSkipIds is not supported
 */
export function getNextQuestion({
  answers,
  history,
  questionnaire,
  loadSkipIds = healthblocksLoadSkipIdsSync,
}: {
  answers?: QuestionAnswerInput[]
  history?: QuestionHistoryEntry[]
  questionnaire: IQuestionnaire
  loadSkipIds?: (
    questionnaire: IQuestionnaire,
    history: QuestionHistoryEntry[],
  ) => string[] | Promise<string[]>
}) {
  // Rebuild history entries from answers
  if (!history?.length) {
    // Load all questions
    history = questionnaire.item.map((item) => ({
      question: item,
      answer: answers?.find((a) => item.linkId === a.linkId),
    }))
    // Find the index of the last answered entry
    const index = history.findLastIndex((item) => !!item.answer)
    // Remove all unanswered questions
    history = index >= 0 ? history.slice(0, index + 1) : []
  }

  // Load skipIds
  const latest = history.at(-1)!
  let skipIds: string[] = []
  if (latest && loadSkipIds) {
    skipIds = loadSkipIds(questionnaire, history) as string[]
    // Apply to every entry, should be ok because they get invalidated when the history changes
    // history.forEach((entry) => (entry.skipIdsPromise = skipIds))
    latest.skipIdsPromise = Promise.resolve(skipIds)
  }

  // Remove skipped ids from history
  history = history.filter((entry) => !skipIds.includes(entry.question.id!))

  const linkIds = new Set(history.map((h) => h.question.linkId).filter(Boolean))
  const ids = new Set(history.map((h) => h.question.id).filter(Boolean))
  const nextQuestion = questionnaire.item
    .filter((q) => supportedQuestionTypes.has(q.type))
    .find(
      (q) =>
        // Skip any linkId that is in the filter
        !skipIds.includes(q.id!) && !linkIds.has(q.linkId!) && !ids.has(q.id),
    )

  return {
    history,
    nextHistory: nextQuestion
      ? [...history, { question: nextQuestion }]
      : history,
    nextQuestion,
  }
}
