import type { HTMLMotionProps } from 'framer-motion'
import { AnimatePresence, motion } from 'framer-motion'
import type { JSXElementConstructor, ReactElement, ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { fadeGrowInOut, fadeInOut } from './SequentialAnimations'

type Props = {
  // Subset of ReactNode
  children: (
    | ReactElement<any, string | JSXElementConstructor<any>>
    | null
    | undefined
  )[]
}

export const SequentialLoader = ({ children }: Props) => {
  const [loadedSequences, setLoadedSequences] = useState<number[]>([])

  useEffect(() => {
    if (!children) {
      return
    }
    setLoadedSequences(
      children
        .map((child, index) => ({ index, isLoading: child?.props?.isLoading }))
        .filter((child) => !child.isLoading)
        .map((child) => child.index),
    )
  }, [children])

  return (
    <AnimatePresence>
      {children.map((child, index) => {
        const canShowCurrentChild =
          includesIntegerAndSmaller(loadedSequences, index) ||
          child?.props.ignoreSequence

        return canShowCurrentChild ? child : null
      })}
    </AnimatePresence>
  )
}

type SequenceItemProps = HTMLMotionProps<'div'> & {
  children: ReactNode
  key: string
  isLoading?: boolean
  ignoreSequence?: boolean
}

export const SequenceItem = ({ children, ...props }: SequenceItemProps) => {
  const [animate, setAnimate] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setAnimate(true)
    }, 2000)
  }, [])
  return (
    <motion.div
      {...(animate ? fadeGrowInOut : fadeInOut)} // Default to this animation
      {...props}
    >
      {children}
    </motion.div>
  )
}

function includesIntegerAndSmaller(array: number[], integer: number) {
  if (
    !Array.isArray(array) ||
    typeof integer !== 'number' ||
    isNaN(integer) ||
    integer < 0
  ) {
    return false // Return false for invalid input
  }

  for (let index = 0; index <= integer; index++) {
    if (!array.includes(index)) {
      return false // If any smaller integer is missing, return false
    }
  }

  return true // If all smaller integers are present, return true
}
