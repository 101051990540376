import { useActorMember, useActorUpdate } from '@healthblocks-io/core'
import { useLingui } from '@lingui/react'
import type { FormikHelpers } from 'formik'
import { Form, Formik } from 'formik'
import { useMemo } from 'react'

import { Button } from '@/components/button/Button'
import { Input } from '@/components/input/Input'
import { PhoneInput } from '@/components/input/PhoneInput'
import { useToast } from '@/hooks/useToast'
import { Container } from '@/layout/Container'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'

import { EditProfileFormSchema } from './EditProfileForm.schema'

type EditProfileFormProps = {
  buttonLabel: string
  onSuccess?: () => void
}

type FormValues = {
  familyName: string
  givenName: string
  email: string
  phone: string
}

export const EditProfileForm = ({
  buttonLabel,
  onSuccess,
}: EditProfileFormProps) => {
  const i18n = useLingui()
  const [_, update] = useActorUpdate()
  const actorMember = useActorMember()
  const { showErrorToast, showSuccessToast } = useToast()

  const formikInitialValues: FormValues = useMemo<FormValues>(() => {
    return {
      familyName: actorMember?.familyName ?? '',
      givenName: actorMember?.givenName ?? '',
      email: actorMember?.email ?? '',
      phone: actorMember?.phone ?? '',
    }
  }, [actorMember])

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    if (!actorMember) {
      return
    }

    actions.setSubmitting(true)
    try {
      const ok = await update({
        givenName: values.givenName,
        familyName: values.familyName,
        email: values.email,
        phone: values.phone ?? '',
      })!
      console.log('editprofile.ok', ok!)

      showSuccessToast({
        message: i18n._(
          'profile.edit.success',
          {},
          { message: 'Profile updated' },
        ),
      })
      onSuccess?.()
    } catch (error: any) {
      console.log('editprofile.error', error)
      showErrorToast({
        message: i18n._(
          'profile.edit.error',
          {},
          { message: 'Something went wrong' },
        ),
      })
      actions.setSubmitting(false)
    }
  }

  return (
    <Container className="h-full relative flex flex-col gap-6 pt-6">
      <Formik
        initialValues={formikInitialValues}
        validationSchema={EditProfileFormSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, isSubmitting }) => (
          <Form className="h-full flex flex-col flex-grow gap-4">
            <div className="flex flex-col gap-4 flex-grow relative z-0">
              <Input
                label={i18n._(
                  'profile.given_name.label',
                  {},
                  { message: 'Voornaam' },
                )}
                name="givenName"
                type="text"
                placeholder={i18n._(
                  'profile.given_name.placeholder',
                  {},
                  { message: 'Vul je voornaam in' },
                )}
              />
              <Input
                label={i18n._(
                  'profile.family_name.label',
                  {},
                  { message: 'Achternaam' },
                )}
                name="familyName"
                type="text"
                placeholder={i18n._(
                  'profile.family_name.placeholder',
                  {},
                  { message: 'Vul je achternaam in' },
                )}
              />
              <Input
                label={i18n._('profile.email.label', {}, { message: 'Email' })}
                name="email"
                type="email"
                placeholder={i18n._(
                  'profile.email.placeholder',
                  {},
                  { message: 'Vul je e-mailadres in' },
                )}
              />
              <PhoneInput
                label={i18n._(
                  'profile.phone.label',
                  {},
                  { message: 'Telefoonnummer (optioneel)' },
                )}
                name="phone"
                placeholder={i18n._(
                  'profile.phone.placeholder',
                  {},
                  { message: '+32...' },
                )}
                className="z-20"
              />
              <div className="h-[60vh]" />
            </div>

            <div className="flex items-center px-6 pt-4 -mx-6 pb-6 shadow-menu order-1 z-10 bg-white sticky bottom-0 ">
              <Button
                type="submit"
                variant="primary"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                isLoading={isSubmitting}
                size="large"
              >
                {buttonLabel}
              </Button>
              <SafeAreaBottomSpacer />
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
