// import { useNotificationChannel } from './useNotificationChannel'
import type { AppInfo } from '@capacitor/app'
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { useApi, useAuthContext, useAxios } from '@healthblocks-io/core'
import useMounted from '@healthblocks-io/core/useMounted'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/** Call this hook when the user is redirected to /oauth-callback */
export function useOAuthCallback(options: {
  authenticatorId: number
  onSuccess: () => void
  onError: (error: Error) => void
}) {
  const auth = useAuthContext()
  const axios = useAxios()
  const location = useLocation()
  const mounted = useMounted()
  useEffect(() => {
    const parameters = new URLSearchParams(location.search)
    const code = parameters.get('code')
    if (!code) return
    const state = parameters.get('state')
    try {
      // Stop checking the token, it's not for this authenticator
      if (state && JSON.parse(state).a !== options.authenticatorId) return
    } catch {
      //
    }

    // Trade code for token
    axios
      .post('/authenticator/' + options.authenticatorId + '/token', { code })
      .then(({ data }) => {
        // Delete notification channels if the user is not the same
        // if (auth.data.current !== data) deleteLocalNotificationChannel()

        // The user moved away from the page, weird...
        if (!mounted.current)
          return console.error(
            'User moved away from the page while authenticating',
          )

        // Success!
        auth.setData(data, true)
        options.onSuccess()
      })
      .catch(options.onError)
  }, [location])
}

/** Call this hook to detect if the app failed to open the /oauth-callback url */
export const useOAuthCallbackWorkaround = Capacitor.isNativePlatform()
  ? useOAuthCallbackWorkaroundNative
  : () => {}

export function useOAuthCallbackWorkaroundNative() {
  const navigate = useNavigate()
  useEffect(() => {
    let before = ''
    App.getLaunchUrl().then((url) => {
      if (url) before = url.url
    })
    const here = async () => {
      const url = await App.getLaunchUrl()
      if (
        url &&
        url.url !== before &&
        url.url.includes('/oauth-callback') &&
        !window.location.href.includes('/oauth-callback')
      ) {
        navigate('/oauth-callback' + url.url.split('/oauth-callback')[1])
      }
    }
    document.addEventListener('visibilitychange', here)
    return () => document.removeEventListener('visibilitychange', here)
  }, [])
}

export function useOAuthAuthorize(options: {
  authenticatorId: number
  redirectUri: string
}) {
  const api = useApi()
  return api && options.authenticatorId && options.redirectUri
    ? `${api}/authenticator/${options.authenticatorId}/authorize?response_type=code&redirect_uri=${options.redirectUri}`
    : ''
}

export function useCapacitorRedirectUrl() {
  const [appInfo, setAppInfo] = useState<AppInfo>()
  useEffect(() => {
    if (Capacitor.isNativePlatform()) App.getInfo().then(setAppInfo)
  }, [])
  return useMemo(() => {
    const isNative = Capacitor.isNativePlatform()

    // Impossible to know the redirect url at this point
    if (isNative && !appInfo?.id) return ''

    // Origin depends on capacitor shell
    const origin = isNative ? `${appInfo?.id}://` : window.location.origin

    // Specific route for redirecting back
    return origin + '/oauth-callback'
  }, [appInfo?.id])
}
