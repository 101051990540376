import type { RouteObject } from 'react-router-dom'

import { GetTheAppPage } from '@/pages/getTheApp/GetTheApp.page'
import { LoginPage } from '@/pages/login/Login.page'
import { OauthCallbackPage } from '@/pages/login/OauthCallback.page'
import { OnboardingPage } from '@/pages/onboarding/Onboarding.page'
import { PrivacyPage } from '@/pages/privacy/Privacy.page'
import { UpdateRequiredPage } from '@/pages/updateRequired/UpdateRequired.page'

const publicRoutes: RouteObject[] = [
  {
    path: '/privacy',
    element: <PrivacyPage />,
  },
  {
    path: '/error',
    element: <div>ErrorPage</div>,
  },
  {
    path: '/onboarding',
    element: <OnboardingPage />,
  },
  {
    path: '/update-required',
    element: <UpdateRequiredPage />,
  },
  {
    path: '/get-the-app',
    element: <GetTheAppPage />,
  },
  {
    path: '/oauth-callback',
    element: <OauthCallbackPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
]

export { publicRoutes }
