import type { ActivityStatus } from '@healthblocks-io/core'
import { General, Time } from 'untitledui-js'

type Props = {
  status: ActivityStatus
  startTime?: Date
}

export const StatusLine = ({ status }: Props) => {
  const icon =
    status === 'completed' ? (
      <div
        className={`w-8 h-8 rounded-full flex justify-center items-center bg-success-100`}
      >
        <General.Check className={'w-4 h-4 stroke-success-600'} />
      </div>
    ) : status === 'cancelled' ? (
      <div
        className={`w-8 h-8 rounded-full flex justify-center items-center bg-error-100`}
      >
        <General.X className={'w-4 h-4 stroke-error-600'} />
      </div>
    ) : status === 'expired' ? (
      <div
        className={`w-8 h-8 rounded-full flex justify-center items-center bg-warning-100`}
      >
        <Time.HourGlass03 className={'w-4 h-4 stroke-warning-600'} />
      </div>
    ) : (
      <div
        className={`w-8 h-8 rounded-full flex justify-center items-center bg-gray-50`}
      >
        <Time.Clock className={`w-4 h-4 stroke-gray-500`} />
      </div>
    )

  return (
    <div className="w-8 flex-shrink-0 flex flex-col justify-center items-center">
      <div className="w-[1px] flex-grow border-r border-dashed border-gray-200" />

      {icon}
      <div className="w-[1px] flex-grow border-r border-dashed border-gray-200" />
    </div>
  )
}
