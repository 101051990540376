import { useAuthActions } from '@healthblocks-io/core'
import { useLingui } from '@lingui/react'
import type { FormikHelpers } from 'formik'
import { Form, Formik } from 'formik'
import { useMemo } from 'react'

import { Button } from '@/components/button/Button'
import { Input } from '@/components/input/Input'

import { LoginFormSchema } from './LoginForm.schema'

type LoginFormProps = {
  onSuccess?: () => void
  onBack?: () => void
}

type FormValues = {
  email: string
  password: string
}

const LoginForm = ({ onSuccess, onBack }: LoginFormProps) => {
  const auth = useAuthActions()
  const i18n = useLingui()
  const formikInitialValues: FormValues = useMemo<FormValues>(
    () => ({
      email: '',
      password: '',
    }),
    [],
  )

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    actions.setSubmitting(true)

    try {
      await auth.signInWithEmailAndPassword(values.email, values.password)
      onSuccess?.()
    } catch (error: any) {
      actions.setErrors({ password: error.message })
    }
    actions.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={LoginFormSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form className="flex flex-col gap-4">
          <Input
            label={i18n._(
              'auth.email_address.label',
              {},
              { message: 'E-mailadres' },
            )}
            name="email"
            type="email"
            required
            placeholder={i18n._(
              'auth.email_address.placeholder',
              {},
              { message: 'naam@healthblocks.io' },
            )}
          />

          <Input
            label={i18n._('auth.password', {}, { message: 'Wachtwoord' })}
            name="password"
            type="password"
            required
            placeholder={i18n._(
              'auth.password.placeholder',
              {},
              { message: 'Typ je wachtwoord' },
            )}
          />

          <Button
            type="submit"
            variant="primary"
            disabled={isSubmitting || !isValid}
            isLoading={isSubmitting}
            size="large"
          >
            {i18n._('auth.sign_in', {}, { message: 'Aanmelden' })}
          </Button>
          {onBack ? (
            <Button variant="gray" onClick={onBack}>
              {i18n._('globals.back', {}, { message: 'Terug' })}
            </Button>
          ) : null}
        </Form>
      )}
    </Formik>
  )
}

export { LoginForm }
