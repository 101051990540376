import type { ReactNode } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

type Props = {
  children: ReactNode
}

type LayoutContextData = {
  isNavigationVisible: boolean
  setIsNavigationVisible?: (visible: boolean) => void
}

const LayoutContext = createContext<LayoutContextData>({
  isNavigationVisible: true,
  setIsNavigationVisible: undefined,
})

const LayoutProvider = ({ children }: Props) => {
  const [isNavigationVisible, setIsNavigationVisible] = useState(true)

  return (
    <LayoutContext.Provider
      value={{ isNavigationVisible, setIsNavigationVisible }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

const useLayout = () => {
  const { isNavigationVisible, setIsNavigationVisible } =
    useContext(LayoutContext)

  const hideNavigation = useCallback(() => {
    if (setIsNavigationVisible) {
      setIsNavigationVisible(false)
    }
  }, [setIsNavigationVisible])

  const showNavigation = useCallback(() => {
    if (setIsNavigationVisible) {
      setIsNavigationVisible(true)
    }
  }, [setIsNavigationVisible])

  return {
    isNavigationVisible,
    hideNavigation,
    showNavigation,
  }
}

const useHideNativeNavigation = () => {
  const { hideNavigation, showNavigation } = useLayout()

  // const isNative = Capacitor.isNativePlatform()
  // Fake this effect for dev purposes in the browser
  // const isSmall = useMediaQuery(breakpoints.toS)
  // const isMobile = isNative || isSmall

  useEffect(() => {
    // if (isMobile) {
    hideNavigation()
    // }

    return () => {
      // if (isMobile) {
      showNavigation()
      // }
    }
  }, [hideNavigation, showNavigation])
}

export { useLayout, useHideNativeNavigation, LayoutProvider }
