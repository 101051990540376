import { deepSet } from '@healthblocks-io/core/set'
import type { CareplanActivity } from '@healthblocks-io/core/types'
import addDays from 'date-fns/addDays'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'
import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import addYears from 'date-fns/addYears'
import differenceInDays from 'date-fns/differenceInDays'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import { useReducer } from 'react'

export function useActivityEditor(activity: CareplanActivity) {
  return useReducer(careplanReducer, activity, initialPatch)
}

function careplanReducer(previous: PatchState, action: any) {
  if (action.type === 'discard') {
    return initialPatch(null)
  }
  if (action.type === 'save') {
    return { ...previous, saving: true }
  }
  if (!action.id) {
    // console.warn('sure no id')
  }

  let { data } = previous
  for (const key in action) {
    if (key !== 'id' && key !== 'type') {
      data = deepSet(data, key, action[key])
    }
  }

  return { ...previous, data }
}

type PatchState = {
  saving: boolean
  data: CareplanActivity | null
}

function initialPatch(activity: PatchState['data']): PatchState {
  return {
    saving: false,
    data: activity,
  }
}

export type DayChunk = {
  key: string
  date: Date
  month?: string
  activities: CareplanActivity[]
}

export function groupByDayInterval(
  activities: CareplanActivity[],
  start: Date,
  end: Date,
) {
  const istart = startOfDay(start).valueOf()
  const iend = endOfDay(end).valueOf()
  const diff = differenceInDays(iend, istart) + 1
  //TODO Thomas: this is not efficient for big ranges (boundless)
  return range(diff)
    .map(
      (index) =>
        ({
          key: format(addDays(start, index), 'yyyy-MM-dd'),
          date: addDays(istart, index),
          activities: [],
        }) as DayChunk,
    )
    .flatMap(insertMonth)
}

export function groupByDay(activities: CareplanActivity[]) {
  const days: DayChunk[] = []
  let last: DayChunk = { key: '', date: new Date(), activities: [] }
  for (const a of activities) {
    const date = new Date(a.plannedAt)
    const key = format(date, 'yyyy-MM-dd')
    if (last.key === key) {
      last.activities.push(a)
    } else {
      // Fill up with empty days
      if (last.key) {
        let x = 1
        let fillDate = addDays(last.date, x)
        let fill = format(fillDate, 'yyyy-MM-dd')
        while (fill < key) {
          days.push({ key: fill, date: fillDate, activities: [] })

          x++
          fillDate = addDays(last.date, x)
          fill = format(fillDate, 'yyyy-MM-dd')
        }
      }
      last = { key, date, activities: [a] }
      days.push(last)
    }
  }
  return days.flatMap(insertMonth)
}

function insertMonth(d: DayChunk, index: number, a: DayChunk[]): DayChunk[] {
  if (d.date.getDate() === 1) {
    const year = new Date().getFullYear()
    return [
      {
        month: format(
          d.date,
          year === d.date.getFullYear() ? 'LLLL' : 'LLLL yyyy',
        ),
        key: d.key + '-mon',
        activities: [],
        date: d.date,
      },
      d,
    ]
  }

  return [d]
}

export function withRepetitions(
  activities: CareplanActivity[],
  until: Date,
  now?: Date,
) {
  return activities
    .flatMap((a) => withRepetitionsOne(a, until, now))
    .sort(chronological)
}

export function chronological(
  a: { plannedAt?: string; id?: number },
  b: { plannedAt?: string; id?: number },
) {
  return a.plannedAt?.localeCompare(b.plannedAt || '') || a.id! - b.id!
}

export function withRepetitionsOne(
  a: CareplanActivity,
  until: Date,
  now?: Date,
) {
  const document: any = a?.meta

  if (
    !a.plannedAt ||
    !document ||
    Object.keys(document).length === 0 ||
    !document.repeatPeriod ||
    !document.repeatPeriodUnit
  ) {
    return [a]
  }
  const exdate = document.exdate || []
  const out = exdate.includes(a.plannedAt.slice(0, 10)) ? [] : [a]
  const duration =
    new Date(document.end_at!).valueOf() - new Date(a.plannedAt).valueOf()

  // Limits
  // TODO: is count total count incl/excl original?
  let count = document.repeatCount || Infinity

  // Fill in between time with due assessments
  let cloneStart = addTime(
    new Date(a.plannedAt),
    document.repeatPeriod,
    document.repeatPeriodUnit,
  )
  while (cloneStart < until && count > 1) {
    const added = {
      ...clone(a),
      parent_id: a.id,
      plannedAt: cloneStart.toJSON(),
    }
    // if (now && a.reminderAt) {
    //   added.reminderAt = getNextReminder(added, now)
    // }
    if (added.doc.end_at) {
      added.doc.end_at = new Date(cloneStart.valueOf() + duration).toJSON()
    }
    if (!exdate.includes(added.plannedAt.slice(0, 10))) {
      out.push(added)
    }

    // Update limits
    count--

    // Prep next clone
    cloneStart = addTime(
      cloneStart,
      document.repeatPeriod,
      document.repeatPeriodUnit,
    )
  }

  return out
}

function clone(a: CareplanActivity) {
  return JSON.parse(JSON.stringify(a))
}

function addTime(date: Date, value: number, unit: string) {
  if (unit.startsWith('min')) {
    return addMinutes(date, value)
  }
  if (unit.startsWith('h')) {
    return addHours(date, value)
  }
  if (unit.startsWith('d')) {
    return agnostic(addDays, date, value)
  }
  if (unit.startsWith('w')) {
    return agnostic(addWeeks, date, value)
  }
  if (unit.startsWith('mo')) {
    return agnostic(addMonths, date, value)
  }
  if (unit.startsWith('md')) {
    return agnostic(addMonths, date, value)
  }
  if (unit !== 'a') {
    console.warn('unexpected addTime unit', unit)
  }
  return addYears(date, value)
}

function agnostic(f: (a: Date, b: number) => Date, date: Date, value: number) {
  const original = date.getTimezoneOffset()
  const output = f(date, value)
  const final = output.getTimezoneOffset()
  const diff = original - final
  return diff ? addMinutes(output, diff) : output
}

function range(count: number) {
  return new Array(count).fill(1).map((_, index) => index)
}

export function getNextReminder(activity: CareplanActivity, at: Date) {
  if (!Array.isArray(activity.doc.reminders)) {
    return null
  }
  const planned = activity.plannedAt
  if (!planned) {
    return null
  }

  // How far do reminders go back in time?
  // This allows to minimize how far we have to calculate repetitions
  const backInTime = Math.max(
    ...activity.doc.reminders.map(
      (r) =>
        new Date(planned).valueOf() -
        addTime(new Date(planned), r.offset, r.offsetUnit).valueOf(),
    ),
  )

  // Map out all reminders
  const x = withRepetitionsOne(
    activity,
    new Date(at.valueOf() + backInTime + 36e5 * 24),
  )
  const all = x.flatMap((a) =>
    a.doc
      .reminders!.map((r) =>
        addTime(new Date(a.plannedAt!), r.offset, r.offsetUnit).valueOf(),
      )
      .filter((r) => r > at.valueOf()),
  )

  if (all.length === 0) {
    return null
  }

  const min = Math.min(...all)
  return new Date(min)
}

// Patient calendar

export function renderDays(
  activities: CareplanActivity[],
  start: Date,
  end?: Date,
) {
  if (!end) end = addDays(start, 30)
  const now = Date.now()
  activities = activities.map((a) =>
    a.doc.title
      ? a
      : // Set missing activity title
        deepSet(
          a,
          'doc.title',
          a.questionnaire_response?.questionnaire?.title ||
            a.questionnaire?.title ||
            '',
        ),
  )

  const groups = groupByDay(withRepetitions(activities, end))
  const daysRendered = groupByDayInterval([], start, end)

  daysRendered.forEach((day) => {
    const group = groups.find((g) => g.key === day.key)?.activities
    if (group) {
      day.activities = group.map((a) => {
        // Mark past appointments as completed
        if (a.kind === 'Appointment' && Date.parse(a.plannedAt) < now)
          return { ...a, status: 'completed' }

        // withRepetitions may have planned activities in the past that are due
        // so let's mark them as due (not-started)
        if (
          a.status === 'scheduled' &&
          endOfDay(smartEnd(a)).valueOf() < Date.now()
        )
          return { ...a, status: 'not-started' }

        return a
      })
    }
  })

  return daysRendered
}

function smartEnd(a: CareplanActivity) {
  return new Date(
    !a.doc.end_at || a.doc.end_at < a.plannedAt ? a.plannedAt : a.doc.end_at,
  )
}
