import type { CareplanActivity } from '@healthblocks-io/core'
import { useAxios, useEvent, useSubjectId } from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { FullScreenModal } from '@/components/modal/FullScreenModal'
import { useQuestionnaire } from '@/components/questionnaire/activity-modal-state'
import { useToast } from '@/hooks/useToast'
import type {
  Article,
  Audio,
  Collection,
  Exercise,
  Video,
} from '@/utils/content'
import { useSearchParameterActivity } from '@/utils/modal'

import { ArticleIntro } from '../content-detail/intros/ArticleIntro'
import { AudioIntro } from '../content-detail/intros/AudioIntro'
import { CollectionIntro } from '../content-detail/intros/CollectionIntro'
import { ExerciseIntro } from '../content-detail/intros/ExerciseIntro'
import { VideoIntro } from '../content-detail/intros/VideoIntro'
import { MessageModalBody } from './Message.modal'
import { QuestionnaireActivity } from './types/QuestionnaireActivity.modal'

/**
 * This Modal component opens/closes based on URL search params
 *
 * `?activityId=123`
 *
 * `?type=article&contentSlug=recabc`
 *
 * `?type=Questionnaire&contentSlug=recabc`
 */
export const ActivityModal = ({ onChange }: { onChange?: () => void }) => {
  const axios = useAxios()
  const subjectId = useSubjectId()
  const {
    activity,
    appointmentId,
    content,
    contentSlug,
    type,
    closing,
    close,
  } = useSearchParameterActivity()
  const questionnaire = useQuestionnaire(
    activity,
    type === 'Questionnaire' && contentSlug,
  )
  const navigate = useNavigate()
  const { showSuccessToast, showErrorToast } = useToast()

  const complete = useEvent(async () => {
    try {
      if (activity) {
        // mark as complete
        await axios.patch(`/careplan-activity/${activity.id}`, {
          status: 'completed',
        })
        if (type !== 'video') {
          return showSuccessToast({
            message: i18n._('activity.completed', undefined, {
              message: 'Klaar!',
            }),
          })
        }
      } else {
        await axios.post<CareplanActivity>('/careplan-activity', {
          type,
          subjectId,
          contentSlug,
          display: content?.title,
          status: 'completed',
        })

        if (type !== 'video') {
          return showSuccessToast({
            message: i18n._('activity.completed', undefined, {
              message: 'Klaar!',
            }),
          })
        }
      }
    } catch (error: any) {
      showErrorToast({ message: error.message })
    } finally {
      onChange?.()
    }
  })

  const open =
    // @ts-expect-error response.status should be in core sdk
    activity?.questionnaireResponse?.status !== 'completed' ||
    activity?.status === 'scheduled'

  useEffect(() => {
    // Update so user does not click again
    if (questionnaire && !open) onChange?.()
  }, [open])

  // Modal for the actual activity
  let modal: ReactNode = null

  if (activity) {
    switch (activity?.type) {
      case 'Questionnaire': {
        if (questionnaire && open) {
          modal = (
            <QuestionnaireActivity
              activity={activity}
              questionnaire={questionnaire}
              content={content}
              onChange={onChange}
              onClose={close}
            />
          )
        }
        break
      }
      case 'Message': {
        modal = (
          <MessageModalBody
            activity={activity}
            onChange={onChange}
            onClose={close}
          />
        )
        break
      }
      case 'AppointmentRequest': {
        try {
          if (activity.status !== 'completed') {
            axios.patch('/careplan-activity/' + activity.id, {
              status: 'completed',
            })
            const prefill = encodeURIComponent(
              `Ik wil graag een afspraak boeken.`,
            )
            navigate(`/chat?prefill=${prefill}`)
          }
        } catch (error) {
          console.log(error)
        }
        break
      }
    }
  }

  if (content) {
    switch (type) {
      case 'article': {
        modal = (
          <ArticleIntro
            activity={activity}
            content={content as Article}
            onClose={close}
            onComplete={complete}
          />
        )
        break
      }
      case 'audio': {
        modal = (
          <AudioIntro
            activity={activity}
            content={content as Audio}
            onClose={close}
            onComplete={complete}
          />
        )
        break
      }
      case 'collection': {
        modal = (
          <CollectionIntro
            activity={activity}
            content={content as Collection}
            onClose={close}
            onComplete={complete}
          />
        )
        break
      }
      case 'exercise': {
        modal = (
          <ExerciseIntro
            activity={activity}
            content={content as Exercise}
            onClose={close}
            onComplete={complete}
          />
        )
        break
      }
      case 'video': {
        modal = (
          <VideoIntro
            activity={activity}
            content={content as Video}
            onClose={close}
            onComplete={complete}
          />
        )
        break
      }
      case 'Questionnaire': {
        modal =
          modal ||
          (questionnaire && open && (
            <QuestionnaireActivity
              content={content}
              questionnaire={questionnaire}
              onChange={onChange}
              onClose={close}
            />
          ))
        break
      }
    }
  }

  return (
    <FullScreenModal
      open={!!modal && !closing}
      onBlur={() => {
        onChange?.()
        close()
      }}
      // Ignore initial focus because it makes the page jump to the bottom
      initialFocus={{ current: { focus() {} } as HTMLElement }}
    >
      {modal}
    </FullScreenModal>
  )
}
