import { useLingui } from '@lingui/react'
import { General } from 'untitledui-js'

import { useEnableNotifications } from '@/utils/push-notification'

import { Button } from '../button/Button'

export default function NotificationModalBody() {
  const i18n = useLingui()
  const [_, enable, disable] = useEnableNotifications()
  return (
    <div className="p-6 flex flex-col gap-4">
      <div className="flex justify-center items-center">
        <div className="w-10 h-10 flex justify-center items-center rounded-full bg-primary-50">
          <General.HelpCircle className="w-5 h-5 stroke-primary-400" />
        </div>
      </div>

      <h2 className="font-title2 text-center">
        {i18n._('notification_modal.title', undefined, {
          message: 'Meldingen aanzetten?',
        })}
      </h2>
      <p className="font-body text-center">
        {i18n._('notification_modal.body', undefined, {
          message:
            'Activeer je meldingen zodat we je op de hoogte kunnen houden als er antwoord is op je vraag.',
        })}
      </p>
      <Button size="medium" onClick={enable}>
        {i18n._('notification_modal.enable', undefined, {
          message: 'Activeer meldingen',
        })}
      </Button>
      <Button variant="tertiary" onClick={disable}>
        {i18n._('notification_modal.disable', undefined, {
          message: 'Niet nu',
        })}
      </Button>
    </div>
  )
}
