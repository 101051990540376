import { AppLauncher } from '@capacitor/app-launcher'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'

export async function openUrl(url: string, windowName?: string) {
  try {
    await (Capacitor.isNativePlatform()
      ? AppLauncher.openUrl({ url })
      : Browser.open({ url, windowName }))
  } catch (error) {
    console.log('Error opening URL:', error)
    window.location.href = url
  }
}
