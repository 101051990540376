import { Capacitor } from '@capacitor/core'
import { i18n } from '@lingui/core'

import { Button } from '@/components/button/Button'
import { NativeStoreButton } from '@/components/native-store-button/NativeStoreButton'
import { LoginLayout } from '@/layout/login/LoginLayout'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'

const UpdateRequiredPage = () => {
  return (
    <LoginLayout image={'img/background.jpg'}>
      <div className="flex flex-col gap-4 pb-6">
        <p className="font-title1 text-brand font-bold">
          {i18n._('update_required.title', undefined, {
            message: 'Welkom bij Healthblocks!',
          })}
        </p>
        <div className="flex flex-col gap-2">
          <p className="font-body text-text-primary font-semibold">
            {i18n._('update_required.subtitle', undefined, {
              message:
                'Je hebt een verourderde versie van de app die niet meer ondersteund wordt.',
            })}
          </p>
          <p className="font-body text-text-primary">
            {i18n._(
              'update_required.body',
              {
                store:
                  Capacitor.getPlatform() === 'android'
                    ? 'Google Play Store'
                    : 'Apple App Store',
              },
              {
                message: 'Gelieve up te daten via de {store}.',
              },
            )}
          </p>
        </div>

        <div className="flex justify-center items-center my-6">
          {Capacitor.getPlatform() === 'android' ? (
            <NativeStoreButton
              type="playStore"
              url="" //TODO: fill in google play store url
            />
          ) : Capacitor.getPlatform() === 'ios' ? (
            <NativeStoreButton
              type="appStore"
              url="" //TODO: fill in apple app store url
            />
          ) : (
            <Button size="small" variant="primary">
              {i18n._('update_required.refresh', undefined, {
                message: 'Refresh the page',
              })}
            </Button>
          )}
        </div>
        <SafeAreaBottomSpacer />
      </div>
    </LoginLayout>
  )
}

export { UpdateRequiredPage }
