import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { i18n } from '@lingui/core'
import { AnimatePresence, motion } from 'framer-motion'
import type { ChangeEvent, KeyboardEvent } from 'react'
import { General } from 'untitledui-js'

import { useLayout } from '@/layout/providers/LayoutProvider'

type Props = {
  query: string
  placeholder: string
  onSearch: (query: string) => void
}

export const SearchBar = ({ query, placeholder, onSearch }: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value)
  }

  const handleCancel = () => {
    onSearch('')
  }

  const { hideNavigation, showNavigation } = useLayout()

  return (
    <AnimatePresence>
      <div className="relative flex items-center w-full h-12">
        <div className="absolute left-4 w-4 h-4">
          <General.SearchLG className="w-full h-full stroke-gray-500" />
        </div>
        <input
          type="text"
          className="w-full h-full pr-4 pl-10 font-callout text-gray-900 rounded-xl bg-white border border-solid border-gray-100 focus:outline-none transition-all duration-500"
          placeholder={placeholder}
          value={query}
          onChange={handleChange}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              if (Capacitor.getPlatform() === 'ios') {
                Keyboard.hide()
              }

              if (Capacitor.isNativePlatform()) {
                showNavigation()
              }
            }
          }}
          onFocus={() => {
            Capacitor.isNativePlatform() && hideNavigation()
          }}
          onBlur={() => {
            Capacitor.isNativePlatform() && showNavigation()
          }}
        />

        {query ? (
          <motion.button
            key={'search-cancel'}
            initial={{ opacity: 0, x: 30 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 30 }}
            className={`h-full px-4 text-sm font-semibold text-gray-900 bg-transparent rounded-md focus:outline-none`}
            onClick={handleCancel}
          >
            {i18n._('search.cancel', undefined, {
              message: 'Annuleer',
            })}
          </motion.button>
        ) : null}
      </div>
    </AnimatePresence>
  )
}
