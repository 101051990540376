import { i18n } from '@lingui/core'
import { useCallback } from 'react'
import { Users } from 'untitledui-js'

type Props = {
  answer?: any
  onSelect: (value: number) => void
  isPreview?: boolean
}

const Smiley = ({ answer, onSelect, isPreview }: Props) => {
  const ratings = [1, 2, 3, 4, 5]

  const renderIcon = useCallback((value: number, isActive: boolean) => {
    const classNames = `w-6 h-6 transition-colors duration-200 ${
      isActive
        ? 'stroke-base-white'
        : isPreview
        ? 'stroke-primary-600'
        : 'stroke-gray-500'
    }`

    switch (value) {
      case 1: {
        return <Users.FaceSad className={classNames} />
      }

      case 2: {
        return <Users.FaceFrown className={classNames} />
      }

      case 3: {
        return <Users.FaceNeutral className={classNames} />
      }

      case 4: {
        return <Users.FaceSmile className={classNames} />
      }

      case 5: {
        return <Users.FaceHappy className={classNames} />
      }
      default: {
        return null
      }
    }
  }, [])

  return (
    <div className="w-full flex flex-col gap-3">
      <div className="w-full flex justify-between items-center">
        {ratings.map((rating) => (
          <div
            key={rating}
            id={`smiley_${rating.toString()}`}
            className={`flex w-14 p-4 rounded-full justify-center items-center transition-colors duration-200 cursor-pointer ${
              rating === answer?.value
                ? 'bg-primary-600'
                : isPreview
                ? 'bg-primary-50 hover:bg-primary-100'
                : 'bg-gray-50 hover:bg-primary-600'
            } `}
            onClick={() => onSelect(rating)}
          >
            {renderIcon(rating, rating === answer?.value)}
          </div>
        ))}
      </div>

      <div className="flex justify-between px-[10px]">
        <p className="font-footNote text-primary font-semibold">
          {i18n._('smiley.bad')}
        </p>
        <p className="font-footNote text-primary font-semibold">
          {i18n._('smiley.good')}
        </p>
      </div>
    </div>
  )
}

export { Smiley }
