import { Capacitor } from '@capacitor/core'
import { Style } from '@capacitor/status-bar'
import { useLingui } from '@lingui/react'
import { Instabug } from 'instabug-cordova'
import { useState } from 'react'
import { Communication, General, Security, Users } from 'untitledui-js'

import { PageHeader } from '@/components/header/PageHeader'
import { MenuLink } from '@/components/menu-link/MenuLink'
import { Container } from '@/layout/Container'
import { useAppVersion } from '@/utils/app-version'
import { useStatusBarStyle } from '@/utils/status-bar'

import { SignOutModal } from './modal/SignOut.modal'

export const ProfilePage = () => {
  const i18n = useLingui()
  useStatusBarStyle(Style.Light)

  const appVersion = useAppVersion()

  const [showSignOutModal, setShowSignOutModal] = useState(false)

  return (
    <div className="h-full flex flex-col bg-base-white">
      <PageHeader
        title={i18n._('profile.title', undefined, { message: 'Profile' })}
        actions={
          <General.Logout01
            className={
              'sticky top-10 rounded-full bg-primary-50 stroke-text-primary w-5 h-5 m-2 cursor-pointer'
            }
            onClick={() => {
              setShowSignOutModal(true)
            }}
          />
        }
      />
      <Container className="h-full flex-grow overflow-auto">
        <div className="flex flex-col gap-4 py-6 h-full">
          <div className="flex flex-col gap-2">
            <MenuLink
              label={i18n._('profile.personal_info.title', undefined, {
                message: 'Personal Information',
              })}
              to={'/profile/edit'}
              icon={<Users.UserCircle />}
            />

            <MenuLink
              label={i18n._('profile.settings.title', undefined, {
                message: 'Settings',
              })}
              to={'/profile/settings'}
              icon={<General.Settings02 />}
            />

            {Capacitor.isNativePlatform() ? (
              <MenuLink
                label={i18n._('profile.report.title', undefined, {
                  message: 'Probleem melden',
                })}
                onClick={() => {
                  Instabug.show()
                }}
                icon={<Communication.Send01 />}
              />
            ) : null}

            <MenuLink
              label={i18n._('profile.settings.debug', undefined, {
                message: 'Debug',
              })}
              to={'/profile/debug'}
              icon={<General.Settings02 />}
            />

            <MenuLink
              label={i18n._('profile.privacy.title', undefined, {
                message: 'Privacy',
              })}
              external={i18n._('privacy_policy_link')}
              icon={<Security.Lock03 />}
            />
          </div>

          <div className="flex flex-col gap-2">
            <p className="font-caption2 text-center text-text-secondary">{`App version: ${appVersion}`}</p>
          </div>
        </div>
      </Container>

      <SignOutModal
        open={showSignOutModal}
        onClose={() => {
          setShowSignOutModal(false)
        }}
      />
    </div>
  )
}
