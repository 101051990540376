import type { ReactNode } from 'react'
import { createContext, useContext, useState } from 'react'

// Define the context
type UnreadMessageContextType = {
  unreadMessages: number
  clearUnreadMessages: () => void
}

const UnreadMessageContext = createContext<
  UnreadMessageContextType | undefined
>(undefined)

// Custom hook to access the context
export const useUnreadMessages = () => {
  const context = useContext(UnreadMessageContext)
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    )
  }
  return context
}

type Props = {
  children: ReactNode
}

// Provider component to wrap your app with
export const UnreadMessageProvider = ({ children }: Props) => {
  // const unreadMessages = useHealthblocksSWR(
  //   '/integration/chatwoot/endpoint/unread_messages',
  // )

  const [unreadCount, setUnreadCount] = useState(0)

  // useEffect(() => {
  //   if (unreadMessages.data) {
  //     setUnreadCount(unreadMessages.data.unreadCount)
  //   }
  // }, [unreadMessages])

  // useEffect(() => {
  //   unreadMessages.mutate()
  // }, [window.location])

  const clearUnreadMessages = () => {
    setTimeout(() => {
      setUnreadCount(0)
    }, 500)
  }

  return (
    <UnreadMessageContext.Provider
      value={{ unreadMessages: unreadCount, clearUnreadMessages }}
    >
      {children}
    </UnreadMessageContext.Provider>
  )
}
