export const theme = {
  colors: {
    primary: {
      900: '#003850',
      800: '#004a69',
      700: '#005f88',
      600: '#007aae',
      500: '#0086bf',
      400: '#339ecc',
      300: '#54aed4',
      200: '#8ac7e2',
      100: '#b0d9eb',
      50: '#e6f3f9',
    },
    gray: {
      900: '#2b2f38',
      800: '#383e49',
      700: '#48505e',
      600: '#5d6679',
      500: '#667085',
      400: '#858d9d',
      300: '#989fad',
      200: '#b9bdc7',
      100: '#d0d3d9',
      50: '#f0f1f3',
    },
    base: {
      black: '#000000',
      white: '#ffffff',
    },
  },
}
