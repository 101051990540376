import {
  humanDate,
  type IQuestionnaireQuestion,
  type QuestionAnswerInput,
} from '@healthblocks-io/core'
import { type ChangeEvent, useEffect } from 'react'

import { splitText } from '@/utils/questionnaire'

import { MultiSelect } from '../multi-select/MultiSelect'
import { QuickReply } from '../quickreply/QuickReply'
import { Slider } from '../slider/Slider'
import { Smiley } from '../smiley/Smiley'

type Props = {
  question: IQuestionnaireQuestion
  to?: string
  answer?: QuestionAnswerInput
  onAnswer: (a: QuestionAnswerInput) => void
  isPreview?: boolean
}

const Question = ({ question, to, answer, onAnswer, isPreview }: Props) => {
  const text = to ? question.t?.[to]?.text : question.text
  const [title, description] = splitText(text)

  useEffect(() => {
    if (question.type === 'Display' && !answer) {
      onAnswer({
        linkId: question.linkId || '_' + question.id,
        value: 'seen',
        text: 'seen',
      })
    }
  }, [])

  return (
    <div className="w-full flex flex-col gap-4">
      {title || description ? (
        <div className="flex flex-col gap-2">
          {title ? (
            <div className="flex gap-2">
              {isPreview ? (
                <p className="font-title2 font-bold text-text-primary pb-8">
                  {title}
                </p>
              ) : (
                <p className="font-title3 text-text-primary">{title}</p>
              )}
            </div>
          ) : null}
          {description && !isPreview ? (
            description.length > 100 ? (
              <p className="font-body text-brand">{description}</p>
            ) : (
              <p className="font-title2 text-brand">{description}</p>
            )
          ) : null}
        </div>
      ) : null}

      {/* Render question content */}
      {/* Text input */}
      {question.type === 'Text' ? (
        <input
          type="text"
          placeholder={question.placeholder}
          maxLength={question.maxlength}
          minLength={question.minlength}
          value={answer?.value || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onAnswer({
              linkId: question.linkId || '_' + question.id,
              value: event.target.value,
              text: event.target.value,
            })
          }
          className="px-[14px] py-3 rounded-sm border border-gray-100 shadow-sm"
        />
      ) : null}
      {/* LongText input */}
      {question.type === 'LongText' ? (
        <textarea
          placeholder={question.placeholder}
          maxLength={question.maxlength}
          minLength={question.minlength}
          value={answer?.value || ''}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            onAnswer({
              linkId: question.linkId || '_' + question.id,
              value: event.target.value,
              text: event.target.value,
            })
          }
          className="px-[14px] py-3 rounded-sm border border-gray-100 shadow-sm resize-none min-h-20 "
        />
      ) : null}
      {/* Number input */}
      {question.type === 'Number' ? (
        <input
          placeholder={question.placeholder}
          type="number"
          min={question.min}
          max={question.max}
          value={answer?.value || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onAnswer({
              linkId: question.linkId || '_' + question.id,
              value: parseFloat(event.target.value),
              text: event.target.value,
            })
          }
          className="px-[14px] py-3 rounded-sm border border-gray-100 shadow-sm"
        />
      ) : null}
      {/* Date input */}
      {question.type === 'Date' || question.type === 'BirthDate' ? (
        <input
          placeholder="YYYY-MM-DD"
          type="date"
          min={question.min}
          max={question.max}
          value={answer?.value || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onAnswer({
              linkId: question.linkId || '_' + question.id,
              value: event.target.value,
              text: Date.parse(event.target.value)
                ? humanDate(new Date(event.target.value))
                : event.target.value,
            })
          }
          className="px-[14px] py-3 rounded-sm border border-gray-100 shadow-sm"
        />
      ) : null}
      {/* Time input */}
      {question.type === 'Time' ? (
        <input
          placeholder="13:00"
          type="time"
          value={answer?.value || ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onAnswer({
              linkId: question.linkId || '_' + question.id,
              value: event.target.value,
              text: Date.parse(event.target.value)
                ? humanDate(new Date(event.target.value))
                : event.target.value,
            })
          }
          className="px-[14px] py-3 rounded-sm border border-gray-100 shadow-sm"
        />
      ) : null}
      {/* Rating input */}
      {question.type === 'Rating' ? (
        <Slider
          min={question.min}
          max={question.max}
          step={question.step}
          minLabel={question.minLabel}
          maxLabel={question.maxLabel}
          initialValue={answer?.value}
          onChange={(value: number) => {
            onAnswer({
              linkId: question.linkId || '_' + question.id,
              value: value,
              text: value.toString(),
            })
          }}
        />
      ) : null}
      {/* Single select input */}
      {question.type === 'QuickReplies' && question.answerOption ? (
        <QuickReply
          quickReplies={question.answerOption}
          answer={answer}
          onSelect={(reply) => {
            onAnswer({
              linkId: question.linkId || '_' + question.id,
              value: reply.value,
              text: reply.value?.toString() || '',
            })
          }}
        />
      ) : null}
      {/* Multi select input */}
      {question.type === 'MultiSelect' && question.answerOption ? (
        <MultiSelect
          quickReplies={question.answerOption}
          answer={answer}
          onSelect={(reply) => {
            const editedAnswer: QuestionAnswerInput = answer?.value.includes(
              reply.value,
            )
              ? {
                  linkId: question.linkId || '_' + question.id,
                  value: answer?.value.filter(
                    (value: string) => value !== reply.value,
                  ),
                  text: answer?.text || '',
                }
              : {
                  linkId: question.linkId || '_' + question.id,
                  value: answer
                    ? [...answer.value, reply.value]
                    : [reply.value],
                  text: answer?.text || '',
                }
            onAnswer(editedAnswer)
          }}
        />
      ) : null}
      {/* Smiley input */}
      {/* @ts-expect-error Should be added by extending interface */}
      {question.type === 'Smiley' ? (
        <Smiley
          answer={answer}
          isPreview={isPreview}
          onSelect={(reply) => {
            onAnswer({
              linkId: question.linkId || '_' + question.id,
              value: reply,
              text: reply.toString(),
            })
          }}
        />
      ) : null}
    </div>
  )
}

export { Question }
