import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const NavigationContainer = styled.nav`
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  box-shadow: 0px -1px 140px 0px rgba(21, 33, 48, 0.15);
`

const NavigationItem = styled(NavLink)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;

  user-select: none;
  overflow: hidden;
  z-index: 1;
  padding: 16px 4px max(var(--safe-area-inset-bottom), 16px);
  transition: opacity 0.2s ease-in-out;
  outline: none;

  &:active,
  &:focus {
    background-color: transparent;
    outline: none;
  }
  &:focus-visible {
    text-decoration: underline;
  }
`

const NavigationIcon = styled.div<{ $active: boolean }>`
  & > p,
  & > svg,
  & > div > svg {
    opacity: ${({ $active: $active }) => ($active ? 1 : 0.5)};
    transition: opacity 0.2s ease-in-out;
  }

  & > svg {
    width: ${({ theme }) => theme.layout.spacingPx.space3};
    height: ${({ theme }) => theme.layout.spacingPx.space3};
  }
`

export { NavigationContainer, NavigationItem, NavigationIcon }
