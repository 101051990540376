import { Capacitor } from '@capacitor/core'
import type { MouseEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { General, MediaDevices } from 'untitledui-js'

import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import type { Audio } from '@/utils/content'

type Props = {
  content: Audio
  start: boolean
  onStart: () => void
  onClose: () => void
}

export const AudioHeader = ({ content, start, onStart, onClose }: Props) => {
  return (
    <div className="relative flex">
      {Capacitor.isNativePlatform() && (
        <div className="absolute inset-0 z-20 h-20 opacity-50 bg-gradient-to-b from-white to-transparent pointer-events-none"></div>
      )}
      <div
        className="flex flex-col absolute top-0 right-0 p-4 z-30 cursor-pointer"
        onClick={onClose}
      >
        <SafeAreaTopSpacer />
        <div className="p-[0.6rem] bg-gray-50 rounded-full">
          <General.X className="w-7 h-7 stroke-gray-500" />
        </div>
      </div>
      <div className="flex flex-grow relative overflow-hidden h-[100vw] max-h-[calc(100vh-300px)]">
        {content.image?.thumbnail ? (
          <img
            src={content.image?.thumbnail}
            alt={content.title!}
            className="w-full h-full object-cover bg-gradient-to-bl from-secondary-aqua-500 to-secondary-aqua-700"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-bl from-secondary-aqua-500 to-secondary-aqua-700" />
        )}
        {start ? null : (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center z-20 w-20 h-20 rounded-md overflow-hidden bg-base-black bg-opacity-50 cursor-pointer">
            <div className="absolute w-full h-full bg-base-black blur-2xl" />
            <MediaDevices.Play
              className="w-6 h-6 stroke-white z-10"
              onClick={onStart}
            />
          </div>
        )}
      </div>

      {start && content?.audio?.url ? (
        <div className="absolute w-full bottom-0 p-2">
          <AudioPlayer url={content.audio.url} />
        </div>
      ) : null}
    </div>
  )
}

type PlayerProps = {
  url: string
  onEnd?: () => void
}

const AudioPlayer = ({ url, onEnd }: PlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(true)
  const [currentTime, setCurrentTime] = useState(0)
  const [totalTime, setTotalTime] = useState(1)

  const percentage = (currentTime / totalTime) * 100

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef?.current?.pause()
    } else {
      audioRef?.current?.play()
    }
    setIsPlaying(!isPlaying)
  }

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef?.current?.currentTime || 0)
  }

  const handleEnded = () => {
    // Audio has finished playing, reset play state
    setIsPlaying(false)
    setCurrentTime(0)
  }

  const handleProgressBarClick = (event: MouseEvent<HTMLDivElement>) => {
    const progressBar = event.target as HTMLDivElement

    if (progressBar) {
      const clickPositionX =
        event.clientX - progressBar.getBoundingClientRect().left
      const progressBarWidth = progressBar.clientWidth
      const seekTime = (clickPositionX / progressBarWidth) * totalTime

      if (audioRef.current) {
        audioRef.current.currentTime = seekTime
        setCurrentTime(seekTime)
      }
    }
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  useEffect(() => {
    const getDuration = () => {
      setTotalTime(audioRef?.current?.duration || 1)
    }

    audioRef?.current?.addEventListener('loadedmetadata', getDuration)
    audioRef?.current?.addEventListener('ended', handleEnded)

    return () => {
      audioRef?.current?.removeEventListener('loadedmetadata', getDuration)
      audioRef?.current?.removeEventListener('ended', handleEnded)
    }
  }, [])

  return (
    <div className="w-full bg-base-black bg-opacity-80 rounded-lg">
      <audio
        ref={audioRef}
        src={url}
        onTimeUpdate={handleTimeUpdate}
        autoPlay
        playsInline
      />
      <div className="flex items-center gap-2 h-full">
        <div className="h-full flex items-center p-4" onClick={togglePlayPause}>
          {isPlaying ? (
            <MediaDevices.PauseSquare className="w-6 h-6 stroke-white z-10" />
          ) : (
            <MediaDevices.Play className="w-6 h-6 stroke-white z-10" />
          )}
        </div>

        <div className="flex-grow h-2 pr-6 justify-start items-center gap-3 inline-flex">
          <p className="font-caption1 font-semibold text-base-white">
            {formatTime(currentTime)}
          </p>
          <div
            className="w-full h-2 bg-primary-100 rounded-sm overflow-hidden"
            onClick={handleProgressBarClick}
          >
            <div
              className={`flex h-2 items-center bg-primary-500 rounded-sm transition:width duration-500`}
              style={{ width: percentage + '%' }}
            />
          </div>
          <p className="font-caption1 font-semibold text-base-white">
            {formatTime(totalTime)}
          </p>
        </div>
      </div>
    </div>
  )
}
