import { i18n } from '@lingui/core'
import { useCallback, useState } from 'react'
import { Arrow } from 'untitledui-js'

import { Card } from '@/components/Card'

type Props = {
  onReload: () => Promise<any>
}

export const EmptyCard = ({ onReload }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleReload = useCallback(async () => {
    setIsLoading(true)
    await onReload()
    setTimeout(() => {
      setIsLoading(false)
    }, 400)
  }, [onReload])

  return (
    <Card className="p-6 flex-col gap-4 bg-primary-50 border border-primary-200">
      <div className="flex justify-center items-center relative">
        <img src="/svg/empty-nba.svg" alt="empty" />

        <div
          onClick={handleReload}
          className="absolute top-0 right-0 flex justify-center items-center cursor-pointer"
        >
          <Arrow.RefreshCW05
            className={`w-5 h-5 stroke-text-secondary ${
              isLoading ? 'animate-spin' : null
            }`}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <p className="font-footNote text-text-primary text-center">
          {i18n._('activity.empty', undefined, {
            message:
              'Er zijn momenteel geen nieuwe acties die je kan ondernemen.',
          })}
        </p>
      </div>
    </Card>
  )
}
