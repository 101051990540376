import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { useNavigate } from 'react-router'
import { General } from 'untitledui-js'

import { Text } from '@/components/typography/Typography'
import { useHideNativeNavigation } from '@/layout/providers/LayoutProvider'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import { openUrl } from '@/utils/external-resources'

import { PrivacyForm } from './form/PrivacyForm'

const PrivacyPage = () => {
  const navigate = useNavigate()
  useHideNativeNavigation()

  const onNext = () => {
    navigate('/')
  }

  const openPrivacyPolicy = () => {
    openUrl(i18n._('privacy_policy_link', undefined, { message: '' }), '_blank')
  }

  return (
    <div className="flex flex-col w-full h-full pt-safe bg-base-white z-10">
      <div className="flex flex-col flex-grow scroll w-full p-6 gap-4">
        <div className="flex flex-col flex-grow gap-4">
          <SafeAreaTopSpacer />
          <Text typography="title1" className="text-brand font-bold">
            <Trans id="privacy.title">Gebruik van je persoonlijke data</Trans>
          </Text>

          <Text typography="body" className="text-text-secondary">
            <Trans id="privacy.body">
              Healthblocks gebruikt je persoonlijke data om je te kunnen
              contacteren in geval van een besmetting.{'\n\n'}Je data wordt
              enkel gebruikt voor contact tracing en wordt niet gedeeld met
              derden. Je data wordt na 14 dagen automatisch verwijderd
            </Trans>
          </Text>

          <div
            className="flex gap-2 items-center cursor-pointer"
            onClick={openPrivacyPolicy}
          >
            <General.Link04 className=" w-5 flex-shrink-0" />
            <Text typography="callout" className="text-text-primary font-bold">
              <Trans id="privacy.link">
                Lees de volledige privacyverklaring
              </Trans>
            </Text>
          </div>
        </div>
      </div>

      <PrivacyForm onSuccess={onNext} />
    </div>
  )
}

export { PrivacyPage }
