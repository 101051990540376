import type { PermissionState } from '@capacitor/core'
import { Capacitor } from '@capacitor/core'
import type { ActionPerformed, Token } from '@capacitor/push-notifications'
import { PushNotifications } from '@capacitor/push-notifications'
import {
  useAxios,
  useEvent,
  useHealthblocksSWR,
  useSubjectId,
  useSubjectMember,
} from '@healthblocks-io/core'
import { useLingui } from '@lingui/react'
import * as Switch from '@radix-ui/react-switch'
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings'
import { useEffect } from 'react'

import { router } from '@/routing/router'

import { deviceName, getDeviceInfo } from './device'
import { createGlobal } from './global-hook'

// Types

export type NotificationChannel = {
  id: number
  medium: string
  createdAt: string
  display: string
  token: string
  meta: Record<string, unknown>
  memberId: number
  projectId: number
}

// Globals

// Globals - token
const web = Capacitor.getPlatform() === 'web'
export const {
  hook: useNotificationToken,
  setData: setToken,
  get: getToken,
  next: getNextToken,
} = createGlobal(web ? '' : undefined)

// Globals - permission status
export const {
  hook: usePermissionState,
  setData: setPermissionState,
  current: getPermissionState,
} = createGlobal<PermissionState | undefined>(undefined)

// Globals - permission status
const notificationIntent = createGlobal<'on' | 'off' | undefined>(
  localStorage.notificationIntent,
)
export const {
  hook: useNotificationIntent,
  setData: setNotificationIntent,
  current: getNotificationIntent,
  listeners: notificationIntentListeners,
} = notificationIntent

notificationIntentListeners.push(
  // eslint-disable-next-line unicorn/consistent-destructuring
  () => (localStorage.notificationIntent = notificationIntent.data),
)

// Globals - permission modal
export const { hook: useNotificationModal, setData: setNotificationModal } =
  createGlobal(false)

// Globals - creating a channel
const ref: { creating: Promise<NotificationChannel> | undefined } = {
  creating: undefined,
}

// Listen for events
if (Capacitor.isNativePlatform()) {
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification: ActionPerformed) => {
      console.log('push.pushNotificationActionPerformed', notification)
      // react-router navigate to /chat
      router.navigate('/chat')
    },
  )

  PushNotifications.addListener('registration', (token: Token) => {
    console.log('push.registration successful', token)
    // Global hook
    setToken(token.value)
  })

  // TODO: Consider capturing this error if it helps the user
  PushNotifications.addListener('registrationError', (error: any) => {
    console.log('push.registrationError', error)
    // setTokenError(error)
  })
}

// UI component for debugging
export function NotificationSettings() {
  const i18n = useLingui()
  const [enabled, enable, disable] = useEnableNotifications()

  return (
    <div className="">
      <div className="font-body mb-2 text-gray-700 font-semibold">
        {i18n._('settings.notifications.title', undefined, {
          message: 'Push meldingen',
        })}
      </div>
      <label className="font-body w-full p-4 flex gap-4 justify-between items-center rounded-md border border-solid border-gray-100 [&>svg]:w-6 [&>svg]:h-6 [&>svg]:stroke-text-primary">
        {i18n._('settings.notifications.label', undefined, {
          message: 'Meldingen ontvangen',
        })}
        <Switch.Root
          className="w-[44px] h-6 bg-slate-200 rounded-full relative data-[state=checked]:bg-green-600 outline-none cursor-default"
          checked={enabled}
          onClick={(x) => {
            console.log('onChange', x)
            return enabled ? disable() : enable()
          }}
        >
          <Switch.Thumb className="block w-5 h-5 bg-white rounded-full transition-transform duration-200 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[22px]" />
        </Switch.Root>
      </label>
      <p className="font-caption1 text-gray-600 mt-2">
        {i18n._('notification_modal.body', undefined, {
          message:
            'Activeer je meldingen zodat we je op de hoogte kunnen houden als er antwoord is op je vraag.',
        })}
      </p>
    </div>
  )
}

export function useNotificationPermissionState() {
  const status = usePermissionState()
  useEffect(() => {
    if (!status) notificationRegisterBackground()
  }, [!status])
  return status
}

export function useNotificationChannels() {
  const subjectId = useSubjectId()
  return useHealthblocksSWR<NotificationChannel[]>(
    subjectId ? '/notification-channel?memberId=' + subjectId : null,
  )
}

export function useEnableNotifications() {
  const axios = useAxios()
  const status = usePermissionState()
  const channels = useNotificationChannels()

  const enable = useEvent(async () => {
    setNotificationIntent('on')
    try {
      console.log('prmpt')
      const ok = await registerPrompt()
      console.log('prmptok', ok)
    } catch (error: any) {
      console.log('prmptca', error)
      // capacitor open notification settings screen
      if (Capacitor.isNativePlatform()) {
        NativeSettings.open({
          optionAndroid: AndroidSettings.AppNotification,
          optionIOS: IOSSettings.App,
        })
      }
    }

    setNotificationModal(false)
  })
  const disable = useEvent(async () => {
    setNotificationModal(false)
    delete localStorage.mockedPushToken
    setNotificationIntent('off')
    setPermissionState('prompt')
    for (const [key, value] of Object.entries(localStorage)) {
      if (key.startsWith('notification-channel/')) {
        console.log('delete', key)
        await axios.delete(`/notification-channel/${value}`)
        delete localStorage[key]
      }
    }
    channels.mutate()
  })
  return [
    status
      ? status === 'granted' && localStorage.notificationIntent === 'on'
      : undefined,
    enable,
    disable,
  ] as const
}

export function useNotificationChannelManager() {
  const subject = useSubjectMember()
  const axios = useAxios()
  const channels = useNotificationChannels()

  /** Create a notification channel */
  const createNotificationChannel = useEvent(
    async (input: Partial<NotificationChannel>) => {
      if (!subject) throw new Error('No subject')
      const ok = await axios.post<NotificationChannel>(
        '/notification-channel',
        { medium: 'firebase', ...input, memberId: subject.id },
      )
      localStorage['notification-channel/' + ok.data.id] = ok.data.id
      channels.mutate()
      setTimeout(() => (ref.creating = undefined), 1000)
      return ok.data
    },
  )

  /** Create a notification channel unless it already seems to exist */
  const createUniqueChannel = useEvent(
    async (input: Partial<NotificationChannel>) => {
      if (!subject) throw new Error('No subject')

      // Check if channel already exists
      const data =
        channels.data! ||
        (await axios
          .get<NotificationChannel[]>(
            '/notification-channel?memberId=' + subject.id,
          )
          .then((r) => r.data))
      const exists = data.find((c) => c.token === input.token)
      if (exists) return exists

      // Only create 1 channel
      if (!ref.creating) ref.creating = createNotificationChannel(input)

      return ref.creating
    },
  )

  const removeChannel = useEvent(async (id: number) => {
    await axios.delete(`/notification-channel/${id}`)
    delete localStorage.mockedPushToken
    channels.mutate()
  })

  /** Return the notification channel for this device */
  const register = useEvent(async () => {
    let token: string | null | undefined
    try {
      token = await registerPrompt()
      if (!token) throw new Error('Push notification permission not granted')
    } catch (error: any) {
      throw new Error(
        error.message || 'Push notification permission not granted',
      )
    }

    // Registration was successful, register with Healthblocks
    const info = await getDeviceInfo()
    return createUniqueChannel({
      display: info!.name ?? 'Unnamed device',
      token,
    })
  })

  return {
    channels,
    register,
    createUniqueChannel,
    removeChannel,
  }
}

/** Get a token if available in background, don't prompt, don't error */
export async function notificationRegisterBackground() {
  if (!Capacitor.isNativePlatform()) {
    if (localStorage.mockedPushToken) setMockedToken()
    else setPermissionState('prompt')
    return
  }
  const status = await PushNotifications.checkPermissions()
  setPermissionState(status.receive)
  if (status.receive === 'granted') await PushNotifications.register()
}

/** Get a token and prompt if needed */
export async function notificationPromptModal() {
  setNotificationModal(true)
}

/** Get a token and prompt if needed */
export async function registerPrompt() {
  const next = getNextToken()
  if (Capacitor.isNativePlatform()) {
    const status = await PushNotifications.requestPermissions()
    setPermissionState(status.receive)
    console.log('permstat', status)
    if (status.receive !== 'granted') throw new Error('Permission not granted')
    console.log('reg')
    await PushNotifications.register()
    console.log('reg ok')
  } else {
    // const sim = confirm(
    //   'Push notifications on web are not supported.\n\nDo you want to mock it?',
    // )
    // if (!sim) throw new Error('Push notifications on web are not supported')
    // setMockedToken()
    return null
  }
  console.log('await next')

  // Close modal after token is received
  const token = await next
  console.log('close modal')
  setNotificationModal(false)
  return token
}

async function setMockedToken() {
  setPermissionState('granted')
  const token = localStorage.mockedPushToken || (await deviceName)
  localStorage.mockedPushToken = token
  setToken(token)
  return token
}

/** Returns true if this device has permission to push but there is no channel registered */
export function useNotificationChannelExists() {
  const token = useNotificationToken()
  const channels = useNotificationChannels()
  return (
    !!channels.data && !!token && !channels.data.some((c) => c.token === token)
  )
}

/** Returns true if this device has permission to push but there is no channel registered */
export function useNotificationChannelCleanup() {
  const token = useNotificationToken()
  const channels = useNotificationChannels()
  const axios = useAxios()
  return useEvent(async () => {
    if (!token) return
    if (!channels.data) return
    const channel = channels.data.find((c) => c.token === token)
    if (!channel) return
    await axios.delete(`/notification-channel/${channel.id}`)
    await channels.mutate()
  })
}

/** Creates notification channel if granted and authenticated  */
export function useNotificationChannelSync() {
  // Trigger token check once
  useEffect(() => {
    const t = setTimeout(notificationRegisterBackground, 1000)
    return () => clearTimeout(t)
  }, [])

  const token = useNotificationToken()
  const channels = useNotificationChannels()
  const axios = useAxios()
  const subjectId = useSubjectId()
  const shouldCreateChannel =
    !!channels.data &&
    !!token &&
    !!subjectId &&
    !channels.data.some((c) => c.token === token) &&
    localStorage.notificationIntent === 'on'
  useEffect(() => {
    if (!shouldCreateChannel) return

    getDeviceInfo()
      .then((info) => {
        if (!ref.creating)
          ref.creating = axios
            .post<NotificationChannel>('/notification-channel', {
              medium: 'firebase',
              token,
              display: info.name ?? 'Unnamed device',
              memberId: subjectId,
            })
            .then((r) => {
              localStorage['notification-channel/' + r.data.id] = r.data.id
              channels.mutate()
              return r.data
            })
        setTimeout(() => (ref.creating = undefined), 2000)

        return ref.creating
      })
      .catch((error) => {
        console.error('channel creation failed', error)
      })
  }, [shouldCreateChannel])
}
