import type {
  ActivityType,
  CareplanActivity,
  CareplanActivityMeta,
} from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import { useCallback } from 'react'
import { Arrow, Time } from 'untitledui-js'

import { ContentType } from '../content/ContentType'

type Props = {
  activity: CareplanActivity<ActivityType, CareplanActivityMeta>
}

export const DefaultContent = ({ activity }: Props) => {
  const renderCTA = useCallback(() => {
    switch (activity.type as string) {
      case 'Message': {
        return i18n._(
          'home.activities.next_best_action.cta.message',
          undefined,
          {
            message: 'Lees bericht',
          },
        )
      }
      case 'article': {
        return i18n._(
          'home.activities.next_best_action.cta.article',
          undefined,
          {
            message: 'Lees dit artikel',
          },
        )
      }
      case 'audio': {
        return i18n._('home.activities.next_best_action.cta.audio', undefined, {
          message: 'Beluister deze audio',
        })
      }
      case 'video': {
        return i18n._('home.activities.next_best_action.cta.video', undefined, {
          message: 'Bekijk deze video',
        })
      }
      case 'exercise': {
        return i18n._(
          'home.activities.next_best_action.cta.exercise',
          undefined,
          {
            message: 'Start hier',
          },
        )
      }
      case 'collection': {
        return i18n._(
          'home.activities.next_best_action.cta.collection',
          undefined,
          {
            message: 'Ontdek',
          },
        )
      }
      default: {
        return null
      }
    }
  }, [activity.type])

  const duration = activity.meta?.duration
    ? i18n._(
        'calendar.duration',
        { duration: activity.meta.duration },
        { message: '±{duration} min.' },
      )
    : undefined

  return (
    <div className="flex flex-1 flex-col gap-2 p-4">
      <div className="flex gap-sp0.5">
        <ContentType type={activity.type} />

        {duration ? (
          <div className="flex gap-1 items-center">
            <Time.ClockStopWatch className="w-3 h-3 stroke-gray-500" />
            <p className="font-caption1 text-gray-700 font-semibold">
              {duration}
            </p>
          </div>
        ) : null}
      </div>

      <div className="flex flex-col flex-grow gap-5">
        <p className="font-title2 text-text-primary font-bold line-clamp-2">
          {activity.display}
        </p>
        <div className="flex flex-grow" />
        <div className="w-full flex gap-2">
          <div className="flex flex-grow gap-2">
            <p className="font-callout text-primary-700 font-semibold">
              {renderCTA()}
            </p>
            <Arrow.ArrowNarrowRight className="w-5 h-5 stroke-primary-700" />
          </div>
        </div>
      </div>
    </div>
  )
}
