import type {
  ActionEffect,
  CareplanActivity,
  IQuestionnaire,
  QuestionAnswerInput,
} from '@healthblocks-io/core'
import { useActorId, useAxios, useEvent } from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import { endOfDay } from 'date-fns'
import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { General, Time } from 'untitledui-js'

import { Button } from '@/components/button/Button'
import { ContentType } from '@/components/content/ContentType'
import {
  useInitialHistory,
  useQuestionnaire,
} from '@/components/questionnaire/activity-modal-state'
import PagedQuestionnaire from '@/components/questionnaire/PagedQuestionnaire'
import { QuestionHistory } from '@/components/questionnaire/question-history'
import { StatusBarFade } from '@/components/statusbar/StatusBarFade'
import { useToast } from '@/hooks/useToast'
import { Container } from '@/layout/Container'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import { IntroHeader } from '@/pages/content-detail/components/IntroHeader'
import { IntroTags } from '@/pages/content-detail/components/IntroTags'
import type {
  FrontdoorCareplanActivity,
  QuestionnaireExercise,
} from '@/utils/content'
import { useContent } from '@/utils/content'

export const QuestionnaireActivity = ({
  activity,
  questionnaire,
  onClose: _onClose,
  onChange,
  content,
}: {
  activity?: FrontdoorCareplanActivity
  questionnaire: IQuestionnaire
  onClose: () => void
  onChange?: () => void
  content?: QuestionnaireExercise
}) => {
  const axios = useAxios()
  const actorId = useActorId()
  const { showSuccessToast, showErrorToast } = useToast()

  // Special cases
  const [search, setSearch] = useSearchParams()
  const onboarding = search.get('onboarding')

  const firstAnswer = search.get('firstAnswer')
  const initialHistory = useInitialHistory(activity, questionnaire, firstAnswer)

  // Intro
  const showIntro =
    !firstAnswer &&
    (content?.description ||
      (activity?.meta.message && search.get('view') !== 'questionnaire'))

  // Outro suggestions
  const [[outro, effects], setEffects] = useState<[boolean, ActionEffect[]]>([
    false,
    [],
  ])
  const onClose = useEvent(() => {
    _onClose()
    onChange?.()
  })

  if (showIntro) {
    return (
      <div className="w-full h-full overflow-auto flex flex-col bg-brand-gradient">
        <IntroHeader type="Questionnaire" onClose={onClose} />
        <div id="wrapper" className="w-full flex flex-grow flex-col">
          <StatusBarFade />
          <Container className="flex flex-col flex-grow gap-2 mt-6">
            <IntroTags
              type={'questionnaire'}
              duration={(questionnaire as any).duration}
            />
            <div className="flex flex-col flex-grow gap-6">
              <div className="flex flex-col flex-grow gap-2">
                <p className="text-brand font-title1 font-bold">
                  {activity?.display || questionnaire.title}
                </p>

                <p className="text-text-secondary font-body whitespace-pre-line">
                  {content?.description ||
                    activity?.meta.message ||
                    questionnaire.description}
                </p>
              </div>

              <div className="flex flex-col pb-6">
                <Button
                  variant="primary"
                  size="large"
                  onClick={() =>
                    setSearch((search) => {
                      search.set('view', 'questionnaire')
                      return search
                    })
                  }
                >
                  {i18n._('questionnaire.start', undefined, {
                    message: 'Start',
                  })}
                </Button>
                <SafeAreaBottomSpacer />
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  }

  const createNewActivity = async (
    subjectId: number | undefined,
    questionnaireId: number,
    questionnaireSlug: string,
  ) => {
    const create = await axios.post<CareplanActivity>('/careplan-activity', {
      type: 'Questionnaire',
      subjectId,
      questionnaireId,
      contentSlug: questionnaireSlug,
      display: questionnaire.title,
      status: 'scheduled',
    })
    return create.data.id
  }

  const completeQuestionnaire = async (
    activityId: number,
    answers: QuestionAnswerInput[],
  ) => {
    const ok = await axios.post<{ effects: ActionEffect[] }>(
      '/careplan-activity/' + activityId + '/questionnaire-response',
      { answers, status: 'completed' },
    )
    const effects = ok.data?.effects?.filter(
      (effect) =>
        effect.type === 'activity' &&
        effect.effect === 'create' &&
        (!effect.data.plannedAt ||
          Date.parse(effect.data.plannedAt) < endOfDay(new Date()).valueOf()),
    )
    console.log('activity.submit', ok.data?.effects?.length, effects)
    if (effects?.length) return setEffects([true, effects])

    showSuccessToast({
      message: i18n._('questionnaire.success', undefined, {
        message: 'Vragenlijst verstuurd',
      }),
    })
    onClose()
  }

  if (!outro)
    return (
      <div className="h-full flex-col flex" ref={focus}>
        <SafeAreaTopSpacer />

        <QuestionHistory
          questionnaire={questionnaire}
          initialHistory={initialHistory}
          onChange={({ answers }) => {
            if (activity) {
              axios.post(
                '/careplan-activity/' + activity.id + '/questionnaire-response',
                { answers, status: 'in-progress' },
              )
            }
          }}
          onSubmit={async ({ answers }) => {
            try {
              if (activity && activity.status !== 'completed') {
                await completeQuestionnaire(activity.id, answers)
              } else {
                const id = await createNewActivity(
                  actorId,
                  questionnaire.id,
                  questionnaire.name,
                )
                await completeQuestionnaire(id, answers)
              }
            } catch (error: any) {
              console.log('activity.submit.error', error)
              showErrorToast({
                duration: 5000,
                message: i18n._(
                  'questionnaire.error',
                  { error: error.message },
                  { message: 'Probleem: {error}' },
                ),
              })
              onClose()
            } finally {
              onChange?.()
            }
          }}
        >
          <PagedQuestionnaire
            onClose={onboarding === 'true' ? undefined : onClose}
          />
        </QuestionHistory>
      </div>
    )

  return (
    <div className="p-6 gap-4 flex flex-col h-full overflow-auto bg-brand-gradient">
      <div
        className="flex flex-col absolute top-0 right-0 p-4 cursor-pointer"
        onClick={onClose}
      >
        <SafeAreaTopSpacer />
        <div className="p-3 bg-white rounded shadow border border-gray-200">
          <General.X className="w-5 h-5" />
        </div>
      </div>
      <div className="flex-1"></div>
      <p className="font-sans font-medium text-text-primary">
        {i18n._('activity.effect.thanks', undefined, {
          message: 'Bedankt voor je input',
        })}
      </p>
      <h2 className="font-title2 text-brand">
        {i18n._('activity.effect.title', undefined, {
          message:
            'Op basis van de informatie uit eerdere vragen bieden we u de volgende aanbevelingen aan:',
        })}
      </h2>
      <div className="flex flex-col gap-6">
        {effects.map((effect, index) => (
          <EffectCard effect={effect} key={index} />
        ))}
      </div>
      <div>
        <Button variant="tertiaryGray" size="medium" onClick={onClose}>
          {i18n._('activity.effect.ignore', undefined, {
            message: 'Ik heb geen interesse',
          })}
        </Button>
        <SafeAreaBottomSpacer />
      </div>
    </div>
  )
}

function EffectCard({ effect }: { effect: ActionEffect }) {
  const activity = effect.data
  const questionnaire = useQuestionnaire(activity)

  // Content
  const contentSwr = useContent(activity.type)
  const slug = activity.contentSlug
  const content = contentSwr.data?.find((c) => c.slug === slug)

  if (slug && !content) return null

  const card = (
    <div className=" pr-2 py-2 bg-white rounded-2xl shadow-card gap-2 flex">
      <div className="grow shrink basis-0 px-4 py-2 flex-col gap-4 flex">
        <div className="text-gray-800 text-lg font-semibold font-['Inter'] leading-normal">
          {activity.display || questionnaire?.title || content?.title}
        </div>
        <div className="flex-col gap-1 flex">
          <ContentType type={activity.type} />

          {content?.duration ? (
            <div className="flex gap-1 items-center">
              <Time.ClockStopWatch className="w-3 h-3 stroke-gray-500" />
              <p className="font-caption1 text-gray-700 font-semibold">
                {content?.duration}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      {content?.image?.thumbnail && (
        <img
          className="w-24 self-stretch rounded-lg object-center object-cover"
          src={content?.image?.thumbnail}
        />
      )}
    </div>
  )

  // This is correct
  if (effect.id)
    return (
      <Link to={`?activityId=${effect.id}`} className="flex-grow-0 basis-0">
        {card}
      </Link>
    )
  // This one is not implemented
  if (effect.data.id)
    return (
      <Link
        to={`?activityId=${effect.data.id}`}
        className="flex-grow-0 basis-0"
      >
        {card}
      </Link>
    )
  if (effect.data.contentSlug)
    return (
      <Link
        to={`?contentSlug=${effect.data.contentSlug}&type=${effect.data.type}`}
        className="flex-grow-0 basis-0"
      >
        {card}
      </Link>
    )
  return card
}
