import { Capacitor } from '@capacitor/core'
import type { StatusBarInfo } from '@capacitor/status-bar'
import { StatusBar, Style } from '@capacitor/status-bar'
import { useEffect } from 'react'

import { checkSafeAreaChanged, safeArea } from '@/hooks/useSafeArea'

import { createGlobal } from './global-hook'

export const statusBarInfo = createGlobal<StatusBarInfo | undefined>(
  Capacitor.getPlatform() === 'android'
    ? { overlays: false, style: Style.Light, visible: true, color: '#000000' }
    : Capacitor.getPlatform() === 'ios'
    ? { overlays: true, style: Style.Light, visible: true, color: '#000000' }
    : undefined,
)

if (statusBarInfo.data)
  StatusBar.getInfo().then(async (initial) => {
    console.log('initial status bar info', initial.overlays, initial.style)
    statusBarInfo.setData(initial)

    // Status bar needs some time to warm up...
    await new Promise((resolve) => setTimeout(resolve, 1000))

    // setOverlaysWebView may throw
    try {
      const overlay =
        Capacitor.getPlatform() === 'android'
          ? StatusBar.setOverlaysWebView({ overlay: true })
          : null
      await Promise.all([StatusBar.setStyle({ style: Style.Light }), overlay])
    } catch (error: any) {
      console.log('Error setting status bar style', error)
    }

    // Check again to see if it worked
    const info = await StatusBar.getInfo()
    statusBarInfo.setData(info)
    // If overlay was changed, the safe area may need to be updated
    checkSafeAreaChanged()
    // If safe area changed on Android, the status bar style may need to be updated
    render()
  })

const stack: { style: Style }[] = []

export const useStatusBarStyle = Capacitor.isNativePlatform()
  ? useStatusBarStyleNative
  : () => {}

function useStatusBarStyleNative(style: Style) {
  useEffect(() => {
    // Wrap in object to get a unique reference
    const item = { style }
    stack.push(item)
    render()
    return () => {
      const index = stack.indexOf(item)
      if (index !== stack.length - 1)
        console.log('Out of order status bar styles', stack)
      stack.splice(index, 1)
      render()
    }
  }, [style])
}

function render() {
  if (safeArea.data.top === 0) return console.log('--')
  const last = stack.at(-1)

  if (last) {
    console.log('Setting status bar style', last?.style, stack)
    StatusBar.setStyle(last)
  } else {
    console.log('no style')
  }
}

/** Android resets to overlay: false so we have to listen and revert it again.  */
export function forceSetOverlaysWebView(overlay: boolean) {
  if (Capacitor.getPlatform() !== 'android') return

  // Keep checking if the overlay is set correctly
  // const interval = setInterval(() => {
  //   StatusBar.getInfo().then((info) => {
  //     if (info.overlays !== overlay) {
  //       console.log('Forcing overlays', overlay)
  //       StatusBar.setOverlaysWebView({ overlay })
  //       StatusBar.setStyle({ style: Style.Light })
  //       clearInterval(interval)
  //     }
  //   })
  // }, 100)

  // setTimeout(() => {
  //   StatusBar.setOverlaysWebView({ overlay })
  //   StatusBar.setStyle({ style: Style.Light })
  // }, 400)

  // Stop checking after 1 second
  // setTimeout(() => clearInterval(interval), 2000)
}
