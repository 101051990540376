/** This file contains global constants */
export const ENV = import.meta.env.VITE_ENV || window.location.hostname

// export const defaultHealthblocksApi = ENV.includes('.dev.')
//   ? 'https://eu.dev.healthblocks.io'
export const defaultHealthblocksApi = 'https://eu.dev.healthblocks.io'

export type Preset = {
  env: string
  pid?: string
  display: string
  api: string
}

export const presets: Preset[] = [
  {
    env: 'dev',
    pid: 'frontdoor',
    display: 'eu-dev',
    api: 'https://api.eu.dev.healthblocks.io',
  },
  {
    env: 'prd',
    display: 'eu-prd',
    api: 'https://api.eu.healthblocks.io',
  },
]

if (window.location.host.includes('.')) {
  presets.unshift({
    env: 'host',
    pid: window.location.host.split('.')[0],
    display: 'Demo',
    api: `https://api.${window.location.host.replace(
      /localhost:\d+/,
      'demo.healthblocks.io',
    )}`,
  })
}

export const preset = presets.find((p) => p.env === ENV) || presets[0]
console.log('preset', preset)
/**
 * The intended API to use
 * Keep this a constant security wise
 */
export const healthblocksApi = presets.some(
  (p) => p.api === localStorage.healthblocksApi,
)
  ? localStorage.healthblocksApi
  : preset.api

/** Switch to a different API */
export function setHealthblocksApi(api: Preset['api']) {
  localStorage.healthblocksApi = api
  window.location.reload()
}

/** Wether a specific server URL is saved in local storage */
export const isServerOverride = !!localStorage.healthblocksApi

/** Wether the Vite build was run in production mode */
export const isProductionBuild = ENV === 'prd'

/** Wether test helpers should be displayed */
export const enableTestHelpers = isServerOverride
