import { i18n } from '@lingui/core'
import { useState } from 'react'

import { Button } from '@/components/button/Button'
import { Container } from '@/layout/Container'

const NoConnectionPage = () => {
  const [isLoading, setIsLoading] = useState(false)

  // Interaction Methods
  // ------------------------------------------------------------------------- /
  function refreshPage() {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  return isLoading ? null : (
    <Container className="overflow-auto bg-white z-20">
      <div className="py-6 min-h-screen flex flex-col justify-center items-center gap-6">
        <div className="flex justify-center items-center">
          <img
            className="w-2/3 max-w-lg max-h-96"
            src="/svg/error-connection.svg"
            alt=""
          />
        </div>
        <p className="font-title2 text-center">
          {i18n._('network.connection_error.title', undefined, {
            message: 'Verbinding even zoek',
          })}
        </p>
        <p className="font-body text-center mx-auto">
          {i18n._('network.connection_error.body', undefined, {
            message:
              'Het lijkt erop dat je internetverbinding weggevallen is. Kijk je instellingen na en probeer dan nog een keer.',
          })}
        </p>
        <div className="max-w-md">
          <Button variant="primary" onClick={refreshPage}>
            {i18n._('network.connection_error.cta', undefined, {
              message: 'Laad de pagina opnieuw',
            })}
          </Button>
        </div>
      </div>
    </Container>
  )
}

export { NoConnectionPage }
