import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { useField } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import type {
  FocusEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  ReactNode,
} from 'react'

import { dropInAnimation } from '@/theme/animation'
import type { PartiallyRequired } from '@/utils/typescript'

import { Text } from '../typography/Typography'

type InputProps = {
  label?: string
  icon?: ReactNode
} & PartiallyRequired<InputHTMLAttributes<HTMLInputElement>, 'name'>

const Input = ({ label, icon, ...props }: InputProps) => {
  const [field, meta] = useField(props)

  const hasError = meta.touched && !!meta.error

  const titleColor = hasError ? 'text-error-500' : 'text-gray-700'
  const inputColor = hasError ? 'text-error-500' : 'text-gray-700'
  const inputBorderColor = hasError ? 'border-error-500' : 'border-gray-300'

  return (
    <div className="w-full flex flex-col gap-2 relative pb-2">
      {label && (
        <Text
          typography="subheadline"
          className={`transition-colors duration-200 ${titleColor} font-bold`}
        >
          <label htmlFor={props.name}>{label}</label>
        </Text>
      )}

      <div className="relative">
        <input
          {...props}
          {...field}
          className={`w-full p-4 rounded-sm bg-base-white border border-solid shadow-sm transition-colors duration-200 ${inputColor} ${inputBorderColor} ${
            icon ? 'pl-11' : 'pl-4'
          }`}
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              field.onBlur(event)
              Keyboard.hide()
            }
          }}
          onFocus={(event: FocusEvent<HTMLInputElement>) => {
            if (Capacitor.isNativePlatform()) {
              setTimeout(() => {
                event.target.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'center',
                })
              }, 700)
            }
          }}
        />
        {icon ? (
          <div className="absolute top-4 bottom-4 left-4 flex items-center [&>svg]:stroke-gray-500">
            {icon}
          </div>
        ) : null}
      </div>

      <AnimatePresence>
        {hasError ? (
          <motion.div
            className="w-full absolute top-full"
            {...dropInAnimation(10)}
            key={meta.error}
          >
            <Text typography="caption1" className="text-error-500">
              {meta.error}
            </Text>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}

export { Input }
