import { i18n } from '@lingui/core'

import { NativeStoreButton } from '@/components/native-store-button/NativeStoreButton'
import { LoginLayout } from '@/layout/login/LoginLayout'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'

const GetTheAppPage = () => {
  return (
    <LoginLayout image={'img/background.jpg'}>
      <div className="flex flex-col gap-4 pb-6">
        <p className="font-title1 text-brand font-bold">
          {i18n._('get_the_app.title', undefined, {
            message: 'Welkom bij Healthblocks!',
          })}
        </p>
        <div className="flex flex-col gap-2">
          <p className="font-body text-text-primary font-semibold">
            {i18n._('get_the_app.subtitle', undefined, {
              message:
                'Helaas is de webversie nog niet beschikbaar. Download nu de app om toegang te krijgen tot je Healthblocks content.',
            })}
          </p>
        </div>

        <div className="flex justify-center gap-6 items-center my-6">
          <NativeStoreButton
            type="playStore"
            url="" //TODO: fill in google play store url
          />
          <NativeStoreButton
            type="appStore"
            url="" //TODO: fill in apple app store url
          />
        </div>
        <SafeAreaBottomSpacer />
      </div>
    </LoginLayout>
  )
}

export { GetTheAppPage }
