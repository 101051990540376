import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { useAuth } from '@healthblocks-io/core'
// import { useHealthblocksSWR } from '@healthblocks-io/core'
import { useEffect } from 'react'
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import { useOnboarding } from '@/hooks/useOnboarding'
import { ToastProvider } from '@/hooks/useToast'
import { useTracking } from '@/utils/analytics'
import { useMinimumVersion } from '@/utils/app-version'

const AppRoot = () => {
  // const supported = useHealthblocksSWR('/extension?type=suport')
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const onboarding = useOnboarding()
  const { page } = useTracking()

  // Consider redirect to update required page
  const nudge = useMinimumVersion()
  const updateRequired = nudge && nudge.required

  const wait = !auth || auth.loading || onboarding.isLoading

  // Wait for auth to be loaded before hiding splash screen
  useEffect(() => {
    if (!wait) {
      SplashScreen.hide({ fadeOutDuration: 250 })
      if (Capacitor.isNativePlatform())
        // @ts-expect-error
        window.screen.orientation.lock('portrait')
    }
  }, [wait])

  // Redirect to onboarding if needed
  useEffect(() => {
    if (!onboarding.isLoading && !onboarding.onboardingCompleted) {
      navigate('/onboarding')
    }
  }, [onboarding.isLoading, onboarding.onboardingCompleted])

  // Redirect to update required if needed
  useEffect(() => {
    if (updateRequired) navigate('/update-required')
  }, [updateRequired])

  // Enable to block the web version.
  // useEffect(() => {
  //   if (Capacitor.getPlatform() === 'web') {
  //     navigate('/get-the-app')
  //   }
  // }, [])

  useEffect(() => {
    App.addListener('appUrlOpen', (data) => {
      const url = new URL(data.url)
      const pathname =
        // eslint-disable-next-line unicorn/prefer-string-replace-all
        url.pathname.replace(/\/{2,}/g, '/') + url.search + url.hash

      const whiteListedPatterns = [
        /\/carepath\?activityId=\d+/,
        /\/oauth-callback/,
      ]

      if (
        matchesAnyPattern(pathname + url.search + url.hash, whiteListedPatterns)
      ) {
        navigate(pathname + url.search + url.hash)
      } else {
        navigate('/')
      }
    })
  }, [])

  useEffect(() => {
    page({
      title: document.title,
      path: location.pathname,
      search: location.search,
    })
  }, [location.pathname, location.search])

  return (
    <>
      <ToastProvider />
      <ScrollRestoration />
      <Outlet />
    </>
  )
}

export { AppRoot }

function matchesAnyPattern(inputString: string, regexArray: RegExp[]) {
  for (const regex of regexArray) {
    if (regex.test(inputString)) {
      return true
    }
  }
  return false
}
