import type { PhoneNumberType } from 'google-libphonenumber'
import gPhoneNumber from 'google-libphonenumber'
import { mergeRight } from 'ramda'
import * as Yup from 'yup'

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()

const YUP_PHONE_METHOD = 'phoneNumber'
const DEFAULT_ERROR_MESSAGES = {
  type: 'INCORRECT_TYPE',
  region: 'INCORRECT_REGION',
  format: 'INVALID_NUMBER',
}

Yup.addMethod(
  Yup.string,
  YUP_PHONE_METHOD,
  function yupPhoneNumber(
    supportedTypes: PhoneNumberType[] = [],
    supportedRegionCodes: string[] = [],
    _errorMessages: Partial<
      Record<keyof typeof DEFAULT_ERROR_MESSAGES, string>
    > = {},
  ) {
    const errorMessages = mergeRight(DEFAULT_ERROR_MESSAGES, _errorMessages)

    return this.test(YUP_PHONE_METHOD, function (value?: string) {
      const { path, createError } = this
      if (!value) {
        return true
      }

      try {
        const phoneNumber = phoneUtil.parseAndKeepRawInput(value ?? '')
        const regionCode = phoneUtil.getRegionCodeForNumber(phoneNumber) ?? ''
        const numberType = phoneUtil.getNumberType(phoneNumber)

        if (
          supportedRegionCodes.length > 0 &&
          !supportedRegionCodes.includes(regionCode)
        ) {
          return createError({
            message: errorMessages.region,
            path: path,
            params: {
              expected: supportedRegionCodes,
              value: regionCode,
            },
          })
        }

        if (!phoneUtil.isPossibleNumber(phoneNumber)) {
          return createError({
            message: errorMessages.format,
            path: path,
            params: {
              value: phoneNumber,
            },
          })
        }

        if (supportedTypes.length > 0 && !supportedTypes.includes(numberType)) {
          return createError({
            message: errorMessages.type,
            path: path,
            params: {
              expected: supportedTypes,
              value: numberType,
            },
          })
        }

        return true
      } catch {
        return createError({
          message: errorMessages.format,
          path: path,
        })
      }
    })
  },
)

export { DEFAULT_ERROR_MESSAGES }
