import { Style } from '@capacitor/status-bar'
import type { CareplanActivity } from '@healthblocks-io/core'
import {
  useActorId,
  useActorMember,
  useHealthblocksSWR,
} from '@healthblocks-io/core'
import { useLingui } from '@lingui/react'
import addDays from 'date-fns/addDays'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import { useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Alerts, Arrow, Communication } from 'untitledui-js'

import { ActivityCarousel } from '@/components/activityCarousel/ActivityCarousel'
import { ErrorCard } from '@/components/activityCarousel/ErrorCard'
import { chronological } from '@/components/calendar/activity'
import { ContentCard } from '@/components/content/card/ContentCard'
import { useContentCards } from '@/components/content/hook/useContent'
import { DismissableCard } from '@/components/dismissable-card/DismissableCard'
import { fadeGrowInOut } from '@/components/sequential-loader/SequentialAnimations'
import {
  SequenceItem,
  SequentialLoader,
} from '@/components/sequential-loader/SequentialLoader'
import { StatusBarFade } from '@/components/statusbar/StatusBarFade'
import { useUnreadMessages } from '@/hooks/useNewMessages'
import { Container } from '@/layout/Container'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import { alternateSort } from '@/utils/alternate-types-sort'
import {
  setNotificationIntent,
  useNotificationChannelManager,
  useNotificationIntent,
} from '@/utils/push-notification'
import { useStatusBarStyle } from '@/utils/status-bar'

import { ActivityModal } from '../activity/Activity.modal'

const HomePage = () => {
  const i18n = useLingui()
  const actorId = useActorId()
  const actorMember = useActorMember()
  const name = actorMember?.givenName
  useStatusBarStyle(Style.Light)

  const navigate = useNavigate()
  const pushManager = useNotificationChannelManager()

  const { unreadMessages } = useUnreadMessages()
  const [showNewMessageCard, setShowNewMessageCard] = useState(
    unreadMessages > 0,
  )
  const notificationIntent = useNotificationIntent()

  const activities = useHealthblocksSWR<CareplanActivity[]>(
    actorId
      ? `/careplan-activity?status=scheduled&subjectId=${actorId}&include=questionnaireResponse.answers,questionnaire`
      : null,
  )

  const nextBestActivities = useMemo(
    () =>
      activities?.data
        ?.sort(chronological)
        // Only show activities from the last 7 days to today
        .filter(
          (x) =>
            new Date(x.plannedAt) > startOfDay(addDays(new Date(), -7)) &&
            new Date(x.plannedAt) < endOfDay(new Date()),
        )
        .slice(0, 10) || [],
    [activities],
  )

  const { cardItems, loading, error } = useContentCards()
  const sortedItems = alternateSort(cardItems, [
    'article',
    'exercise',
    'video',
    'audio',
    'collection',
    'Questionnaire',
  ])

  return (
    <div className="h-full overflow-auto">
      <StatusBarFade variant={'pageheader'} />
      <Container className="bg-primary-50 p-6">
        <SafeAreaTopSpacer />
        <h1 className="font-title1 !leading-[28px] text-brand">
          {i18n._('home.title', { name })}
        </h1>
        <div
          className="overflow-hidden relative mt-4 cursor-pointer"
          onClick={() => {
            navigate('/chat')
          }}
        >
          <div className="flex justify-center items-center w-10 h-10 absolute left-[6px] top-0 bottom-0 m-auto bg-gray-50 rounded-full">
            <Communication.MessageChatCircle className="w-5 h-5 stroke-gray-500" />
          </div>
          <input
            type="search"
            className="text-lg p-2 pl-14 w-full leading-loose rounded-full text-text-primary placeholder:text-text-primary bg-white"
            placeholder={i18n._('home.search.placeholder')}
          />
        </div>
      </Container>
      {/* Combination of PullToRefresh with Horizontal scroll containers result in a weird scroll behaviour. Disabling the pull to refresh for now */}
      {/* <PullToRefresh
        onRefresh={async () => {
          const promises = [activities.mutate(), exercises.mutate()]
          await Promise.all(promises)
        }}
      > */}
      <Container className="pb-10 min-h-full bg-base-white">
        <SequentialLoader>
          {showNewMessageCard ? (
            <SequenceItem key={'new-message'} {...fadeGrowInOut} ignoreSequence>
              <DismissableCard
                icon={<Communication.MessageDotsCircle className={'w-5 h-5'} />}
                variant="message"
                text={i18n._('home.messages.new_message', undefined, {
                  message: 'Je hebt een nieuw chatbericht ontvangen.',
                })}
                onClick={() => {
                  navigate('/chat')
                }}
                onDismiss={() => {
                  setShowNewMessageCard(false)
                }}
              />
            </SequenceItem>
          ) : null}
          {notificationIntent ? null : (
            <SequenceItem
              key={'notification-nudge'}
              {...fadeGrowInOut}
              ignoreSequence
            >
              <DismissableCard
                icon={<Alerts.BellRinging04 className={'w-5 h-5'} />}
                variant="notification"
                text={i18n._('home.messages.notifications', undefined, {
                  message: 'Sta meldingen in deze app toe.',
                })}
                onClick={() => pushManager.register()}
                onDismiss={() => setNotificationIntent('off')}
              />
            </SequenceItem>
          )}

          {nextBestActivities.length > 0 ? (
            <SequenceItem
              key={'next-best-action'}
              isLoading={activities.isLoading && !activities.error}
            >
              <div className="pt-4 pb-1 flex flex-row justify-between font-semibold">
                <h2 className="text-lg text-text-primary">
                  {i18n._('home.activities.heading', undefined, {
                    message: 'Je acties',
                  })}
                </h2>
                <Link to={'/carepath'} className="flex gap-2 items-center">
                  <p className="font-body text-primary-700">
                    {i18n._('home.activities.view_all', undefined, {
                      message: 'Toon alles',
                    })}
                  </p>
                  <Arrow.ArrowNarrowRight className="w-5 h-5 stroke-primary-700" />
                </Link>
              </div>
              <ActivityCarousel
                error={activities.error}
                activities={nextBestActivities}
                mutate={activities.mutate}
              />
            </SequenceItem>
          ) : null}

          <SequenceItem key={'content-carousel'} isLoading={loading && !error}>
            <div className="flex flex-row justify-between pt-4 mb-2 font-semibold">
              <h2 className="text-lg text-text-primary">
                {i18n._('home.content.heading')}
              </h2>
              <Link to={'/discover'} className="text-lg text-brand">
                {i18n._('home.content.view_all')}
                <Arrow.ArrowNarrowRight className="ml-2 w-5 stroke-brand inline-block align-text-top" />
              </Link>
            </div>

            <div className="flex flex-wrap gap-4 pb-10">
              {sortedItems.slice(0, 4).map((card) => (
                <ContentCard key={card.id} {...card} />
              ))}
              {error ? (
                <ErrorCard onReload={async () => {}}>
                  <div className="font-semibold">
                    {i18n._('home.content.error', undefined, {
                      message: 'De inhoud kon niet geladen worden.',
                    })}
                  </div>
                  <div>{error.message}</div>
                </ErrorCard>
              ) : null}
            </div>
          </SequenceItem>
        </SequentialLoader>

        <ActivityModal
          onChange={() => {
            activities.mutate()
          }}
        />
      </Container>
      {/* </PullToRefresh> */}
    </div>
  )
}

export { HomePage }
