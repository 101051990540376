import { Style } from '@capacitor/status-bar'
import { useActorMember, useActorUpdate, useAxios } from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import { useNavigate } from 'react-router'

import { Container } from '@/layout/Container'
import { useHideNativeNavigation } from '@/layout/providers/LayoutProvider'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import { useStatusBarStyle } from '@/utils/status-bar'

import { ActivityModal } from '../activity/Activity.modal'
import { EditProfileForm } from './forms/EditProfileForm'

export const CompleteProfilePage = () => {
  const navigate = useNavigate()
  const [_, update] = useActorUpdate()
  const actor = useActorMember()
  const axios = useAxios()

  useStatusBarStyle(Style.Light)
  useHideNativeNavigation()

  return (
    <div className="h-full flex flex-col overflow-auto bg-brand-gradient bg-base-white">
      <Container className="flex flex-col gap-4  pt-6">
        <SafeAreaTopSpacer />
        <p className="font-title1 font-bold text-text-primary">
          {i18n._('profile.confirm.title', undefined, {
            message: 'Complete your profile',
          })}
        </p>
      </Container>

      <EditProfileForm
        onSuccess={async () => {
          if (actor) {
            try {
              await update({
                meta: {
                  ...actor.meta,
                  profileCompleted: new Date().toJSON(),
                },
              })
            } catch (error) {
              console.log(error)
            }

            try {
              // Create new onboarding activity
              const activity = await axios.post('/careplan-activity', {
                subjectId: actor.id,
                questionnaireSlug: 'introductie',
                display: 'Onboarding',
                status: 'scheduled',
              })

              console.log(activity)

              // Create chatwoot conversation
              const conversation = await axios.post(
                '/integration/chatwoot/endpoint/conversation',
                {
                  subjectId: actor.id,
                },
              )
              console.log(conversation)

              navigate('/?activityId=' + activity.data.id)
              return
            } catch (error) {
              console.log(error)
            }
          }
          navigate('/')
        }}
        buttonLabel={i18n._('profile.complete.button', undefined, {
          message: 'Voltooi je profiel',
        })}
      />

      <ActivityModal />
    </div>
  )
}
