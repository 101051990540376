import { Style } from '@capacitor/status-bar'
import type { CareplanActivity } from '@healthblocks-io/core'
import { useActorId, useAxios } from '@healthblocks-io/core'
import { useState } from 'react'

import { Button } from '@/components/button/Button'
import { Container } from '@/layout/Container'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import { useStatusBarStyle } from '@/utils/status-bar'

const TestPage = () => {
  const axios = useAxios()
  const actorId = useActorId()
  useStatusBarStyle(Style.Light)

  const [prepSuccess, setPrepSuccess] = useState(false)
  const prepTestActivities = async () => {
    await deleteTestActivities()

    // Create new test activities
    await axios.post('/careplan-activity', {
      subjectId: actorId,
      questionnaireSlug: 'questionnaire_inputs_test',
      display: 'Test: Questionnaire Inputs',
    })
    setPrepSuccess(true)
  }

  const deleteTestActivities = async () => {
    // Get all existing activities
    const activities = await axios.get('/careplan-activity', {
      params: {
        subjectId: actorId,
      },
    })
    const testActivities =
      activities.data?.filter((activity: CareplanActivity) =>
        activity.display.startsWith('Test:'),
      ) || []

    // Delete all existing test activities
    for (const activity of testActivities) {
      await axios.delete(`/careplan-activity/${activity.id}`)
    }
  }

  return (
    <Container className="h-full overflow-auto">
      <div className="bg-primary-50 -mx-6 p-6">
        <SafeAreaTopSpacer />
        <h1 className="text-3xl font-bold text-text-primary">
          Automated Test Page
        </h1>
        {actorId ? <p>ActorId: {actorId}</p> : null}
      </div>
      <div className="flex flex-col flex-grow gap-6 py-2">
        <div className="flex flex-col items-center py-4">
          {prepSuccess ? (
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                deleteTestActivities()
                setPrepSuccess(false)
              }}
            >
              Clean up Test Activities
            </Button>
          ) : (
            <Button variant="primary" size="large" onClick={prepTestActivities}>
              Prep Test Activities
            </Button>
          )}
        </div>
      </div>
    </Container>
  )
}

export { TestPage }
