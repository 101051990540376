import { createContext, useContext } from 'react'

export const CalendarContext = createContext({
  refetch: () => {
    alert('Context missing')
  },
})

export function useCalendar() {
  return useContext(CalendarContext)
}
