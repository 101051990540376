import type { IQuestionnaire, QuestionAnswer } from '@healthblocks-io/core'
import { useHealthblocksSWR } from '@healthblocks-io/core'
import { useEffect } from 'react'

import { useToast } from '@/hooks/useToast'
import type { FrontdoorCareplanActivity } from '@/utils/content'

import { getNextQuestion } from './question-history'

export const preloadKey = (id: number | string | null) =>
  id
    ? '/careplan-activity/' +
      id +
      '?include=questionnaire,questionnaireResponse.answers'
    : null

export function useQuestionnaire(
  activity?: {
    type?: string
    meta?: { questionnaireSlug?: string }
    questionnaire?: IQuestionnaire
  },
  contentSlug?: string,
): IQuestionnaire | undefined {
  const { showErrorToast } = useToast()
  const slug = activity
    ? activity.type === 'Questionnaire' &&
      !activity.questionnaire &&
      activity.meta?.questionnaireSlug
    : contentSlug

  const questionnaireBySlug = useHealthblocksSWR<IQuestionnaire>(
    slug ? '/questionnaire/' + slug : null,
    {
      onErrorRetry(error, key, config, revalidate, { retryCount }) {
        // Never retry for these errors
        if (
          error?.response?.status === 404 ||
          error?.response?.status === 401
        ) {
          return
        }
        // Try a max of 2 retries
        if (retryCount >= 2) {
          return
        }
        setTimeout(() => {
          revalidate({ retryCount })
        }, 5000)
      },
    },
  )

  useEffect(() => {
    if (questionnaireBySlug.error) {
      showErrorToast({
        message: `Questionnaire met slug: ${contentSlug} bestaat niet`,
      })
    }
  }, [questionnaireBySlug.error])

  return (
    (activity?.type === 'Questionnaire' && activity?.questionnaire) ||
    questionnaireBySlug.data
  )
}

export function useInitialHistory(
  activity: FrontdoorCareplanActivity | undefined,
  questionnaire: IQuestionnaire,
  firstAnswer: string | null,
) {
  const questionnaireResponse = activity?.questionnaireResponse

  // If there a firstAnswer, ignore all history and use that instead
  // If there is no firstAnswer, load activity history
  const answers = firstAnswer
    ? [JSON.parse(firstAnswer) as QuestionAnswer]
    : questionnaireResponse?.answers

  return getNextQuestion({
    questionnaire,
    answers,
  }).nextHistory
}
