import 'react-multi-carousel/lib/styles.css'

import { Preferences } from '@capacitor/preferences'
import { i18n } from '@lingui/core'
import { useRef } from 'react'
import Carousel from 'react-multi-carousel'
import { useNavigate } from 'react-router'
import { Arrow } from 'untitledui-js'

import { Button } from '@/components/button/Button'
import { Container } from '@/layout/Container'
import { useHideNativeNavigation } from '@/layout/providers/LayoutProvider'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'

const responsive = {
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
}

const slides = [
  {
    title: i18n._('onboarding.slide1.title', undefined, {
      message: 'Onboarding 1',
    }),
    description: i18n._('onboarding.slide1.description', undefined, {
      message: 'Description 1',
    }),
    image: '/img/background.jpg',
  },
  {
    title: i18n._('onboarding.slide2.title', undefined, {
      message: 'Onboarding 2',
    }),
    description: i18n._('onboarding.slide2.description', undefined, {
      message: 'Description 2',
    }),
    image: '/img/background.jpg',
  },
]

export const OnboardingPage = () => {
  const navigate = useNavigate()
  useHideNativeNavigation()

  const carouselRef = useRef<any>()

  const onCompleteOnboarding = async () => {
    await Preferences.set({
      key: 'onboardingCompleted',
      value: new Date().toJSON(),
    })
    navigate('/login')
  }

  return (
    <div className="h-full overflow-auto bg-brand-gradient z-10">
      <Container className="flex flex-col h-full max-h-full p-6 pt-2">
        <SafeAreaTopSpacer />
        <div className="flex justify-end flex-shrink-0 z-30">
          <button
            className="flex gap-2 justify-center items-center -mx-4 px-4 py-3"
            onClick={onCompleteOnboarding}
          >
            <p className="font-callout leading-4 font-semibold text-gray-600">
              {i18n._('globals.skip', undefined, {
                message: 'Overslaan',
              })}
            </p>
            <Arrow.ArrowNarrowRight className="w-5 h-5 stroke-gray-600" />
          </button>
        </div>

        <div className="-mx-6 flex-grow flex min-h-0">
          <Carousel
            ref={carouselRef}
            responsive={responsive}
            showDots={true}
            keyBoardControl={true}
            arrows={false}
            customDot={<Dot />}
            className="flex-grow pb-20"
            sliderClass="h-full"
          >
            {slides.map((slide, index) => (
              <OnboardingSlide key={index} {...slide} />
            ))}
          </Carousel>
        </div>
        <div className="mt-8">
          <Button
            size="medium"
            className="min-w-fit w-auto mx-auto my-0"
            onClick={() => {
              if (
                carouselRef.current &&
                carouselRef.current.state.currentSlide === slides.length - 1
              ) {
                onCompleteOnboarding()
              } else {
                carouselRef.current.next()
              }
            }}
          >
            {i18n._('globals.next')}{' '}
            <Arrow.ArrowNarrowRight className="w-5 h-5 stroke-white" />
          </Button>
        </div>

        <div className="py-4 w-full flex-shrink-0 self-end">
          <SafeAreaBottomSpacer />
        </div>
      </Container>
    </div>
  )
}

type SlideProps = {
  title: string
  description: string
  image: string
}

const OnboardingSlide = ({ title, description, image }: SlideProps) => {
  return (
    <div className="h-full flex flex-col gap-4 px-6">
      <div className="flex flex-grow -mx-4 min-h-0">
        <img src={image} className=" object-contain w-full" draggable={false} />
      </div>
      <div className="flex flex-col gap-4">
        <p className="font-title1 font-bold text-brand text-center">{title}</p>
        <p className="font-body text-text-primary text-center">{description}</p>
      </div>
    </div>
  )
}

const Dot = ({ active, onClick }: any) => {
  return (
    <span
      className={`cursor-pointer h-2 w-2 rounded-full inline-block mx-0.5 ${
        active ? 'bg-gray-600' : 'bg-gray-200'
      }`}
      onClick={onClick}
    />
  )
}
