import {
  type ActivityStatus,
  type ActivityType,
  useAxios,
} from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import format from 'date-fns/format'
import nl from 'date-fns/locale/nl'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { Maps, Time } from 'untitledui-js'

import { Card } from '@/components/Card'
import { ContentType } from '@/components/content/ContentType'
import { useSafeArea } from '@/hooks/useSafeArea'
import { useContent } from '@/utils/content'
import { openUrl } from '@/utils/external-resources'

import { StatusLine } from './StatusLine'

type CalendarCardProps = {
  id: number
  slug?: string
  contentSlug?: string | null
  display: string
  type:
    | ActivityType
    | 'Appointment'
    | 'article'
    | 'audio'
    | 'video'
    | 'exercise'
    | 'collection'
  duration?: string
  startTime?: Date | null
  status: ActivityStatus | null
  thumbnailUrl?: string
  location?: string | null
  showDate?: boolean
  meta?: {
    message?: string
    contentDisplay?: string
  }
}

export const CalendarCard = ({
  id,
  contentSlug,
  display,
  type,
  duration,
  startTime,
  status,
  thumbnailUrl,
  location,
  showDate,
  meta,
}: CalendarCardProps) => {
  const axios = useAxios()
  const navigate = useNavigate()

  const content = useContent(type).data?.find(
    (item) => item.slug === contentSlug,
  )

  const onCardClick = () => {
    if (meta?.message) {
      navigate(`?activityId=${id}&view=activity`)
      return
    }

    switch (type) {
      case 'Questionnaire': {
        if (status === 'completed') {
          toast('Deze vragenlijst is al ingevuld')
        } else {
          navigate(`?activityId=${id}`)
        }
        break
      }

      case 'video':
      case 'audio':
      case 'article':
      case 'collection':
      case 'exercise': {
        navigate(
          id ? `?activityId=${id}` : `?contentSlug=${contentSlug}&type=${type}`,
        )
        break
      }

      case 'AppointmentRequest': {
        if (status !== 'completed') {
          axios.patch('/careplan-activity/' + id, { status: 'completed' })
          const prefill = encodeURIComponent(
            `Ik wil graag een afspraak boeken.`,
          )
          navigate(`/chat?prefill=${prefill}`)
        }
        break
      }

      case 'Appointment': {
        if (status === 'completed') {
          toast(
            i18n._('error.appointment.completed', undefined, {
              message: 'Deze afspraak is reeds voltooid',
            }),
          )
        } else {
          navigate(`?appointmentId=${id}`)
        }
        break
      }
      case 'Message': {
        navigate(`?activityId=${id}`)
        break
      }

      default: {
        break
      }
    }
  }

  const onLocate = () => {
    openUrl(
      `https://www.google.com/maps/search/?api=1&query=${location}`,
      '_blank',
    )
  }

  // @ts-expect-error
  display = display || meta?.contentDisplay || meta?.questionnaireDisplay || ''
  thumbnailUrl = thumbnailUrl || content?.image?.url
  duration =
    duration || content?.duration
      ? i18n._(
          'calendar.duration',
          { duration: duration || content?.duration },
          { message: '±{duration} min.' },
        )
      : undefined

  const safe = useSafeArea()
  return (
    <article
      className="flex gap-2"
      style={{ scrollMarginTop: 140 + safe.top }}
      id={'activity' + id}
    >
      <StatusLine status={status} />
      <Card className="p-2 cursor-pointer" onClick={onCardClick}>
        <div className="flex flex-grow justify-between items-center gap-2">
          <div className="flex flex-col flex-grow gap-4 p-2">
            {display && (
              <p className="font-headline text-text-primary font-bold">
                {display}
              </p>
            )}

            <div className="flex justify-between gap-2">
              <div className="flex flex-col flex-grow gap-2">
                <ContentType type={type} />

                {startTime ? (
                  <div className="flex gap-1 items-center">
                    <Time.ClockStopWatch className="w-3 h-3 stroke-gray-500" />
                    <p className="font-caption1 text-gray-700 font-semibold">
                      {showDate
                        ? format(startTime, 'E dd MMM yyyy - HH:mm', {
                            locale: nl,
                          })
                        : format(startTime, 'HH:mm', { locale: nl })}
                    </p>
                  </div>
                ) : duration ? (
                  <div className="flex gap-1 items-center">
                    <Time.ClockStopWatch className="w-3 h-3 stroke-gray-500" />
                    <p className="font-caption1 text-gray-700 font-semibold">
                      {duration}
                    </p>
                  </div>
                ) : null}

                {location ? (
                  <div className="flex gap-1">
                    <Maps.MarkerPin03 className="w-4 h-4 stroke-gray-500" />
                    <div className="flex flex-col gap-1">
                      <p className="font-caption2 text-gray-700 font-semibold">
                        {location}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="flex items-end">
                {type === 'Appointment' ? (
                  <button
                    className="w-10 h-10 bg-gray-50 rounded-full flex justify-center items-center"
                    onClick={(event) => {
                      event.stopPropagation()
                      onLocate()
                    }}
                  >
                    <Maps.MarkerPin01 className="stroke-gray-500" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>

          {thumbnailUrl && type !== 'Appointment' ? (
            <div className="w-[100px] h-full flex-shrink-0">
              <img
                src={thumbnailUrl}
                className="w-full h-full object-cover rounded-md bg-gray-50"
                loading="lazy"
              />
            </div>
          ) : null}
        </div>
      </Card>
    </article>
  )
}
