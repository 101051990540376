import { Dialog, Transition } from '@headlessui/react'
import type { MutableRefObject, ReactNode } from 'react'
import { Fragment } from 'react'

type Props = {
  open?: boolean
  onBlur: () => void
  children: ReactNode
  initialFocus?: MutableRefObject<HTMLElement | null>
}

export const FullScreenModal = ({
  open = true,
  onBlur,
  children,
  initialFocus,
}: Props) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={onBlur}
        initialFocus={initialFocus}
      >
        <div
          id={'full-screen-modal'}
          className={'fixed inset-0 overflow-y-auto'}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full h-full transform overflow-hidden bg-white text-left align-middle transition-all">
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
