import { useAuth } from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import { useCallback } from 'react'
import { General } from 'untitledui-js'

import { Button } from '@/components/button/Button'
import Modal from '@/components/modal/Modal'
import { useToast } from '@/hooks/useToast'
import { useNotificationChannelCleanup } from '@/utils/push-notification'

type Props = {
  open: boolean
  onClose: () => void
}

export const SignOutModal = ({ open, onClose }: Props) => {
  const actions = useAuth()
  const cleanupNotification = useNotificationChannelCleanup()

  const { showErrorToast } = useToast()

  const signOut = useCallback(async () => {
    try {
      await cleanupNotification()
      onClose()
      actions.signOut()
    } catch (error) {
      console.log(error)
      showErrorToast({
        message: i18n._('profile.sign_out.error', undefined, {
          message: 'Something went wrong',
        }),
      })
    }
  }, [])

  return (
    <Modal
      title=""
      open={open}
      onBlur={onClose}
      bodyClassName="rounded-lg max-w-sm"
      dialog
    >
      <div className="p-6 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex justify-center items-center">
            <div className="w-10 h-10 flex justify-center items-center rounded-full bg-primary-50">
              <General.HelpCircle className="w-5 h-5 stroke-primary-400" />
            </div>
          </div>

          <p className="font-title2 font-bold text-center text-text-primary">
            {i18n._('profile.sign_out.title', undefined, {
              message: 'Are you sure you want to log out?',
            })}
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <Button variant="primary" size="medium" onClick={signOut}>
            {i18n._('profile.sign_out.accept', undefined, {
              message: 'Yes',
            })}
          </Button>
          <Button variant="gray" size="medium" onClick={onClose}>
            {i18n._('profile.sign_out.cancel', undefined, {
              message: 'No, Cancel',
            })}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
