import { i18n } from '@lingui/core'
import { Link } from 'react-router-dom'
import { Time } from 'untitledui-js'

import { ContentType } from '../ContentType'
import { ContentImageWithFallback } from '../fallback/ContentImageWithFallback'

export type ContentCardProps = {
  id: string
  title: string
  type:
    | 'article'
    | 'audio'
    | 'video'
    | 'exercise'
    | 'collection'
    | 'Questionnaire'
  thumbnailUrl?: string
  duration?: string
  published?: boolean
  fromCollection?: string
}

export const ContentCard = ({
  id: contentSlug,
  title,
  type,
  thumbnailUrl,
  duration,
  fromCollection,
}: ContentCardProps) => {
  duration = duration
    ? i18n._('calendar.duration', { duration }, { message: '±{duration} min.' })
    : undefined

  return (
    <Link
      to={`?contentSlug=${contentSlug}&type=${type}${
        fromCollection ? `&fromCollection=${fromCollection}` : ''
      }`}
      className="flex flex-col gap-2 w-[calc(50%-8px)] cursor-pointer"
    >
      <ContentImageWithFallback src={thumbnailUrl} type={type} />
      <div className="flex gap-2 flex-wrap">
        <ContentType type={type} />
        {duration ? (
          <div className="flex gap-1 items-center">
            <Time.ClockStopWatch className="w-3 h-3 stroke-gray-500" />
            <p className="font-caption1 text-gray-700 font-semibold">
              {duration}
            </p>
          </div>
        ) : null}
      </div>
      <p className="font-callout text-text-primary font-semibold line-clamp-2 pb-2">
        {title}
      </p>
    </Link>
  )
}
