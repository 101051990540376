import { i18n } from '@lingui/core'
import { useState } from 'react'

import { Button } from '@/components/button/Button'
import { Container } from '@/layout/Container'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'
import type { Audio, FrontdoorCareplanActivity } from '@/utils/content'

import { AudioHeader } from '../components/AudioHeader'
import { IntroTags } from '../components/IntroTags'

type Props = {
  content: Audio
  onClose: () => void
  onComplete: () => void
  activity?: FrontdoorCareplanActivity
}

export const AudioIntro = ({
  activity,
  content,
  onClose,
  onComplete,
}: Props) => {
  const [start, setStart] = useState(false)

  return (
    <div className="w-full h-full overflow-auto flex flex-col gap-6 bg-brand-gradient">
      <AudioHeader
        content={content}
        start={start}
        onStart={() => {
          setStart(true)
        }}
        onClose={onClose}
      />
      <Container className="flex flex-col flex-grow gap-2">
        <IntroTags type={'audio'} duration={content.duration} />

        <div className="flex flex-col flex-grow gap-6">
          <div className="flex flex-col flex-grow gap-2">
            <p className="text-brand font-title1 font-bold">
              {activity?.display || content.title}
            </p>

            <p className="text-text-secondary font-body whitespace-pre-line">
              {activity?.meta.message || content.description}
            </p>
          </div>

          <div className="flex flex-col pb-6">
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                if (start) {
                  onClose()
                  onComplete()
                } else setStart(true)
              }}
            >
              {start
                ? i18n._('audio.mark_completed', undefined, {
                    message: 'Markeer als afgespeeld',
                  })
                : i18n._('audio.start', undefined, {
                    message: 'Beluister deze oefening',
                  })}
            </Button>
            <SafeAreaBottomSpacer />
          </div>
        </div>
      </Container>
    </div>
  )
}
