import type { CareplanActivity } from '@healthblocks-io/core/types'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import { useEffect, useMemo, useRef } from 'react'

import type { FrontdoorCareplanActivity } from '@/utils/content'

import { chronological, groupByDay, groupByDayInterval } from './activity'
import { CalendarContext } from './CalendarContext'
import { CalendarItem } from './CalendarItem'

export default function SimpleCalendar({
  activities,
  refetch,
}: {
  activities: Partial<FrontdoorCareplanActivity>[]
  refetch: () => void
}) {
  // renderDays is probably not perfect, but close enough
  const data = useMemo(() => {
    const now = startOfDay(Date.now())
    const days = renderDays(activities as CareplanActivity[])
    const pivot = days.findIndex((d) => d.date.valueOf() >= now.valueOf())
    return pivot === -1
      ? // eslint-disable-next-line unicorn/prefer-spread
        days.concat({
          activities: [
            {
              status: 'scheduled',
              plannedAt: new Date().toJSON(),
              id: -1,
              display: 'Geen activiteiten',
              type: 'Message',
            } as CareplanActivity,
          ],
          date: new Date(),
          key: format(now, 'yyyy-MM-dd'),
        })
      : days
  }, [activities])

  const scrollTo = useMemo(() => {
    const now = startOfDay(Date.now()).getTime()
    const sorted = [...activities].sort(chronological)
    // Next planned
    for (const activity of sorted) {
      if (
        activity.plannedAt &&
        Date.parse(activity.plannedAt) > now &&
        activity.status === 'scheduled'
      ) {
        return activity
      }
    }
    // Most recently planned
    // eslint-disable-next-line unicorn/prefer-spread
    for (const activity of sorted.reverse()) {
      if (activity.plannedAt && activity.status === 'scheduled') return activity
    }

    return { id: -1 }
  }, [activities])

  const stable = useRef(false)
  useEffect(() => {
    if (scrollTo) {
      const element = document.querySelector(`#activity${scrollTo.id}`)
      if (element && !stable.current) {
        element.scrollIntoView({})
      }
      const t = setTimeout(() => {
        stable.current = true
      }, 1000)
      return () => clearTimeout(t)
    } else {
      const element = document.querySelector(`#today`)
      if (element && !stable.current) {
        element.scrollIntoView({})
      }
      const t = setTimeout(() => {
        stable.current = true
      }, 1000)
      return () => clearTimeout(t)
    }
  }, [scrollTo])

  // Context for actions on the cards
  const value = useMemo(() => ({ refetch }), [refetch])

  return (
    <CalendarContext.Provider value={value}>
      <div className="pt-8 pb-[calc(100vh-350px)]">
        {data.map((data, index) => (
          <CalendarItem key={index} data={data} index={index} />
        ))}
      </div>
    </CalendarContext.Provider>
  )
}

// No support for repetitions
export function renderDays(activities: CareplanActivity[]) {
  activities = activities.sort(chronological)

  if (activities.length === 0) return []

  const groups = groupByDay(activities)
  const start = new Date(groups.length > 0 ? groups[0].key : new Date())
  const end = endOfDay(new Date(groups.at(-1)?.key || Date.now()))
  const daysRendered = groupByDayInterval([], start, end)

  daysRendered.forEach((day) => {
    const group = groups.find((g) => g.key === day.key)?.activities
    if (group) {
      day.activities = group
    }
  })
  return daysRendered
}
