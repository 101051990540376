import type { Typography } from './types/typography'

export const typography: Typography = {
  largeTitle: 'text-[34px] leading-[41px] tracking-[-0.4px]',
  title1: 'text-[28px] leading-[34px] tracking-[-0.4px]',
  title2: 'text-[22px] leading-[28px] tracking-[-0.4px]',
  title3: 'text-[20px] leading-[25px] tracking-[-0.4px]',
  headline: 'text-[17px] leading-[22px] tracking-[-0.4px]',
  body: 'text-[17px] leading-[22px] tracking-[-0.4px]',
  callout: 'text-[16px] leading-[21px] tracking-[-0.4px]',
  subheadline: 'text-[15px] leading-[20px] tracking-[-0.4px]',
  footNote: 'text-[13px] leading-[18px] tracking-[-0.4px]',
  caption1: 'text-[12px] leading-[16px] tracking-[-0.4px]',
  caption2: 'text-[11px] leading-[13px] tracking-[-0.4px]',
}
