import type { SpacingKey, SpacingPx, SpacingValue } from './types/spacing'

export const spacing = (baseSpace: number) => {
  const spacingPxList: Partial<SpacingPx> = {}
  const spacingList: Partial<SpacingValue> = {}

  // Dynamically generate the spacing classes
  Array.from({ length: 20 })
    .fill(null)
    .forEach((_, index) => {
      const key = `space${index + 1}` as SpacingKey
      const value = (index + 1) * baseSpace

      spacingList[key] = value
      spacingPxList[key] = `${value}px`
    })

  return {
    layout: {
      borderRadius: {
        small: baseSpace,
        large: 2 * baseSpace,
      },
      spacingPx: spacingPxList as SpacingPx,
      spacing: spacingList as SpacingValue,
    },
  }
}

export const tailwindSpacing = (baseSpace: number) => {
  const spacingPxList: Partial<SpacingPx> = {}

  // Dynamically generate the spacing classes
  Array.from({ length: 20 })
    .fill(null)
    .forEach((_, index) => {
      const wholeNumber = index
      const key = `sp${wholeNumber}` as SpacingKey
      const value = wholeNumber * baseSpace

      spacingPxList[key] = `${value}px`

      if (wholeNumber <= 3) {
        for (let fraction = 0.25; fraction < 1; fraction += 0.25) {
          const fractionValue = wholeNumber + fraction
          const fractionKey = `sp${fractionValue}` as SpacingKey
          const fractionSpacing = fractionValue * baseSpace

          spacingPxList[fractionKey] = `${fractionSpacing}px`
        }
      }
    })

  return spacingPxList
}
