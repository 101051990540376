import type { HTMLProps } from 'react'

/** Basic card */
export function Card(props: HTMLProps<HTMLDivElement>) {
  return (
    <div className="w-full h-full py-3">
      <div
        {...props}
        className={
          (props.className || '') +
          ' h-full flex rounded-lg shadow-massive border border-gray-50'
        }
      />
    </div>
  )
}

/** Basic card */
export function CardBody(props: HTMLProps<HTMLDivElement>) {
  return <div {...props} className={(props.className || '') + ' px-4'} />
}
