import { useField } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import type { InputHTMLAttributes, ReactNode } from 'react'
import { General } from 'untitledui-js'

import { dropInAnimation } from '@/theme/animation'
import type { PartiallyRequired } from '@/utils/typescript'

import { Text } from '../typography/Typography'

type Props = {
  label?: string | ReactNode
} & PartiallyRequired<
  Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>,
  'name'
>

const Checkbox = ({ label, ...props }: Props) => {
  const [field, meta, helpers] = useField(props)

  return (
    <div className="flex flex-col gap-4 pb-6 relative">
      <button
        type="button"
        className={
          (field.value
            ? 'bg-primary-50 text-primary-800 border-primary-600'
            : 'bg-white text-gray-700 border-gray-100') +
          ' shadow-card rounded-sm border p-4 flex justify-start gap-3 text-left'
        }
        onClick={() => helpers.setValue(!field.value)}
      >
        <input
          checked={field.value}
          readOnly
          type="checkbox"
          className="hidden"
        />
        <div
          className={`w-6 h-6 relative flex-shrink-0 border rounded-sm transition-colors duration-200 ${
            field.value
              ? 'bg-primary-600 border-primary-600'
              : 'bg-base-white border-gray-300'
          }`}
        >
          <AnimatePresence>
            {field.value ? (
              <motion.div key={field.name} {...dropInAnimation(-10)}>
                <General.Check className="stroke-base-white" />
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>

        <Text typography="body">{label}</Text>
      </button>

      <AnimatePresence>
        {meta.error ? (
          <motion.div
            className="w-full absolute bottom-0"
            {...dropInAnimation(10)}
            key={meta.error}
          >
            <Text typography="caption1" className="text-error-500">
              {meta.error}
            </Text>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}

export { Checkbox }
