import type { CareplanActivity } from '@healthblocks-io/core'
import { useEvent, useHealthblocksSWR } from '@healthblocks-io/core'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import type { FrontdoorCareplanActivity } from './content'
import { useContent } from './content'

export const activityDetailURL = (id: number | string | null) =>
  id
    ? '/careplan-activity/' +
      id +
      '?include=questionnaire,questionnaireResponse.answers'
    : null

export function useSearchParameterActivity() {
  const [search, setSearch] = useSearchParams()
  const [closing, setClosing] = useState(false)

  // Activity
  const activityId = parseInt(search.get('activityId')!)
  const activitySwr = useHealthblocksSWR<FrontdoorCareplanActivity>(
    activityDetailURL(activityId),
  )

  // Content
  const type = activitySwr.data?.type || search.get('type')!
  const contentSwr = useContent(type as CareplanActivity['type'])
  const contentSlug =
    activitySwr.data?.contentSlug ||
    (activitySwr.data?.type === 'Questionnaire'
      ? activitySwr.data.meta.questionnaireSlug
      : undefined) ||
    (activitySwr.data?.type === 'Questionnaire'
      ? // @ts-expect-error
        activitySwr.data.questionnaire?.slug ||
        activitySwr.data.questionnaire?.name
      : undefined) ||
    search.get('contentSlug')!
  const content = contentSwr.data?.find((c) => c.slug === contentSlug)

  // Appointment
  const appointmentId = parseInt(search.get('appointmentId')!)

  // Animated close
  const close = useEvent(async () => {
    const fromCollection = search.get('fromCollection')
    if (fromCollection) {
      // navigate back to collection
      setSearch((previous) => {
        previous.set('contentSlug', fromCollection)
        previous.set('type', 'collection')
        previous.delete('fromCollection')
        return previous
      })
      return
    }

    if (!closing) {
      setClosing(true)
      await new Promise((resolve) => setTimeout(resolve, 500))
    }

    setSearch((previous) => {
      previous.delete('activityId')
      previous.delete('appointmentId')
      previous.delete('contentSlug')
      previous.delete('type')
      previous.delete('view')
      return previous
    })
    Promise.resolve().then(() => setClosing(false))
  })

  return {
    activity: activitySwr.data,
    activityId,
    activitySwr: activitySwr,
    appointmentId,
    close,
    closing,
    content,
    contentSlug,
    contentSwr,
    type,
    view: search.get('view'),
  }
}
