export const alternateSort = <T extends { type: string }>(
  array: T[],
  sortOrder: string[],
): T[] => {
  // Create a map of types to arrays of items
  const itemsByType: { [key: string]: T[] } = array.reduce(
    (accumulator: { [key: string]: T[] }, item) => {
      accumulator[item.type] = accumulator[item.type] || []
      accumulator[item.type].push(item)
      return accumulator
    },
    {},
  )

  // Validate and use the provided sortOrder, default to natural sort if invalid
  const validSortOrder = sortOrder.every((type) => itemsByType[type])
    ? sortOrder
    : Object.keys(itemsByType).sort()

  const result: T[] = []

  let continueAdding
  do {
    continueAdding = false
    for (const type of validSortOrder) {
      if (itemsByType[type] && itemsByType[type].length > 0) {
        result.push(itemsByType[type].shift() as T)
        continueAdding = true
      }
    }
  } while (continueAdding)

  return result
}
