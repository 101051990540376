import { General } from 'untitledui-js'

import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import type {
  Article,
  Audio,
  Collection,
  Exercise,
  Video,
} from '@/utils/content'

export const gradient = {
  article: 'bg-gradient-to-bl from-secondary-pink-500 to-secondary-pink-700',
  audio: 'bg-gradient-to-bl from-secondary-aqua-500 to-secondary-aqua-700',
  exercise: 'bg-gradient-to-bl from-secondary-green-500 to-secondary-green-700',
  video: 'bg-gradient-to-bl from-secondary-purple-500 to-secondary-purple-700',
  collection: 'bg-gradient-to-bl from-success-500 to-success-700',
  Questionnaire: 'bg-gradient-to-bl from-primary-500 to-primary-700',
}

type Props = {
  content?: Article | Audio | Exercise | Video | Collection
  type: keyof typeof gradient
  onClose: () => void
}

export const IntroHeader = ({ content, type, onClose }: Props) => {
  return (
    <div className="flex z-20">
      {content?.image?.thumbnail ? (
        <img
          src={content.image?.thumbnail}
          alt={content.title!}
          className={`w-full object-cover h-[100vw] max-h-[calc(100vh-300px)] ${gradient[type]}`}
        />
      ) : (
        <div className={`w-full h-40 ${gradient[type]}`} />
      )}

      <div
        className="flex flex-col absolute top-0 right-0 p-4 cursor-pointer z-20"
        onClick={onClose}
      >
        <SafeAreaTopSpacer />
        <div className="p-[0.6rem] bg-gray-50 rounded-full">
          <General.X className="w-7 h-7 stroke-gray-500" />
        </div>
      </div>
    </div>
  )
}
