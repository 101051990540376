import { useBearer } from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { useOAuthCallbackWorkaround } from '@/hooks/useOAuth2'
import { LoginLayout } from '@/layout/login/LoginLayout'

import { LoginForm } from './form/LoginForm'

const LoginPage = () => {
  const navigate = useNavigate()
  const bearer = useBearer()

  useOAuthCallbackWorkaround()

  // Check if we are already logged in
  useEffect(() => {
    const t = setTimeout(() => {
      bearer()
        .then((exists) => {
          if (exists) navigate('/')
        })
        .catch(() => {})
    }, 1000)
    return () => clearTimeout(t)
  }, [])

  return (
    <LoginLayout image={'img/background.jpg'}>
      <div className="flex flex-col gap-4 mb-6">
        <p className="font-title1 text-brand font-bold">
          {i18n._('login.title', undefined, {
            message: 'Welkom bij Healthblocks!',
          })}
        </p>
        <p className="font-body text-text-primary font-semibold">
          {i18n._('login.subtitle', undefined, {
            message: 'Veilig en snel aanmelden met Itsme.',
          })}
        </p>
        <p className="font-body text-text-primary">
          {i18n._('login.body', undefined, {
            message: 'Sign in with email and password.',
          })}
        </p>
      </div>
      <div className="my-6">
        <LoginForm onSuccess={() => navigate('/')} />
      </div>
    </LoginLayout>
  )
}

export { LoginPage }
