import { SplashScreen } from '@capacitor/splash-screen'
import { useEffect, useState } from 'react'

import { Debug } from '@/components/Debug'
import { EnvironmentOverride } from '@/components/EnvironmentOverride'
import { preset } from '@/config/environment'

/** Component to show when project is busy loading (or fails to load) */
export function HealthblocksProjectFallback() {
  const [late, setLate] = useState(false)

  useEffect(() => {
    const t = setTimeout(() => {
      setLate(true)
      SplashScreen.hide()
    }, 1500)
    return () => clearTimeout(t)
  }, [])
  if (!late) return null
  return (
    <div className="flex flex-col justify-end h-full gap-2 text-center pb-12">
      <b>Staat je VPN wel aan?</b>
      <div className="text-sm">Installeer GlobalProtect voor</div>
      <Debug preset={preset} />
      <div>
        <a
          className="text-primary-600 underline p-4"
          href="https://apps.apple.com/us/app/globalprotect/id1400555706"
        >
          iOS
        </a>
        <a
          className="text-primary-600 underline p-4"
          href="https://play.google.com/store/apps/details?id=com.paloaltonetworks.globalprotect"
        >
          Android
        </a>
      </div>
      <EnvironmentOverride className="flex-col gap-2 items-center mt-12" />
    </div>
  )
}
