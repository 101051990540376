import { useLingui } from '@lingui/react'
import Fuse from 'fuse.js'
import { useEffect, useState } from 'react'
import { General } from 'untitledui-js'

import type { ContentCardProps } from '@/components/content/card/ContentCard'
import { ContentCard } from '@/components/content/card/ContentCard'
import { useContentCards } from '@/components/content/hook/useContent'
import { PageHeader } from '@/components/header/PageHeader'
import { SearchBar } from '@/components/search-bar/SearchBar'
import { Container } from '@/layout/Container'
import { alternateSort } from '@/utils/alternate-types-sort'

import { ActivityModal } from '../activity/Activity.modal'

const ContentPage = () => {
  const i18n = useLingui()

  const { cardItems, loading, error } = useContentCards()
  const [filteredCardItems, setFilteredCardItems] = useState<
    ContentCardProps[]
  >([])

  const [query, setQuery] = useState('')

  useEffect(() => {
    if (query.trim() === '') {
      const sortedItems = alternateSort(cardItems, [
        'article',
        'exercise',
        'video',
        'audio',
        'collection',
        'Questionnaire',
      ])
      setFilteredCardItems(sortedItems) // Reset to the original data if the input is empty
      return
    }

    const cardItemsWithTranslations = cardItems.map((cardItem) => ({
      ...cardItem,
      translatedType: i18n._(`activity.type.${cardItem.type}.title`),
    }))

    const fuse = new Fuse(cardItemsWithTranslations, {
      keys: ['title', 'type', 'translatedType'],
      threshold: 0.4,
      findAllMatches: true,
    })

    const result = fuse.search(query)
    const items = result.map((item) => item.item)
    const sortedItems = alternateSort(items, [
      'article',
      'exercise',
      'video',
      'audio',
      'collection',
      'Questionnaire',
    ])

    setFilteredCardItems(sortedItems)
  }, [query, cardItems])

  const handleSearch = (query: string) => {
    setQuery(query)
  }

  return cardItems ? (
    <>
      <div className="flex flex-col bg-base-white">
        <PageHeader
          title={i18n._('discover.title', undefined, {
            message: 'Ontdek',
          })}
        />

        <Container className="py-4 flex flex-col gap-4">
          <SearchBar
            query={query}
            placeholder={i18n._('discover.search.placeholder', undefined, {
              message: 'Wat wil je zoeken?',
            })}
            onSearch={handleSearch}
          />

          <div className="flex flex-wrap gap-4">
            {filteredCardItems.map((card) => (
              <ContentCard key={card.id} {...card} />
            ))}
          </div>
        </Container>
      </div>
      <ActivityModal />
    </>
  ) : error ? (
    <div className="p-6 overflow-y-auto w-full h-full flex justify-center items-center bg-base-white">
      error state {error}
    </div>
  ) : loading ? (
    <div className="p-6 overflow-y-auto w-full h-full flex justify-center items-center bg-base-white">
      <General.Loading02 className="stroke-gray-600 animate-spin" />
    </div>
  ) : null
}

export { ContentPage }
