import type {
  ActivityType,
  CareplanActivity,
  CareplanActivityMeta,
  IQuestionnaire,
  QuestionAnswerInput,
  QuestionnaireResponse,
} from '@healthblocks-io/core'
import { useAxios, useHealthblocksSWR } from '@healthblocks-io/core'
import { i18n } from '@lingui/core'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alerts, Arrow, Time } from 'untitledui-js'

import { ContentType } from '../content/ContentType'
import { Question } from '../questionnaire/Question'
import { getNextQuestion } from '../questionnaire/question-history'

type CardState = 'start' | 'inProgress' | 'preview'

type Props = {
  activity: CareplanActivity<ActivityType, CareplanActivityMeta> & {
    questionnaire?: IQuestionnaire
    questionnaireResponse?: QuestionnaireResponse
  }
  mutate: () => void
}

export const QuestionnaireContent = ({ activity, mutate }: Props) => {
  const axios = useAxios()
  const navigate = useNavigate()

  const supportedQuestionnaireQuestionTypes = new Set(['Smiley'])
  const questionnaires = useHealthblocksSWR<IQuestionnaire[]>('/questionnaire')
  const questionnaire =
    activity?.questionnaire ||
    questionnaires.data?.find((q) => q.name === activity.meta.questionnaireSlug)
  const questionnaireResponse = activity.questionnaireResponse

  const [success, setSuccess] = useState(false)
  const duration = activity.meta?.duration
    ? i18n._(
        'calendar.duration',
        { duration: activity.meta.duration },
        { message: '±{duration} min.' },
      )
    : undefined

  const cardState: CardState = useMemo(() => {
    if (questionnaireResponse) {
      const isInProgress = questionnaireResponse.status === 'in-progress'
      if (isInProgress) {
        return 'inProgress'
      }
    }

    const firstQuestion = questionnaire?.item?.[0]
    const showPreview =
      firstQuestion &&
      supportedQuestionnaireQuestionTypes.has(firstQuestion.type)
    if (showPreview) {
      return 'preview'
    }

    return 'start'
  }, [questionnaireResponse, questionnaire])

  const onSubmit = useCallback(
    async (answers: QuestionAnswerInput[]) => {
      try {
        await axios.post(
          '/careplan-activity/' + activity.id + '/questionnaire-response',
          { answers, status: 'completed' },
        )

        setSuccess(true)
        setTimeout(() => {
          mutate()
          questionnaires.mutate()
        }, 2000)
      } catch (error) {
        console.error(error)
      }
    },
    [mutate],
  )

  // Render
  // ------------------------------------------------------------------------- /

  const renderStartState = useCallback(() => {
    return (
      <div className="h-full flex flex-col">
        <p className="font-title2 text-text-primary font-bold line-clamp-2">
          {activity.display || questionnaire?.title}
        </p>
        <div className="flex flex-grow" />
        <div className="w-full flex gap-2">
          <div className="flex flex-grow gap-2">
            <p className="font-callout text-primary-700 font-semibold">
              {i18n._('home.activities.questionnaire.link', undefined, {
                message: 'Start hier',
              })}
            </p>
            <Arrow.ArrowNarrowRight className="w-5 h-5 stroke-primary-700" />
          </div>
        </div>
      </div>
    )
  }, [activity, questionnaire])

  const renderInProgressState = useCallback(() => {
    const answeredQuestions = questionnaireResponse?.answers.length || 0
    const totalQuestions = questionnaire?.item.length || 1

    const progress = answeredQuestions / totalQuestions
    return (
      <div className="h-full flex flex-col gap-4">
        <div className="flex flex-col gap-4 flex-grow">
          <p className="font-title2 text-text-primary font-bold flex">
            {i18n._('home.activities.questionnaire.in_progress.title')}
          </p>

          <div className="w-full h-2 bg-primary-100 rounded-sm">
            <div
              className="flex h-2 items-center bg-primary-500 rounded-sm transition:width duration-500"
              style={{ width: Math.min(Math.round(progress * 100), 100) + '%' }}
            />
          </div>
        </div>

        <div className="w-full flex gap-2">
          <div className="flex flex-grow gap-2">
            <p className="font-callout text-primary-700 font-semibold">
              {i18n._('home.activities.questionnaire.continue', undefined, {
                message: 'Ga verder',
              })}
            </p>
            <Arrow.ArrowNarrowRight className="w-5 h-5 stroke-primary-700" />
          </div>
        </div>
      </div>
    )
  }, [questionnaireResponse?.answers, questionnaire?.item])

  const renderPreviewState = useCallback(() => {
    const previewQuestion = questionnaire?.item[0]
    if (!previewQuestion) {
      return null
    }

    return success ? (
      <div className="flex flex-col flex-grow justify-center items-center gap-4">
        <div className="flex justify-center items-center p-4 rounded-lg border border-gray-50">
          <Alerts.ThumbsUp className="w-7 h-7 stroke-base-black" />
        </div>
        <p className="font-body text-text-primary font-semibold text-center">
          {i18n._('home.activities.questionnaire.success', undefined, {
            message: 'Bedankt voor je input.',
          })}
        </p>
      </div>
    ) : (
      <div className="flex flex-grow items-end justify-end">
        <Question
          question={previewQuestion}
          answer={undefined}
          onAnswer={async (a) => {
            // Simulate this answer
            const { nextQuestion } = await getNextQuestion({
              questionnaire,
              answers: [a],
            })

            // Submit immediately if there is no next question
            if (!nextQuestion) return onSubmit([a])

            try {
              // Save the answer
              await axios.post(
                '/careplan-activity/' + activity.id + '/questionnaire-response',
                { answers: [a], status: 'in-progress' },
              )

              // Resume in modal
              navigate(`?activityId=${activity.id}`)
            } catch (error: any) {
              alert(
                'Error saving questionnaire with First Answer: ' +
                  error.message,
              )
            }
          }}
          isPreview
        />
      </div>
    )
  }, [success, questionnaire])

  const renderContent = useCallback(() => {
    switch (cardState) {
      case 'inProgress': {
        return renderInProgressState()
      }
      case 'preview': {
        return renderPreviewState()
      }
      default:
      case 'start': {
        return renderStartState()
      }
    }
  }, [cardState, renderInProgressState, renderPreviewState, renderStartState])

  return (
    <div className="flex flex-col w-full gap-2 p-4">
      <div className="flex gap-sp0.5">
        <ContentType type={activity.type} />

        {duration ? (
          <div className="flex gap-1 items-center">
            <Time.ClockStopWatch className="w-3 h-3 stroke-gray-500" />
            <p className="font-caption1 text-gray-700 font-semibold">
              {duration}
            </p>
          </div>
        ) : null}
      </div>
      {renderContent()}
    </div>
  )
}
