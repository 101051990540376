import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { Style } from '@capacitor/status-bar'
import { useHealthblocksSWR } from '@healthblocks-io/core'
import { useLingui } from '@lingui/react'
import { useCallback, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { General } from 'untitledui-js'

import { useUnreadMessages } from '@/hooks/useNewMessages'
import { useSafeArea } from '@/hooks/useSafeArea'
import { useLayout } from '@/layout/providers/LayoutProvider'
import { useKeyboardHeight } from '@/utils/keyboard'
import { setNotificationModal } from '@/utils/push-notification'
import { useStatusBarStyle } from '@/utils/status-bar'

const ChatPage = () => {
  useStatusBarStyle(Style.Light)
  const i18n = useLingui()

  const hmac = useHealthblocksSWR('/integration/chatwoot/endpoint/hmac')

  return hmac.data ? (
    <ChatPageReady {...hmac.data} />
  ) : (
    <div className="z-10 flex items-center justify-center h-full sm:relative fixed inset-0 bg-[#f8f9fa]">
      <div className="text-center">
        <div className="text-xl font-semibold">
          {i18n._('chat.service', {}, { message: 'Chat' })}
        </div>
        <div className="text-sm">
          {i18n._('chat.loading', {}, { message: 'Loading...' })}
        </div>
      </div>
    </div>
  )
}

const ChatPageReady = (hmac: {
  websiteToken: string
  baseUrl: string
  locale: 'en'
  identifier: string
  identifier_hash: string
}) => {
  useStatusBarStyle(Style.Light)
  const ref = useRef(null)

  const { hideNavigation, showNavigation } = useLayout()
  const [search] = useSearchParams()
  const prefill = search.get('prefill')
  const { clearUnreadMessages } = useUnreadMessages()

  const sendMessage = useCallback((object: unknown) => {
    console.log('chatwoot-widget.send', object)
    const message = `chatwoot-widget:${JSON.stringify(object)}`
    // @ts-expect-error
    ref.current?.contentWindow.postMessage(message, '*')
  }, [])

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if (typeof event.data !== 'string') return
      if (!event.data.startsWith('chatwoot-widget:')) return
      const data = JSON.parse(event.data.replace('chatwoot-widget:', ''))
      console.log('chatwoot-widget.receive', data.event, data)
      if (data.event === 'loaded') {
        // Clear new message badge
        clearUnreadMessages()

        sendMessage({
          event: 'set-user',
          identifier: hmac.identifier,
          identifier_hash: hmac.identifier_hash,
          user: hmac,
        })
      }
      if (
        data.data?.sender_type === 'Contact' &&
        !localStorage.notificationIntent
      )
        setNotificationModal(true)
    }

    window.addEventListener('message', handler)
    const t = setTimeout(() => {
      sendMessage({ event: 'toggle-open', isOpen: true })
    }, 5000)

    return () => {
      clearTimeout(t)
      window.removeEventListener('message', handler)
    }
  }, [hmac])

  // Hide keyboard on iOS when navigating away
  useEffect(() => {
    return () => {
      Capacitor.getPlatform() === 'ios' && Keyboard.hide()
    }
  }, [])

  const safe = useSafeArea()
  const keyboard = useKeyboardHeight()

  const goBack = useCallback(async () => {
    window.history.back()
    if (Capacitor.getPlatform() === 'ios') Keyboard.hide()
  }, [])

  useEffect(() => {
    Keyboard.addListener('keyboardWillShow', () => {
      hideNavigation()
    })
    Keyboard.addListener('keyboardDidHide', () => {
      showNavigation()
    })

    return () => {
      Keyboard.removeAllListeners()
      showNavigation()
    }
  }, [])

  return (
    <div className="z-10 overflow-hidden h-full relative inset-0 bg-[#fff]">
      <iframe
        src={`${hmac.baseUrl}/widget?website_token=${
          hmac.websiteToken
        }&prefill=${encodeURIComponent(prefill || '')}&locale=${
          hmac.locale
        }#/messages`}
        style={{ paddingTop: safe.top, paddingBottom: keyboard }}
        ref={ref}
        className="w-full h-[calc(100%_+_20px)] flex-1 -mb-2 overflow-hidden"
      />
      <button
        className="z-10 absolute top-0 left-0 w-16 h-20 cursor-pointer"
        onClick={goBack}
        style={{ marginTop: safe.top }}
        aria-label="Terug"
      ></button>
      <div
        className="absolute bg-[#f8f9fa] left-0 right-0 h-2 pointer-events-none"
        style={{
          bottom: keyboard,
        }}
      ></div>
      <div
        style={{ marginTop: safe.top }}
        className="z-20 absolute top-[10px] right-2 flex flex-col"
        onClick={goBack}
      >
        <div className="p-[0.6rem] bg-gray-50 rounded-full">
          <General.X className="w-7 h-7 stroke-gray-500" />
        </div>
      </div>
    </div>
  )
}

export { ChatPage }
