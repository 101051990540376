import * as RadixSlider from '@radix-ui/react-slider'
import { useEffect, useMemo, useState } from 'react'

import { Text } from '../typography/Typography'

type Props = {
  initialValue?: number
  min?: number
  max?: number
  step?: number
  minLabel?: string
  maxLabel?: string
  onChange: (value: number) => void
}

export const Slider = ({
  initialValue,
  min = 0,
  max = 10,
  step = 1,
  minLabel,
  maxLabel,
  onChange,
}: Props) => {
  const defaultValue = initialValue ?? calculateDefaultValue(min, max, step)
  const [value, setValue] = useState(defaultValue)

  const ticks = useMemo(() => {
    const elements = []
    for (let index = min; index <= max; index += step) {
      elements.push(index)
    }

    return elements
  }, [min, max])

  // Set initial value
  useEffect(() => {
    if (initialValue !== defaultValue) {
      onChange(defaultValue)
    }
  }, [initialValue])

  return (
    <div className="flex flex-col gap-2 mb-6">
      <div className="flex justify-between px-[10px]">
        {ticks.map((tick) => (
          <div
            key={tick}
            className={`flex w-5 justify-center items-center transition-all duration-200 ${
              value === tick ? 'text-primary-600 scale-125' : 'text-gray-400'
            }`}
          >
            <Text
              typography="subheadline"
              className={value === tick ? 'font-bold' : ''}
            >
              {tick}
            </Text>
          </div>
        ))}
      </div>
      <RadixSlider.Root
        className="w-full h-10 relative flex items-center select-none touch-none"
        orientation="horizontal"
        min={min ?? 0}
        max={max ?? 10}
        step={step ?? 1}
        defaultValue={[defaultValue]}
        onValueChange={(values: number[]) => {
          setValue(values[0])
          onChange(values[0])
        }}
      >
        <RadixSlider.Track className="relative flex-grow h-2 rounded-sm bg-gray-200">
          <RadixSlider.Range className="absolute h-full rounded-sm bg-primary-500" />
        </RadixSlider.Track>
        <RadixSlider.Thumb
          id={'thumb'}
          className="absolute w-10 h-10 rounded-full bg-white border-md border-primary-500 -translate-x-5 -translate-y-5 hover:border-primary-500 focus:border-primary-500 active:border-primary-500 focus-visible:outline-none"
        />
      </RadixSlider.Root>

      {minLabel || maxLabel ? (
        <div className="flex justify-between px-[10px]">
          <Text typography="footNote" className="text-gray-700">
            {minLabel}
          </Text>
          <Text typography="footNote" className="text-gray-700">
            {maxLabel}
          </Text>
        </div>
      ) : null}
    </div>
  )
}

function calculateDefaultValue(min: number, max: number, stepSize: number) {
  const range = max - min
  const middleValue = min + range / 2
  const nearestTick = Math.round(middleValue / stepSize) * stepSize

  if (nearestTick < min) {
    return min
  } else if (nearestTick > max) {
    return max
  } else {
    return nearestTick
  }
}
