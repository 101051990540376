import type { ReactNode } from 'react'
import { Fragment, useCallback, useEffect, useState } from 'react'

import { NoConnectionPage } from './NoConnection.page'

type Props = {
  children: ReactNode | ReactNode[]
}

const NetworkConnected = ({ children }: Props) => {
  const [isOnline, setIsOnline] = useState<boolean>(true)

  const handleGoOffline = useCallback(() => {
    setIsOnline(false)
  }, [])

  const handleGoOnline = useCallback(() => {
    setIsOnline(true)
  }, [])

  useEffect(() => {
    window.addEventListener('offline', handleGoOffline)
    window.addEventListener('online', handleGoOnline)

    return () => {
      window.removeEventListener('offline', handleGoOffline)
      window.removeEventListener('online', handleGoOnline)
    }
  }, [handleGoOffline, handleGoOnline])

  return isOnline ? (
    <Fragment key="online">{children}</Fragment>
  ) : (
    <NoConnectionPage />
  )
}

export { NetworkConnected }
