export const fadeInOut = {
  variants: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
      height: 0,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
}

export const fadeGrowInOut = {
  variants: {
    hidden: { opacity: 0, height: 0 },
    visible: {
      opacity: 1,
      height: 'auto',
    },
    exit: {
      opacity: 0,
      height: 0,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
}
