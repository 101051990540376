import '@/utils/yup-phone-number/lib'

import { i18n } from '@lingui/core'
import * as Yup from 'yup'

const EditProfileFormSchema = Yup.object().shape({
  familyName: Yup.string().required(
    i18n._('yup.familyName.error.required', undefined, {
      message: 'Family name is required',
    }),
  ),
  givenName: Yup.string().required(
    i18n._('yup.givenName.error.required', undefined, {
      message: 'Given name is required',
    }),
  ),
  email: Yup.string()
    .email(
      i18n._('yup.email.error.invalid', undefined, {
        message: 'This is not a valid email',
      }),
    )
    .required(
      i18n._('yup.email.error.required', undefined, {
        message: 'Email is required',
      }),
    ),
  phone: Yup.string().phoneNumber([], [], {
    type: i18n._('yup.phone.error.type', undefined, {
      message: 'Invalid type',
    }),
    region: i18n._('yup.phone.error.region', undefined, {
      message: 'Invalid region',
    }),
    format: i18n._('yup.phone.error.format', undefined, {
      message: 'This is not a valid phone number',
    }),
  }),
})

export { EditProfileFormSchema }
