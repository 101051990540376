import { i18n } from '@lingui/core'

import { Button } from '@/components/button/Button'
import { ContentCard } from '@/components/content/card/ContentCard'
import {
  mapArticlesToCard,
  mapAudiosToCard,
  mapExercisesToCard,
  mapQuestionnairesToCard,
  mapVideosToCard,
} from '@/components/content/hook/useContent'
import { StatusBarFade } from '@/components/statusbar/StatusBarFade'
import { Container } from '@/layout/Container'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'
import type { Collection, FrontdoorCareplanActivity } from '@/utils/content'

import { IntroHeader } from '../components/IntroHeader'
import { IntroTags } from '../components/IntroTags'

type Props = {
  content: Collection
  onClose: () => void
  onComplete: () => void
  activity?: FrontdoorCareplanActivity
}

export const CollectionIntro = ({
  activity,
  content,
  onClose,
  onComplete,
}: Props) => {
  const cardList = [
    ...mapExercisesToCard(content.exercise),
    ...mapAudiosToCard(content.audio),
    ...mapVideosToCard(content.video),
    ...mapArticlesToCard(content.article),
    ...mapQuestionnairesToCard(content.questionnaire),
  ].filter((cardInfo) => cardInfo.published)

  return (
    <div className="w-full h-full overflow-auto flex flex-col bg-brand-gradient">
      <IntroHeader content={content} type={'collection'} onClose={onClose} />
      <div id="wrapper" className="w-full flex flex-grow flex-col">
        <StatusBarFade />
        <Container className="flex flex-col flex-grow gap-2 mt-6">
          <IntroTags type={'collection'} duration={content.duration} />

          <div className="flex flex-col flex-grow gap-6">
            <div className="flex flex-col gap-2">
              <p className="text-brand font-title1 font-bold">
                {activity?.display || content.title}
              </p>

              <p className="text-text-secondary font-body whitespace-pre-line">
                {activity?.meta.message || content.description}
              </p>
            </div>

            <div className="flex flex-wrap flex-grow gap-4">
              {cardList.map((card) => (
                <ContentCard
                  key={card.id}
                  {...card}
                  fromCollection={content.slug || undefined}
                />
              ))}
            </div>

            <div className="flex flex-col pb-6">
              <Button
                variant="primary"
                size="large"
                onClick={() => {
                  onClose()
                  onComplete()
                }}
              >
                {i18n._('collection.mark_completed', undefined, {
                  message: 'Markeer als voltooid',
                })}
              </Button>
              <SafeAreaBottomSpacer />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
