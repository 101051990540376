import { Capacitor } from '@capacitor/core'
import { useActorId, useAxios } from '@healthblocks-io/core'

type EventParameters = Record<string, any>

export const useTracking = () => {
  const axios = useAxios()
  const actorId = useActorId()

  const localLog = (event: string, parameters?: EventParameters) => {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'acc'
    ) {
      console.log(`Tracking ${event}`)
      if (parameters) {
        console.log(
          'Page View Parameters:',
          JSON.stringify({
            environment: process.env.NODE_ENV,
            platform: Capacitor.getPlatform(),
            time: Date.now(),
            type: 'page',
            ...parameters,
            subjectId: actorId,
          }),
        )
      }
    }
  }

  const page = async (parameters?: EventParameters) => {
    try {
      localLog('page view', parameters)
    } catch (error) {
      console.log('Error tracking page view:', error)
    }
  }

  const track = async (event: string, parameters?: EventParameters) => {
    try {
      localLog(event, parameters)
    } catch (error) {
      console.log('Error tracking event:', error)
    }
  }

  return { track, page }
}
