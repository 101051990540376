import { i18n } from '@lingui/core'
import { useSearchParams } from 'react-router-dom'

import { Button } from '@/components/button/Button'
import { MarkdownRenderer } from '@/components/markdown/MarkdownRenderer'
import { StatusBarFade } from '@/components/statusbar/StatusBarFade'
import { Container } from '@/layout/Container'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'
import type { Article, FrontdoorCareplanActivity } from '@/utils/content'

import { IntroHeader } from '../components/IntroHeader'
import { IntroTags } from '../components/IntroTags'

type Props = {
  activity?: FrontdoorCareplanActivity
  content: Article
  onClose: () => void
  onComplete: () => void
}

export const ArticleIntro = ({
  activity,
  content,
  onClose,
  onComplete,
}: Props) => {
  const [search, setSearch] = useSearchParams()
  const showIntro = activity?.meta.message && search.get('view') !== 'article'
  return (
    <div
      id={'article-modal'}
      className="w-full h-full overflow-auto flex flex-col bg-brand-gradient"
    >
      <IntroHeader content={content} type={'article'} onClose={onClose} />
      <div id="wrapper" className="w-full flex flex-grow flex-col">
        <StatusBarFade />
        <Container className="flex flex-col flex-grow gap-2 mt-6">
          <IntroTags type={'article'} duration={content.duration} />
          <div className="flex flex-col flex-grow gap-6">
            <div className="flex flex-col flex-grow gap-2">
              <p className="text-brand font-title1 font-bold">
                {showIntro ? activity?.display : content.title}
              </p>

              <MarkdownRenderer
                markdownText={
                  showIntro ? activity?.meta.message : content.description
                }
              />
              {/* <p className="text-text-secondary font-body whitespace-pre-line">
                {showIntro ? activity?.meta.message : content.description}
              </p> */}
            </div>

            <div className="flex flex-col pb-6">
              <Button
                variant="primary"
                size="large"
                onClick={() => {
                  if (showIntro)
                    setSearch((search) => {
                      search.set('view', 'article')
                      return search
                    })
                  else {
                    onClose()
                    onComplete()
                  }
                }}
              >
                {showIntro
                  ? i18n._('article.start', undefined, {
                      message: 'Lees dit artikel',
                    })
                  : i18n._('article.mark_completed', undefined, {
                      message: 'Markeer als gelezen',
                    })}
              </Button>
              <SafeAreaBottomSpacer />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
