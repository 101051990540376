import { createBrowserRouter } from 'react-router-dom'

import { AppRoot } from './AppRoot'
import { privateRoutes } from './private.routes'
import { publicRoutes } from './public.routes'

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppRoot />,
    children: [...publicRoutes, ...privateRoutes],
  },
])

export { router }
