import { i18n } from '@lingui/core'
import { Time } from 'untitledui-js'

import { ContentType } from '@/components/content/ContentType'
import type { ContentTypeMap } from '@/utils/content'

type Props = {
  duration?: string
  type?: keyof ContentTypeMap | string
}

export const IntroTags = ({ type, duration }: Props) => {
  duration = duration
    ? i18n._('calendar.duration', { duration }, { message: '±{duration} min.' })
    : undefined
  return (
    <div className="flex gap-4 pb-1">
      <ContentType type={type} />
      {duration ? (
        <div className="flex gap-1 items-center">
          <Time.ClockStopWatch className="w-4 h-4 stroke-gray-500" />
          <p className="font-callout text-gray-700 font-semibold">{duration}</p>
        </div>
      ) : null}
    </div>
  )
}
