import { Dialog, Transition } from '@headlessui/react'
import type { MutableRefObject, ReactNode } from 'react'
import { Fragment } from 'react'

export default function Modal({
  open = true,
  onBlur,
  bodyClassName = 'max-w-md',
  topAligned = false,
  children,
  title,
  initialFocus,
  dialog = false,
}: {
  open?: boolean
  onBlur: () => void
  bodyClassName?: string
  topAligned?: boolean
  title: string
  children: ReactNode
  initialFocus?: MutableRefObject<HTMLElement | null>
  // Set to true to keep spacing around the modal, like a small dialog
  dialog?: boolean
}) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={onBlur}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="inline-flex fixed -inset-2 bg-black bg-opacity-60 filter backdrop-blur-sm" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className={(!topAligned && 'align-middle') + ' inline-block h-screen'}
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className={
            'fixed inset-0 overflow-y-auto md:max-w-[600px] md:mx-auto' +
            (dialog ? 'px-6 py-12 flex' : 'md:px-1 md:py-12')
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={
                (bodyClassName || '') +
                ' m-auto w-full transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'
              }
            >
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
