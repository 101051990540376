import '@vidstack/react/player/styles/default/theme.css'
import '@vidstack/react/player/styles/default/layouts/video.css'

import { Capacitor } from '@capacitor/core'
import { i18n } from '@lingui/core'
import type { MediaPlayerInstance } from '@vidstack/react'
import { MediaPlayer, MediaProvider } from '@vidstack/react'
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default'
import { useEffect, useRef, useState } from 'react'
import { General, MediaDevices } from 'untitledui-js'

import { Button } from '@/components/button/Button'
import { Container } from '@/layout/Container'
import { SafeAreaBottomSpacer } from '@/layout/safearea/SafeAreaBottomSpacer'
import { SafeAreaTopSpacer } from '@/layout/safearea/SafeAreaTopSpacer'
import type { FrontdoorCareplanActivity, Video } from '@/utils/content'

import { IntroTags } from '../components/IntroTags'
import { VideoHeader } from '../components/VideoHeader'
type Props = {
  content: Video
  onClose: () => void
  onComplete: () => void
  activity?: FrontdoorCareplanActivity
}

export const VideoIntro = ({
  activity,
  content,
  onClose,
  onComplete,
}: Props) => {
  const ref = useRef<MediaPlayerInstance>(null)
  const [start, setStart] = useState(false)

  useEffect(() => {
    if (start) {
      onComplete()
    }

    if (Capacitor.isNativePlatform()) {
      if (start) {
        window.screen.orientation.unlock()
      } else {
        // @ts-expect-error
        window.screen.orientation.lock('portrait')
      }
    }
  }, [start])

  useEffect(() => {
    return () => {
      if (Capacitor.isNativePlatform()) {
        // @ts-expect-error
        window.screen.orientation.lock('portrait')
      }
    }
  }, [])

  if (start) {
    return (
      <div className="w-full h-full relative flex justify-center items-center bg-base-black">
        <div
          className="flex flex-col absolute top-0 right-0 p-4 z-20 cursor-pointer"
          onClick={() => onClose()}
        >
          <SafeAreaTopSpacer />
          <div className="p-[0.6rem] bg-gray-50 rounded-full">
            <General.X className="w-7 h-7 stroke-gray-500" />
          </div>
        </div>
        <MediaPlayer
          ref={ref}
          title={content.title}
          src={content.videoUrl}
          autoplay
          controls={!Capacitor.isNativePlatform()}
          playsinline={!Capacitor.isNativePlatform()}
          autoFocus={Capacitor.isNativePlatform()}
          className="border-0"
          onEnded={() => {
            onClose()
          }}
        >
          <MediaProvider />
          <DefaultVideoLayout icons={defaultLayoutIcons} />
        </MediaPlayer>
      </div>
    )
  }

  return (
    <div className="w-full h-full overflow-auto flex flex-col gap-6 bg-brand-gradient">
      <VideoHeader
        content={content}
        onStart={() => {
          setStart(true)
        }}
        onClose={onClose}
      />
      <Container className="flex flex-col flex-grow gap-2">
        <IntroTags type={'video'} duration={content.duration} />

        <div className="flex flex-col flex-grow gap-6">
          <div className="flex flex-col flex-grow gap-2">
            <p className="text-brand font-title1 font-bold">
              {activity?.display || content.title}
            </p>

            {content.description ? (
              <p className="text-text-primary font-body whitespace-pre-line">
                {activity?.meta.message || content.description}
              </p>
            ) : null}
          </div>

          <div className="flex flex-col pb-6">
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                setStart(true)
              }}
            >
              <MediaDevices.PlayCircle className="w-6 h-6 stroke-white" />
              <p className="font-body">
                {i18n._('video.start', undefined, {
                  message: 'Afspelen',
                })}
              </p>
            </Button>
            <SafeAreaBottomSpacer />
          </div>
        </div>
      </Container>
    </div>
  )
}
