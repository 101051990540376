import type { ReactNode } from 'react'
import { useCallback, useState } from 'react'
import { Arrow } from 'untitledui-js'

import { Card } from '@/components/Card'

type Props = {
  children: ReactNode
  onReload: () => Promise<any>
}

export const ErrorCard = ({ children, onReload }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleReload = useCallback(async () => {
    setIsLoading(true)
    await onReload()
    setTimeout(() => {
      setIsLoading(false)
    }, 400)
  }, [onReload])

  return (
    <Card className="p-6 flex-col gap-4 bg-red-50 border border-red-200 relative">
      <div
        onClick={handleReload}
        className="absolute p-4 top-0 right-0 flex justify-center items-center cursor-pointer"
      >
        <Arrow.RefreshCW05
          className={`w-5 h-5 stroke-red-800 ${
            isLoading ? 'animate-spin' : null
          }`}
        />
      </div>
      <div className="flex flex-col">
        <div className="font-footNote text-red-800 text-center">{children}</div>
      </div>
    </Card>
  )
}
