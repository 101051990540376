import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'

import { animation } from './animation'
import { theme as generatedTheme } from './generated-theme'
import { spacing } from './spacing'
import { typography } from './typography'

type BothrsThemeProviderProps = {
  children: ReactNode
  config: {
    spacingBase: number
  }
}

const FrontdoorThemeProvider = ({
  children,
  config,
}: BothrsThemeProviderProps) => {
  const composedTheme = useMemo(() => {
    return {
      ...generatedTheme,
      ...typography,
      ...spacing(config.spacingBase),
      ...animation,
    }
  }, [config.spacingBase])

  return <ThemeProvider theme={composedTheme}>{children}</ThemeProvider>
}

export { FrontdoorThemeProvider }
