import { SafeArea } from 'capacitor-plugin-safe-area'
import { useEffect, useState } from 'react'

import { createGlobal } from '@/utils/global-hook'
import { statusBarInfo } from '@/utils/status-bar'

// Make sure to load this css
// :root {
//   --safe-area-inset-top: env(safe-area-inset-top);
//   --safe-area-inset-right: env(safe-area-inset-right);
//   --safe-area-inset-bottom: env(safe-area-inset-bottom);
//   --safe-area-inset-left: env(safe-area-inset-left);
// }
export const safeArea = createGlobal({
  top: readSafeAreaTopOrStatusBar(),
  right: getPropertyValue('--safe-area-inset-right'),
  bottom: getPropertyValue('--safe-area-inset-bottom'),
  left: getPropertyValue('--safe-area-inset-left'),
})

let cachedInsets:
  | { top: number; bottom: number; left: number; right: number }
  | undefined = undefined

export const useSafeArea = () => {
  const [insets, setInsets] = useState(
    cachedInsets || { top: 0, bottom: 0, left: 0, right: 0 },
  )

  const fetchSafeAreaInsets = async () => {
    try {
      const { insets } = await SafeArea.getSafeAreaInsets()
      cachedInsets = insets // Cache the insets
      setInsets(insets)
    } catch (error) {
      console.error('Error fetching safe area insets:', error)
    }
  }

  useEffect(() => {
    if (!cachedInsets) {
      fetchSafeAreaInsets()
    }

    SafeArea.addListener('safeAreaChanged', ({ insets }) => {
      cachedInsets = insets
      setInsets(insets)
    })

    return () => {
      SafeArea.removeAllListeners()
    }
  })

  return insets
}

window.addEventListener('orientationchange', checkSafeAreaChanged)
document.addEventListener('load', checkSafeAreaChanged)
document.addEventListener('idle', checkSafeAreaChanged)
setTimeout(checkSafeAreaChanged, 1000)

function readSafeAreaTopOrStatusBar() {
  const top = getPropertyValue('--safe-area-inset-top')
  return top || (statusBarInfo.get()?.overlays === true ? 50 : 0)
}

export function checkSafeAreaChanged() {
  const top = readSafeAreaTopOrStatusBar()
  const right = getPropertyValue('--safe-area-inset-right')
  const bottom = getPropertyValue('--safe-area-inset-bottom')
  const left = getPropertyValue('--safe-area-inset-left')

  const changed =
    // eslint-disable-next-line unicorn/consistent-destructuring
    top !== safeArea.data.top ||
    // eslint-disable-next-line unicorn/consistent-destructuring
    right !== safeArea.data.right ||
    // eslint-disable-next-line unicorn/consistent-destructuring
    bottom !== safeArea.data.bottom ||
    // eslint-disable-next-line unicorn/consistent-destructuring
    left !== safeArea.data.left

  // Update all useSafeArea hooks
  if (changed) {
    console.log('Safe area changed', { top, right, bottom, left })
    safeArea.setData({ top, right, bottom, left })
  }
}

function getPropertyValue(property: string): number {
  const propertyValue = getComputedStyle(
    document.documentElement,
  ).getPropertyValue(property)

  return Number(propertyValue.replace('px', ''))
}
